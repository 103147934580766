import React, { useState, useContext } from "react";
import ThemeContext, { ThemeVariables } from "../../../assets/styles/darkLightMode/theme-context";

import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Legend, ResponsiveContainer, Rectangle } from 'recharts';
import { AiOutlineExpand } from "react-icons/ai";
import { convertToInternationalCurrencySystem } from "../../../utils/Maths/MathsFunctions";
import ModalWindow from "../../ModalWindow/ModalWindow";
import { FlexBlockDiv } from "../../../assets/styles/global";
import { CardProperties, NoBorderIconButton } from "../../../assets/styles/UIKit/UIKit";

const HorizontalBarChartCard = (props) => {
    const { title, data, cardType, dataKey, categoryName, yAxisLabel, showLegend, modal } = props;
    const { theme, setTheme } = useContext(ThemeContext);

    const [isModalOpen, setisModalOpen] = useState(false);

    const labelFormatter = (x) => {
        return convertToInternationalCurrencySystem(x, 3)
    };

    return (
        <>
            {data?.length > 0 &&
                <>
                    <CardProperties className={`${!modal && 'CardProperties'} ${cardType}`}>
                    {!modal &&
                    <FlexBlockDiv>
                        <p className="font-style-montserrat-family-regular extra-small-text color-white">{title}</p>
                        <NoBorderIconButton clickAction={() => setisModalOpen(!isModalOpen)} icon={<AiOutlineExpand className="icon small-text color-lightgray" />} />
                    </FlexBlockDiv>}
                        <ResponsiveContainer id="percentage-bar-chart" width="100%" height="100%" className="font-style-montserrat-family-regular extra-extra-small-text color-white">
                            <BarChart width="100%" height="100%" data={data} layout="vertical" margin={showLegend ? { top: 15 } : { top: 15, right: 30 }}>
                                <CartesianGrid style={{ stroke: `${ThemeVariables[theme]?.lines}` }} horizontal={false} />
                                <XAxis hide type="number" axisLine={false} tickFormatter={(tick) => { return `${tick}` }} />
                                <YAxis tick={{ fill: `${ThemeVariables[theme]?.extreme_color}` }} tickLine={false} dataKey={yAxisLabel} type="category" width={130} tickMargin={10} />
                                <Bar shape={<Rectangle radius={[0, 3, 3, 0]} />} animationDuration={800} dataKey={dataKey} name={categoryName} minPointSize={2} barSize={45} fill="#0071dc" label={{ fill: `${ThemeVariables[theme]?.contrast_color}`, formatter: labelFormatter, position: "right" }} />
                                {showLegend &&
                                    <Legend wrapperStyle={{ maxWidth: "20%", whiteSpace: "break-spaces", paddingLeft: 10 }} iconType="square" formatter={(value) => <span className="legend-items-piechart font-style-montserrat-family-regular color-white">{value}</span>} layout="vertical" verticalAlign="top" align="right" />}
                            </BarChart>
                        </ResponsiveContainer>
                    </CardProperties>
                    <ModalWindow title={title} sizeClass="charts-visualization" open={isModalOpen} setOpen={setisModalOpen}>
                        <HorizontalBarChartCard title={title} data={data} showLegend={showLegend} dataKey={dataKey} categoryName={categoryName} yAxisLabel={yAxisLabel} cardType="modal-card" modal={true}></HorizontalBarChartCard>
                    </ModalWindow>
                </>
            }
        </>
    );
}

export default HorizontalBarChartCard;