import React, { useContext, useState, useEffect } from "react";

import OverviewTopBar from "../../components/OverviewTopBar/OverviewTopBar";
import OverviewFundContent from "../../components/OverviewFundContent/OverviewFundContent";
import LocalLoader from "../../components/LocalLoader/LocalLoader";

import { GeneralContext, OverviewContext } from "../../utils/functions_variables/variables";
import { getFunds, getOverviewCharacteristics, getOverviewEnvironmental } from "../../utils/requests/getRequests";

import metrics from "./optional_metrics.json"

export default function Overview() {
  const { databaseAddress, setWarningMessage } = useContext(GeneralContext)
  const [isLoading, setIsLoading] = useState(true)
  const [tab, setTab] = useState(1)
  const [fundsList, setFundsList] = useState(null);
  const [overviewCharacteristicsData, setOverviewCharacteristicsData] = useState(null);
  const [overviewEnvironmentalData, setOverviewEnvironmentalData] = useState(null);
  const [fundSelectedMetrics, setFundSelectedMetrics] = useState([]);
  const [availableMetrics, setAvailableMetrics] = useState(metrics);

  useEffect(() => {
    const getFundsList = async () => {
      setIsLoading(true)
      await getFunds(databaseAddress).then(data => {
        const simplifiedData = data?.map(obj => { return { value: obj?.id, label: obj?.fund_name } }).sort((a, b) => a?.label?.localeCompare(b?.label))
        setFundsList(simplifiedData)
        setIsLoading(false)
      }).catch(error => {
        setIsLoading(false)
        setWarningMessage(["error", error.message])
      })
    }

    getFundsList()
  }, [])

  useEffect(() => {
    const overviewCharacteristicsData = async () => {
      setIsLoading(true)
      await getOverviewCharacteristics(databaseAddress).then(data => {
        setOverviewCharacteristicsData(data)
      }).catch(error => {
        setIsLoading(false)
        setWarningMessage(["error", error.message])
      })
    }

    const overviewEnvironmentalData = async () => {
      await getOverviewEnvironmental(databaseAddress).then(data => {
        let organizedData = organizeOverviewMetricsData(data)
        setOverviewEnvironmentalData(data)
        setIsLoading(false)
      }).catch(error => {
        setIsLoading(false)
        setWarningMessage(["error", error.message])
      })
    }

    overviewCharacteristicsData().then(overviewEnvironmentalData())
  }, [])

  function organizeOverviewMetricsData(data) {
    let organizedData = data
    organizedData["bestAndWorstAssetsPerEurInvested"] = data?.bestAssetsPerEurInvested.map(item => ({ ...item, type: 'best', best: item.value, worst: null })).concat(data?.worstAssetsPerEurInvested.map(item => ({ ...item, type: 'worst', best: null, worst: item.value })))
    delete organizedData?.bestAssetsPerEurInvested;
    delete organizedData?.worstAssetsPerEurInvested;
    organizedData["bestAndWorstAssetsPerRevenue"] = data?.bestAssetsPerRevenue.map(item => ({ ...item, type: 'best', best: item.value, worst: null })).concat(data?.worstAssetsPerRevenue.map(item => ({ ...item, type: 'worst', best: null, worst: item.value })))
    delete organizedData?.bestAssetsPerRevenue;
    delete organizedData?.worstAssetsPerRevenue;
    organizedData["bestAndWorstIndustriesPerEurInvested"] = data?.bestIndustriesPerEurInvested.map(item => ({ ...item, type: 'best', best: item.value, worst: null })).concat(data?.worstIndustriesPerEurInvested.map(item => ({ ...item, type: 'worst', best: null, worst: item.value })))
    delete organizedData?.bestIndustriesPerEurInvested;
    delete organizedData?.worstIndustriesPerEurInvested;
    organizedData["bestAndWorstIndustriesPerRevenue"] = data?.bestIndustriesPerRevenue.map(item => ({ ...item, type: 'best', best: item.value, worst: null })).concat(data?.worstIndustriesPerRevenue.map(item => ({ ...item, type: 'worst', best: null, worst: item.value })))
    delete organizedData?.bestIndustriesPerRevenue;
    delete organizedData?.worstIndustriesPerRevenue;

    return organizedData
  }

  // function changeActiveFund(tab) {
  //   setActiveFund(tab)
  // }

  // function removeFund(fundName, id, tab) {
  //   const newSelectedFunds = selectedFunds?.filter(obj => obj?.value !== id)
  //   setSelectedFunds(newSelectedFunds);
  //   const newAvailableMetrics = { ...availableMetrics }
  //   delete newAvailableMetrics[fundName]
  //   setAvailableMetrics(newAvailableMetrics)
  //   const newFundSelectedMetrics = fundSelectedMetrics
  //   delete newFundSelectedMetrics[fundName];
  //   setFundSelectedMetrics(newFundSelectedMetrics);
  //   setFundsList(prevFundsList => ([...prevFundsList, { value: id, label: fundName }]))
  //   if (tab <= activeFund && activeFund !== 0) {
  //     setActiveFund(prevValue => (prevValue - 1))
  //   }
  //   if (newSelectedFunds?.length === 0) {
  //     setActiveFund(null)
  //     setOverviewCharacteristicsData(null)
  //   }
  // }

  // function handleAddMetricToFundOverview(metric, id, metric_id) {
  //   const newAvailableMetrics = availableMetrics
  //   newAvailableMetrics[selectedFunds[activeFund]?.label] = newAvailableMetrics[selectedFunds[activeFund]?.label]?.filter((item) => item?.label !== metric);
  //   setAvailableMetrics(newAvailableMetrics);
  //   let newFundSelectedMetrics = {}
  //   if (fundSelectedMetrics.hasOwnProperty(selectedFunds[activeFund]?.label)) {
  //     newFundSelectedMetrics = {
  //       ...fundSelectedMetrics,
  //       [selectedFunds[activeFund]?.label]: [...fundSelectedMetrics[selectedFunds[activeFund]?.label], { metric: metric, id: id, metric_id: metric_id }]
  //     };
  //   } else {
  //     newFundSelectedMetrics = {
  //       ...fundSelectedMetrics,
  //       [selectedFunds[activeFund]?.label]: [{ metric: metric, id: id, metric_id: metric_id }]
  //     };
  //   }
  //   setFundSelectedMetrics(newFundSelectedMetrics)
  // }

  // function handleRemoveMetricToFundOverview(metric, metric_id) {
  //   const newAvailableMetrics = availableMetrics
  //   newAvailableMetrics[selectedFunds[activeFund]?.label] = [...availableMetrics[selectedFunds[activeFund]?.label], { value: metric_id, label: metric }]
  //   setAvailableMetrics(newAvailableMetrics);
  //   const newFundSelectedMetrics = { ...fundSelectedMetrics };
  //   const updatedArray = newFundSelectedMetrics[selectedFunds[activeFund]?.label]?.filter((item) => item.metric_id !== metric_id);
  //   newFundSelectedMetrics[selectedFunds[activeFund]?.label] = updatedArray;
  //   if (updatedArray?.length === 0) {
  //     delete newFundSelectedMetrics[selectedFunds[activeFund]?.label];
  //   }
  //   setFundSelectedMetrics(newFundSelectedMetrics);
  // }

  return (
    <>
      <title>Lynxai | Overview</title>
      {isLoading ?
        <LocalLoader></LocalLoader>
        :
        <OverviewContext.Provider value={{ tab, setTab, fundsList, setFundsList, overviewCharacteristicsData, overviewEnvironmentalData, availableMetrics, setAvailableMetrics, metrics, fundSelectedMetrics }}>
          <div className="content-main-div">
            <OverviewTopBar />
            <OverviewFundContent />
          </div>
        </OverviewContext.Provider>
      }
    </>
  );
}