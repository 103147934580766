import React, { useState, useContext, useCallback } from "react";
import { PieChart, Pie, Cell, Legend, ResponsiveContainer, Tooltip, Sector } from 'recharts';

import ThemeContext, { ThemeVariables } from "../../../assets/styles/darkLightMode/theme-context";
import { PercentageTooltip } from "../Tooltips";
import { AiOutlineExpand } from "react-icons/ai";
import ModalWindow from "../../ModalWindow/ModalWindow";
import { FlexBlockDiv } from "../../../assets/styles/global";
import { CardProperties, NoBorderIconButton } from "../../../assets/styles/UIKit/UIKit";

const PieChartPercentageCard = (props) => {
    const { title, data, cardType, colors, modal } = props;
    const { theme, setTheme } = useContext(ThemeContext);
    const [isModalOpen, setisModalOpen] = useState(false);
    const [activeIndex, setActiveIndex] = useState(null);
    const onMouseOver = useCallback((data, index) => {
        setActiveIndex(index);
    }, []);
    const onMouseLeave = useCallback((data, index) => {
        setActiveIndex(null);
    }, []);

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 1.1;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} style={{ fill: `${ThemeVariables[theme]?.extreme_color}`, fontSize: 12 }} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {percent >= 0.05 ? `${(percent * 100).toFixed(1).replace(/[.,]0/, "")}%` : ""}
            </text>
        );
    };

    const renderActiveShape = props => {
        const RADIAN = Math.PI / 180;
        const {
            cx,
            cy,
            innerRadius,
            outerRadius,
            startAngle,
            endAngle,
            midAngle,
            fill
        } = props;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + 0 * cos;
        const sy = cy + 0 * sin;
        return (
            <Sector
                cx={sx}
                cy={sy}
                innerRadius={innerRadius}
                outerRadius={outerRadius + 5}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
        );
    };

    return (
        <>
            <CardProperties className={`${!modal && 'CardProperties'} ${cardType}`}>
                {!modal &&
                    <FlexBlockDiv>
                        <p className="font-style-montserrat-family-regular extra-small-text color-white">{title}</p>
                        <NoBorderIconButton clickAction={() => setisModalOpen(!isModalOpen)} icon={<AiOutlineExpand className="icon small-text color-lightgray" />} />
                    </FlexBlockDiv>}
                <ResponsiveContainer height='100%' width='100%'>
                    <PieChart width="100%" height="100%" className="extra-extra-small-text font-style-montserrat-family-regular">
                        <Pie activeIndex={activeIndex} activeShape={renderActiveShape} onMouseOver={onMouseOver}
                            onMouseLeave={onMouseLeave} animationDuration={800} data={data} startAngle={-270} labelLine={false} stroke="none" label={renderCustomizedLabel} dataKey="percentage"> {data?.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={colors[index % colors?.length]} />
                            ))}
                        </Pie>
                        <Legend wrapperStyle={{ maxWidth: "50%", whiteSpace: "break-spaces" }} iconType="square" formatter={(percentage) => <span className="legend-items-piechart font-style-montserrat-family-regular color-white">{percentage}</span>} layout="vertical" verticalAlign="middle" align="right" />
                        <Tooltip wrapperStyle={{ outline: 'none' }} content={<PercentageTooltip />} shared={false} />
                    </PieChart>
                </ResponsiveContainer>
            </CardProperties>
            <ModalWindow title={title} sizeClass="charts-visualization" open={isModalOpen} setOpen={setisModalOpen}>
                <PieChartPercentageCard title={title} data={data} cardType="modal-card" modal={true} colors={colors}></PieChartPercentageCard>
            </ModalWindow>
        </>
    );
}

export default PieChartPercentageCard;