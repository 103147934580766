import React, { useState, useContext } from "react";

import "../AssetProfile.scss"

import { AssetProfileContext } from "../../../utils/functions_variables/variables";
import { convertToBigNumbers } from "../../../utils/Maths/MathsFunctions";

import HeaderTitle from "../../HeaderTitle/HeaderTitle";
import MultipleLinesChartWithThresholdCard from "../../DataVisualization/MultipleLinesChartWithThresholdCard/MultipleLinesChartWithThresholdCard";
import LineChartGraphCard from "../../DataVisualization/LineChartGraphCard/LineChartGraphCard";
import OneOrTwoValueCard from "../../Cards/OneOrTwoValueCard/OneOrTwoValueCard";

const AssetProfileEnvironmental = () => {
  const { assetEnvironmentalData } = useContext(AssetProfileContext)

  function get_number_columns() {
    let row_items_number_class = 2
    if (assetEnvironmentalData?.rankingInPortfolio) {
      row_items_number_class += 1;
    }
    switch (row_items_number_class) {
      case 2:
        return "two"
      case 3:
        return "three"
      default:
        return "two"
    }
  }

  return (
    <div className="asset-profile-content">
      <HeaderTitle text="Metrics"></HeaderTitle>
      {assetEnvironmentalData?.rankingInPortfolio && <OneOrTwoValueCard text="Ranking in portfolio" data={assetEnvironmentalData?.rankingInPortfolio} type="oneValue" cardType={`${get_number_columns()}-items small-small-chart-card-size card-height-1`} />}
      { <OneOrTwoValueCard text="Is it currently above or below the threshold?" data={0.01233} type="greenRedPercentage" cardType={`${get_number_columns()}-items small-small-chart-card-size card-height-1`} />}
      {<OneOrTwoValueCard text="What were the lowest and highest emission values?" data={{ highest_value: convertToBigNumbers(129023), lowest_value: convertToBigNumbers(23455) }} type="twoValuesWithArrows" cardType={`${get_number_columns()}-items small-small-chart-card-size card-height-1`} />}
      <MultipleLinesChartWithThresholdCard title="Total emissions" linesKeys={[{ label: "Scope 1", value: "scope1" }, { label: "Scope 2", value: "scope2" }, { label: "Scope 3", value: "scope3" }, { label: "Total", value: "total" }]} cardType="medium-chart-card-size card-height-2 card-height-3" multipleLines={true} data={assetEnvironmentalData?.emissionData} />
      <LineChartGraphCard title="CO2 emissions intensity (per EUR invested)" cardType="medium-chart-card-size card-height-2 card-height-3" multipleLines={true} data={assetEnvironmentalData?.emissionsIntensityPerEurInvested} />
      <LineChartGraphCard title="CO2 emissions intensity (per revenue)" cardType="medium-chart-card-size card-height-2 card-height-3" multipleLines={true} data={assetEnvironmentalData?.emissionsIntensityPerRevenue} />
    </div>
  );
}

export default AssetProfileEnvironmental;