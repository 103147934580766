
import React, { useContext, useState, useRef, useEffect } from "react";

import { getPAIInputFieldsData } from "../../../../utils/requests/getRequests";
import { putPAIInput } from "../../../../utils/requests/putRequests";

import "../MultiStep.scss"

import { IoChevronDown } from "react-icons/io5";
import { GeneralContext } from "../../../../utils/functions_variables/variables";
import LocalLoader from "../../../LocalLoader/LocalLoader";
import { BorderIconTextFullWidthButton, BorderTextButton, BorderTextIconFullWidthButton } from "../../../../assets/styles/UIKit/UIKit";

const StepFive = ({ selectedFund, step, stepsInfo, setStepsInfo }) => {
    const { databaseAddress, setWarningMessage } = useContext(GeneralContext)
    const contentRef = useRef(null);
    const [isLoading, setIsLoading] = useState(true)
    const [articlesOpen, setArticlesOpen] = useState(false);
    const [height, setHeight] = useState(0);
    const textareaRef = React.useRef(null);
    const [placeholderText, setPlaceholderText] = useState("");
    const [valueText, setValueText] = useState("");
    const [buttonEnable, setButtonEnable] = useState(false)

    useEffect(() => {
        if (contentRef?.current) {
            setHeight(contentRef.current.scrollHeight);
        }
    }, [articlesOpen]);

    const toggleShow = () => {
        setArticlesOpen(!articlesOpen);
    }

    const handleChangeText = (evt) => {
        const val = evt?.target?.value;
        setValueText(val);
    };

    function checkFields() {
        if (valueText !== '') {
            setButtonEnable(true)
        } else { setButtonEnable(false) }
    }

    useEffect(() => {
        checkFields()
    }, [valueText]);

    function cleanFields() {
        setValueText("")
    }

    const getInputFieldsData = async () => {
        setIsLoading(true)
        await getPAIInputFieldsData(databaseAddress, selectedFund?.label, "step5", 5).then(data => {
            if (Object.keys(data)?.length === 0) {
                cleanFields()
            } else {
                if (data?.step5) { setValueText(data?.step5); }
                let stepFilled = false
                if (data?.step5 !== '') {
                    stepFilled = true
                }
                setStepsInfo(prevState => ({ ...prevState, stepFive: { filled: stepFilled } }))
            }
            setIsLoading(false)
        }).catch(error => {
            cleanFields()
            setIsLoading(false)
            setWarningMessage(["error", error.message])
        })
    }

    useEffect(() => {
        getInputFieldsData()
    }, [selectedFund])

    const handleSaveStep = async (e) => {
        e.preventDefault();
        if (buttonEnable) {
            setIsLoading(true)
            const bodyContent = JSON.stringify({ client: databaseAddress, fundName: selectedFund?.label, insertValue: { step5: valueText } })
            await putPAIInput(bodyContent, 5).then(data => {
                getInputFieldsData()
                setStepsInfo(prevState => ({ ...prevState, stepFive: { filled: true } }))
                setIsLoading(false)
                setWarningMessage(["success", "Content saved successfully."])
            }).catch(error => {
                setIsLoading(false)
                setWarningMessage(["error", error.message])
            })
        }
    }

    if (isLoading) {
        return <LocalLoader></LocalLoader>;
    }

    return (
        <div style={{ display: step === 5 ? 'flex' : 'none' }} className="steps-main-div color-white">
            <div className="articles">
                <BorderIconTextFullWidthButton text="Articles" icon={<IoChevronDown className={`icon ${articlesOpen && "close-180-deg"}`} />} clickAction={toggleShow} />
                <div ref={contentRef} className="articles-div" style={{ height: articlesOpen ? `${height}px` : '0', marginBottom: articlesOpen ? `15px` : '0' }}>
                    <p className="title font-style-montserrat-family-semibold extra-small-text">Article 9 - References to international standards section</p>
                    <p className="text font-style-montserrat-family-regular extra-small-text "><label className="font-style-montserrat-family-semibold">1. </label>In the section ‘References to international standards’ in Table 1 of Annex I, financial market participants shall describe whether and to what extent they adhere to responsible business conduct codes and internationally recognised standards for due diligence and reporting and, where relevant, the degree of their alignment with the objectives of the Paris Agreement.</p>
                    <p className="text font-style-montserrat-family-regular extra-small-text "><label className="font-style-montserrat-family-semibold">2. </label>The description referred to in paragraph 1 shall contain information about all of the following:<br />
                        <label className="font-style-montserrat-family-semibold">(a)</label> the indicators used to consider the principal adverse impacts on sustainability factors referred to in Article 6(1) that measure the adherence or alignment referred to in paragraph 1;<br />
                        <label className="font-style-montserrat-family-semibold">(b)</label> the methodology and data used to measure the adherence or alignment referred to in paragraph 1, including a description of the scope of coverage, data sources, and how the methodology used forecasts the principal adverse impacts of investee companies;<br />
                        <label className="font-style-montserrat-family-semibold">(c)</label> whether a forward-looking climate scenario is used, and, if so, the name and provider of that scenario and when it was designed;<br />
                        <label className="font-style-montserrat-family-semibold">(d)</label> where no forward-looking climate scenario is used, an explanation of why the financial market participant does consider forward-looking climate scenarios to be irrelevant.</p>
                </div>
            </div>
            <div className="step-three-four-five margin-top-2 margin-bottom-3">
                <textarea rows={1} onChange={handleChangeText} ref={textareaRef}
                    type="text" className="first-input font-style-montserrat-family-regular extra-extra-small-text color-white"
                    value={valueText}
                    placeholder={placeholderText === "" ? "References to international standards" : placeholderText} />
                <BorderTextButton text="Save" extraClasses={`${buttonEnable ? "button-hover" : "button-disabled"} margin-top-2`} clickAction={handleSaveStep} type="submit" disabled={!buttonEnable} />
            </div>
        </div>
    );
}

export default StepFive;