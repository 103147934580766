import React, { useState, useContext } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Tooltip } from 'recharts';

import ThemeContext, { ThemeVariables } from "../../../assets/styles/darkLightMode/theme-context";
import { ValueTooltip, TickFormatterYAxisBigText, TickFormatterAxisBigNumber } from "../Tooltips";

const RankingDotsChart = (props) => {
    const { data, domainInterval, calculatedInterval, labelText, dotsColor } = props;
    const { theme, setTheme } = useContext(ThemeContext);
    const countTick = Math.floor(domainInterval[1] / calculatedInterval)

    return (
        <>
            {data?.length > 0 &&
                <ResponsiveContainer width="100%" className="font-style-montserrat-family-regular extra-extra-small-text color-white">
                    <LineChart layout="vertical" data={data} margin={{ top: 15, left: 15, right: 30, bottom: 15 }}>
                        <CartesianGrid style={{ stroke: `${ThemeVariables[theme]?.lines}` }} />
                        <XAxis dy={5} domain={domainInterval} label={{ value: labelText, position: "bottom", style: { textAnchor: 'middle' }, fill: `${ThemeVariables[theme]?.extreme_color}` }} type="number" interval={0} tickCount={countTick} tick={{ fill: `${ThemeVariables[theme]?.extreme_color}` }} tickLine={false} />
                        <YAxis dx={-5} dataKey="label" type="category" width={115} tickFormatter={TickFormatterYAxisBigText} tick={{ fill: `${ThemeVariables[theme]?.extreme_color}` }} tickLine={false} />
                        <Tooltip wrapperStyle={{ outline: 'none' }} content={<ValueTooltip />} shared={false} />
                        <Line animationDuration={300} dataKey="value" name="ESG score" type="monotone" stroke="none" dot={{ r: 5, fill: dotsColor }} activeDot={{ r: 6 }} />
                    </LineChart>
                </ResponsiveContainer>
            }
        </>
    );
}

export default RankingDotsChart;