import React, { useState, useContext } from "react";

import "../ManageFund.scss"
import { ManageFundContext } from "../../../../utils/functions_variables/variables";
import { checkIfAbleToSave } from "../../../../utils/functions_variables/functions";

import AddAsset from "../AddAsset";
import ModalWindow from "../../../ModalWindow/ModalWindow";
import Assets from "../Assets";
import HeaderTitle from "../../../HeaderTitle/HeaderTitle";
import CreateAsset from "../CreateAsset";
import AssetsHistoryManagement from "../AssetsHistoryManagement/AssetsHistoryManagement";

import { BorderTextButton, CardProperties } from "../../../../assets/styles/UIKit/UIKit";

const FundAssets = () => {
  const { purpose, fundAssets, createdAssets, setStepThreeDone } = useContext(ManageFundContext)

  const [displayCreateAsset, setDisplayCreateAsset] = useState(false)
  const [buttonEnable, setButtonEnable] = useState(checkIfAbleToSave(fundAssets, createdAssets))

  function handleNotFound() {
    setDisplayCreateAsset(!displayCreateAsset)
  }

  function handleSave() {
    setStepThreeDone(true)
  }

  return (
    <>
      <HeaderTitle text="Assets" />
      <div className="fund-details-div">
        <AssetsHistoryManagement />
        <CardProperties className="CardProperties">
          {purpose !== "details" && <AddAsset handleNotFound={handleNotFound} />}
          <Assets setButtonEnable={setButtonEnable} />
        </CardProperties>
        {
          purpose !== "details" && 
          <BorderTextButton text="Save" extraClasses={`${buttonEnable ? "button-hover" : "button-disabled"} margin-top-2`} clickAction={handleSave} disabled={!buttonEnable}/>
        }
      </div>
      <ModalWindow title="Create asset" sizeClass="create-asset-create-fund" open={displayCreateAsset} setOpen={setDisplayCreateAsset}>
        <CreateAsset />
      </ModalWindow>
    </>
  );
}

export default FundAssets;