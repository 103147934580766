import React from "react";

import "./OneValueLabelBottomCard.scss"

import { CardProperties } from "../../../assets/styles/UIKit/UIKit";

const OneValueLabelBottomCard = ({ text, value, cardType }) => {

    return (
        <CardProperties className={`CardProperties ${cardType} extra-small-text font-style-montserrat-family-regular color-white`}>
            <p className="one-value-card-value large-text font-style-montserrat-family-semibold">{` ${(value * 100)?.toFixed(1).replace(/[.,]0/, "")}% `}</p>
            {text}
        </CardProperties>
    );
}

export default OneValueLabelBottomCard;