import React, { useState, useContext } from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Legend, ResponsiveContainer, Tooltip, Rectangle } from 'recharts';

import ThemeContext, { ThemeVariables } from "../../../assets/styles/darkLightMode/theme-context";
import { CurrencyTooltip } from "../Tooltips";

import ModalWindow from "../../ModalWindow/ModalWindow";
import { convertToInternationalCurrencySystem } from "../../../utils/Maths/MathsFunctions"

import { CardProperties, NoBorderIconButton } from "../../../assets/styles/UIKit/UIKit";
import { FlexBlockDiv } from "../../../assets/styles/global";
import { AiOutlineExpand } from "react-icons/ai";

const BarChartCard = (props) => {
    const { title, data, cardType, modal } = props;
    const { theme, setTheme } = useContext(ThemeContext);
    const [isModalOpen, setisModalOpen] = useState(false);

    const ticks = [0, 25000000, 50000000, 75000000, 100000000];
    const labelFormatter = (x) => {
        return convertToInternationalCurrencySystem(x, 1)
    };

    return (
        <>
            <CardProperties className={`${!modal && 'CardProperties'} ${cardType}`}>
                {!modal &&
                    <FlexBlockDiv>
                        <p className="font-style-montserrat-family-regular extra-small-text color-white">{title}</p>
                        <NoBorderIconButton clickAction={() => setisModalOpen(!isModalOpen)} icon={<AiOutlineExpand className="icon small-text color-lightgray" />} />
                    </FlexBlockDiv>}
                <ResponsiveContainer width="100%" height="100%" className="extra-extra-small-text font-style-montserrat-family-regular">
                    <BarChart width="100%" height="100%" data={data} >
                        <CartesianGrid style={{ stroke: `${ThemeVariables[theme]?.lines}` }} vertical={false} />
                        <XAxis dataKey="name" tick={{ width: 130, fill: `${ThemeVariables[theme]?.extreme_color}` }} interval={0} tickLine={false} />
                        <YAxis ticks={ticks} tickCount={23} hide />
                        <Bar shape={<Rectangle radius={[3, 3, 0, 0]} />} animationDuration={800} dataKey="eligible" name="Taxonomy - Eligible" fill="#0071dc" minPointSize={1} label={{ fill: `${ThemeVariables[theme]?.contrast_color}`, formatter: labelFormatter, position: "top" }}>
                        </Bar>
                        <Bar shape={<Rectangle radius={[3, 3, 0, 0]} />} animationDuration={800} dataKey="non_eligible" name="Taxonomy-Non-Eligible" fill="#35D272" minPointSize={1} label={{ fill: `${ThemeVariables[theme]?.contrast_color}`, formatter: labelFormatter, position: "top" }}>
                        </Bar>
                        <Legend wrapperStyle={{ maxWidth: "40%", whiteSpace: "break-spaces", paddingLeft: 10 }} iconType="square" formatter={(value) => <span className="legend-items-piechart font-style-montserrat-family-regular color-white">{value}</span>} layout="vertical" verticalAlign="middle" align="right" />
                        <Tooltip wrapperStyle={{ outline: 'none' }} content={<CurrencyTooltip />} shared={false} />
                    </BarChart>
                </ResponsiveContainer>
            </CardProperties>
            <ModalWindow title={title} sizeClass="charts-visualization" open={isModalOpen} setOpen={setisModalOpen}>
                <BarChartCard title={title} data={data} cardType="modal-card" modal={true}></BarChartCard>
            </ModalWindow>
        </>
    );
}

export default BarChartCard;