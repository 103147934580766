import React, { useState, useContext } from "react";
import { useNavigate } from 'react-router-dom';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getComparator, stableSort, EnhancedTableHead } from "../../utils/functions_variables/table_functions/TableCompFunctions";
import { BorderIconTextButton, NormalSearchBar } from "../../assets/styles/UIKit/UIKit";
import { convertToBigNumbers, roundStringToTwoDecimalPlacesOrMinorLabel } from "../../utils/Maths/MathsFunctions";
import { SimulatorContext } from "../../utils/functions_variables/variables";
import { BiSearch } from "react-icons/bi";
import { HiPlus } from "react-icons/hi";
import "./ManageSimulations.scss"

import LocalLoader from "../LocalLoader/LocalLoader";
import { CardProperties, NoBorderIconButton, NoBorderTextUnderlineButton } from "../../assets/styles/UIKit/UIKit";
import { AiOutlineReload } from "react-icons/ai";
import { FlexBlockDiv } from "../../assets/styles/global";

const ManageSimulations = ({ handleSimulationSearchChange }) => {
  const { isLoading, simulationsData, filteredSimulations, simulatorSearch } = useContext(SimulatorContext)
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState({ id: 'simulation_name', numeric: false, label: "Name" });
  const [selected, setSelected] = useState([]);
  const headCells = [
    {
      id: 'simulation_name',
      numeric: false,
      label: 'Name',
    },
    {
      id: 'nr_assets',
      numeric: true,
      label: 'Number assets',
    },
    {
      id: 'amount',
      numeric: true,
      label: 'Amount (EUR)',
    },
    {
      id: 'portfolio_weight_percentage',
      numeric: true,
      label: 'Weight',
    },
    // {
    //   id: '',
    //   numeric: false,
    //   label: '',
    // },
  ];

  function handleReloadPage() {
    //dataFetch()
  }

  const handleRequestSort = (event, property) => {  // DO NOT REMOVE THE EVENT ARGUMENT. STOPS WORKING.
    const isAsc = orderBy.id === property.id && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const isSelected = (name) => selected?.indexOf(name) !== -1;
  const navigate = useNavigate();

  function handleDeleteFundAction(fund) {
    // setDataToDelete(fund)
    // setisModalOpen(true)
  }

  function handleSimulationDetailsPage(fundName) {
    navigate(`simulation-profile/${fundName}`)
  }

  return (
    <>
      <div className="manage-simulations-div color-white">
        <CardProperties className="CardProperties">
          <FlexBlockDiv>
            <p className="font-style-montserrat-family-semibold small-text color-white">Manage Simulations</p>
            <NoBorderIconButton clickAction={handleReloadPage} icon={<AiOutlineReload className="icon medium-text color-white" />} />
            <NormalSearchBar placeholder="Search simulation..." searchHandler={handleSimulationSearchChange} value={simulatorSearch} />
            <BorderIconTextButton text="New simulation" icon={<HiPlus className="icon small-text"></HiPlus>} clickAction={() => navigate('create-simulation')} />
          </FlexBlockDiv>
        </CardProperties>
        <div className="table-div extra-small-text">
          <Paper sx={{ width: '100%', background: 'transparent', boxShadow: "none !important" }}>
            <TableContainer sx={{ maxHeight: 600 }}>
              <Table stickyHeader>
                <EnhancedTableHead headCells={headCells}
                  numSelected={selected?.length}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={filteredSimulations?.length} />
                <TableBody>
                  {isLoading ?
                    <TableRow>
                      <TableCell className="table-row-cell" colSpan={7} >
                        <div className="local-loader-div">
                          <LocalLoader size="textarea-big-input"></LocalLoader>
                        </div>
                      </TableCell>
                    </TableRow>
                    :
                    <>
                      {simulationsData?.length === 0 ?
                        <TableRow>
                          <TableCell className="table-row-cell" colSpan={7} >
                            <div className="no-simulations-found-div font-style-montserrat-family-regular small-text color-white">
                              <p>There are no created simulations.</p>
                              <NoBorderTextUnderlineButton extraClasses="small-text" text="Create simulation" clickAction={() => navigate('create-simulation')} />
                            </div>
                          </TableCell>
                        </TableRow>
                        :
                        filteredSimulations?.length === 0 ?
                          <TableRow>
                            <TableCell className="table-row-cell" colSpan={7} >
                              <div className="no-simulations-found-div font-style-montserrat-family-regular small-text color-white">
                                <p>There are no simulations matching the search.</p>
                              </div>
                            </TableCell>
                          </TableRow>
                          :
                          <>{stableSort(filteredSimulations, getComparator(order, orderBy))
                            ?.map((row, index) => {
                              const isItemSelected = isSelected(row?.name);
                              return (
                                <TableRow
                                  hover
                                  tabIndex={-1}
                                  key={index}
                                  selected={isItemSelected}>
                                  <TableCell className="table-row-cell limit-width font-style-montserrat-family-regular extra-small-text color-white" align="left">
                                    <NoBorderTextUnderlineButton text={row?.simulation_name} clickAction={() => handleSimulationDetailsPage(row?.simulation_name)} />
                                  </TableCell>
                                  <TableCell className="table-row-cell font-style-montserrat-family-regular extra-small-text color-white" align="left"><p className="font-style-montserrat-family-regular extra-small-text color-white">{row?.nr_assets}</p></TableCell>
                                  <TableCell className="table-row-cell font-style-montserrat-family-regular extra-small-text color-white" align="left"><p className="font-style-montserrat-family-regular extra-small-text color-white">{row?.amount ? "€ " + convertToBigNumbers(row?.amount) : "-"}</p></TableCell>
                                  <TableCell className="table-row-cell font-style-montserrat-family-regular extra-small-text color-white" align="left"><p className="font-style-montserrat-family-regular extra-small-text color-white">{roundStringToTwoDecimalPlacesOrMinorLabel(row?.portfolio_weight_percentage)}%</p></TableCell>
                                  {/* <TableCell className="table-row-cell">
                                     <FlexBlockDiv className="right-alignment">
                                        <NoBorderIconButton icon={<BiTrash className="icon-red small-text" />} clickAction={() => handleDeleteFundAction(row)} />
                                        <NoBorderIconButton icon={<TfiPencil className="icon-orange icon small-text" />} clickAction={() => handleEditFund(row?.fund_name)} />
                                      </FlexBlockDiv>
                                    </TableCell> */}
                                </TableRow>
                              );
                            })}
                          </>
                      }
                    </>
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
      </div>
      {/* <ModalWindow title={`Do you want to delete the fund '${dataToDelete.fund_name}'?`} classType="confirmation-input-action" open={isModalOpen} setOpen={setisModalOpen}>
        <div className="input-confirmation-field-div font-style-montserrat-family-regular extra-small-text color-white">
          <p>Write the fund name to confirm.</p>
          <NormalInput extraClasses="margin-top-1 margin-bottom-1" type="text" setText={handleInputChange} autoFocus={true} required={true} handleKeyDown={(event) => handleKeyDownEvent(handleConfirmAction, event)}  />
        </div>
        <FlexBlockDiv className="right-alignment margin-top-2">
          <NoBorderIconTextButton text="Cancel" clickAction={() => closeModal(isModalOpen, setisModalOpen)}/>
          <BorderTextButton extraClasses={!confirmedInput && "button-disabled"} text="Delete" clickAction={handleConfirmAction} disabled={!confirmedInput} />
        </FlexBlockDiv>
      </ModalWindow> */}
    </>
  );
}

export default ManageSimulations;
