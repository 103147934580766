
import React, { useContext, useState, useRef, useEffect } from "react";

import LocalLoader from "../../../LocalLoader/LocalLoader";
import MainIndicators from "./MainIndicators/MainIndicators";
import AdditionalClimateIndicators from "./AdditionalClimateIndicators/AdditionalClimateIndicators";
import AdditionalSocialEmployeeHumanRightsIndicators from "./AdditionalSocialEmployeeHumanRightsIndicators/AdditionalSocialEmployeeHumanRightsIndicators";

import "./StepTwo.scss"

import { getPAIStepTwoTableData, getPAIInputFieldsData } from "../../../../utils/requests/getRequests";
import { GeneralContext } from "../../../../utils/functions_variables/variables";

import { IoChevronDown } from "react-icons/io5";
import { BorderIconTextFullWidthButton, NoBorderTextButton } from "../../../../assets/styles/UIKit/UIKit";

const StepTwo = ({ selectedFund, step }) => {
  const { databaseAddress, setWarningMessage } = useContext(GeneralContext)
  const [tabPage, setTabPage] = useState(0)
  const [dataStatic, setDataStatic] = useState();
  const [dataDynamic, setDataDynamic] = useState();
  const [isLoading, setIsLoading] = useState(true)
  const [articlesOpen, setArticlesOpen] = useState(false);
  const [referencesOpen, setReferencesOpen] = useState(false);
  const [heightArticles, setHeightArticles] = useState(0);
  const [heightReferences, setHeightReferences] = useState(0);
  const articlesRef = useRef(null);
  const referencesRef = useRef(null);

  useEffect(() => {
    if (articlesRef?.current) {
      setHeightArticles(articlesRef.current.scrollHeight);
    }
  }, [articlesOpen]);

  const toggleShowArticles = () => {
    setArticlesOpen(!articlesOpen);
  }

  const toggleShowReferences = () => {
    setReferencesOpen(!referencesOpen);
  }

  useEffect(() => {
    if (referencesRef?.current) {
      setHeightReferences(referencesRef.current.scrollHeight);
    }
  }, [referencesOpen]);

  useEffect(() => {
    const PAIStepTwoInputData = async () => {
      setIsLoading(true)
      Promise.all([
        await getPAIStepTwoTableData(databaseAddress, selectedFund?.label).then(data => {
          setDataStatic(Object.keys(data)?.length > 1 && data);
        }).catch(error => {
          setIsLoading(false)
          setWarningMessage(["error", error.message])
        }),
        await getPAIInputFieldsData(databaseAddress, selectedFund?.label, "step2",).then(data => {
          setDataDynamic(data?.step2 ? data?.step2 : {});
          setIsLoading(false)
        }).catch(error => {
          setIsLoading(false)
          setWarningMessage(["error", error.message])
        })
      ])
    }

    PAIStepTwoInputData()
  }, [selectedFund])

  if (isLoading) {
    return <LocalLoader></LocalLoader>;
  }

  return (
    <div style={{ display: step === 2 ? 'flex' : 'none' }}>

      {dataStatic && dataDynamic ? <div className="steps-main-div extra-extra-small-text font-style-montserrat-family-regular color-white">
        <div className="articles">
          <BorderIconTextFullWidthButton text="Articles" icon={<IoChevronDown className={`icon ${articlesOpen && "close-180-deg"}`} />} clickAction={toggleShowArticles} />
          <div ref={articlesRef} className="articles-div" style={{ height: articlesOpen ? `${heightArticles}px` : '0', marginBottom: articlesOpen ? `15px` : '0' }}>
            <p className="title font-style-montserrat-family-semibold extra-small-text">Article 6 - Description of the principal adverse impacts of investment decisions on sustainability factors</p>
            <p className="text font-style-montserrat-family-regular extra-small-text "><label className="font-style-montserrat-family-semibold">1. </label>In the section ‘Description of the principal adverse impacts on sustainability factors’ in Table 1 of Annex I, financial market participants shall complete all the fields that relate to the indicators related to principal adverse impacts of their investment decisions on sustainability factors, and they shall add all of the following:<br />
              <label className="font-style-montserrat-family-semibold">(a)</label> information on one or more additional climate and other environment-related indicators, as set out in Table 2 of Annex I;<br />
              <label className="font-style-montserrat-family-semibold">(b)</label> information on one or more additional indicators for social and employee matters, respect for human rights, anti- corruption and anti-bribery matters, as set out in Table 3 of Annex I;<br />
              <label className="font-style-montserrat-family-semibold">(c)</label> information on any other indicators used to identify and assess additional principal adverse impacts on a sustainability factor.</p>
            <p className="text font-style-montserrat-family-regular extra-small-text "><label className="font-style-montserrat-family-semibold">2. </label>In the section ‘Description of the principal adverse impacts on sustainability factors’ in Table 1 of Annex I, financial market participants shall describe the actions taken during the period from 1 January to 31 December of the preceding year and actions planned or targets set for the subsequent period from 1 January to 31 December to avoid or reduce the principal adverse impacts identified.</p>
            <p className="text font-style-montserrat-family-regular extra-small-text "><label className="font-style-montserrat-family-semibold">3. </label>Financial market participants shall include in the columns ‘Impact’ in the section ‘Description of the principal adverse impacts on sustainability factors’ in Table 1 of Annex I a figure on impact as the average of impacts on 31 March, 30 June, 30 September and 31 December of each period from 1 January to 31 December.</p>
            <p className="title font-style-montserrat-family-semibold extra-small-text">Article 10 - Historical Comparison</p>
            <p className="text font-style-montserrat-family-regular extra-small-text ">Financial market participants that have described the adverse impacts on sustainability factors for a period preceding the period for which information is to be disclosed in accordance with Article 6 shall provide in the section ‘Description of principal adverse impacts on sustainability factors’ in Table 1 of Annex I a historical comparison of the period reported on with the previous period reported on and, subsequently, with every previous period reported on up to the last five previous periods.</p>
          </div>
        </div>
        <div className="references">
          <BorderIconTextFullWidthButton text="References" icon={<IoChevronDown className={`icon ${referencesOpen && "close-180-deg"}`} />} clickAction={toggleShowReferences} />
          <div ref={referencesRef} className="references-div" style={{ height: referencesOpen ? `${heightReferences}px` : '0', marginBottom: referencesOpen ? `20px` : '0' }}>
            <p className="text font-style-montserrat-family-regular extra-small-text"><label className="font-style-montserrat-family-semibold color-white">1.</label><label className="font-style-montserrat-family-regular color-white"> Principal Adverse Impacts of Investment Decisions on Sustainability Factors - Metric 16 - Fragile States Index</label><a className="color-white" href="https://fragilestatesindex.org/" target="_blank">Link</a></p>
            <p className="text font-style-montserrat-family-regular extra-small-text"><label className="font-style-montserrat-family-semibold color-white">2.</label><label className="font-style-montserrat-family-regular color-white"> Additional Climate and Other Environment-Related Indicators - Metric 16 & Metric 17 - LUXSE & EURONEXT & CLIMATE BONDS</label> <a className="color-white" href="https://www.bourse.lu/index.jsp" target="_blank">Link</a>  -  <a className="color-white" href="https://www.euronext.com/en" target="_blank">Link</a>  -  <a className="color-white" href="https://www.climatebonds.net/" target="_blank">Link</a></p>
            <p className="text font-style-montserrat-family-regular extra-small-text"><label className="font-style-montserrat-family-semibold color-white">3.</label><label className="font-style-montserrat-family-regular color-white"> Additional Indicators for Social and Employee, Respect for Human Rights, Anti-Corruption and Anti-Bribery Matters - Metric 18 - Worldbank GINI Index</label> <a className="color-white" href="https://www.worldbank.org/en/home" target="_blank">Link</a></p>
            <p className="text font-style-montserrat-family-regular extra-small-text"><label className="font-style-montserrat-family-semibold color-white">4.</label><label className="font-style-montserrat-family-regular color-white"> Additional Indicators for Social and Employee, Respect for Human Rights, Anti-Corruption and Anti-Bribery Matters - Metric 19 - CIVICU</label> <a className="color-white" href="https://monitor.civicus.org/" target="_blank">Link</a></p>
            <p className="text font-style-montserrat-family-regular extra-small-text"><label className="font-style-montserrat-family-semibold color-white">5.</label><label className="font-style-montserrat-family-regular color-white"> Additional Indicators for Social and Employee, Respect for Human Rights, Anti-Corruption and Anti-Bribery Matters - Metric 20 - Fragile States Index</label> <a className="color-white" href="https://fragilestatesindex.org/" target="_blank">Link</a></p>
            <p className="text font-style-montserrat-family-regular extra-small-text"><label className="font-style-montserrat-family-semibold color-white">6.</label><label className="font-style-montserrat-family-regular color-white"> Additional Indicators for Social and Employee, Respect for Human Rights, Anti-Corruption and Anti-Bribery Matters - Metric 21 - Fragile States Index</label> <a className="color-white" href="https://fragilestatesindex.org/" target="_blank">Link</a></p>
            <p className="text font-style-montserrat-family-regular extra-small-text"><label className="font-style-montserrat-family-semibold color-white">7.</label><label className="font-style-montserrat-family-regular color-white"> Additional Indicators for Social and Employee, Respect for Human Rights, Anti-Corruption and Anti-Bribery Matters - Metric 22 - EU List of Non-Cooperative Jurisdictions for Tax Purposes</label> <a className="color-white" href="https://www.consilium.europa.eu/" target="_blank">Link</a></p>
            <p className="text font-style-montserrat-family-regular extra-small-text"><label className="font-style-montserrat-family-semibold color-white">8.</label><label className="font-style-montserrat-family-regular color-white"> Additional Indicators for Social and Employee, Respect for Human Rights, Anti-Corruption and Anti-Bribery Matters - Metric 23 - The Global Economy Political Stability Country Rankings</label> <a className="color-white" href="https://www.theglobaleconomy.com/" target="_blank">Link</a></p>
            <p className="text font-style-montserrat-family-regular extra-small-text"><label className="font-style-montserrat-family-semibold color-white">9.</label><label className="font-style-montserrat-family-regular color-white"> Additional Indicators for Social and Employee, Respect for Human Rights, Anti-Corruption and Anti-Bribery Matters - Metric 24 - World Justice Project Rule of Law Index</label> <a className="color-white" href="https://worldjusticeproject.org/" target="_blank">Link</a></p>
          </div>
        </div>
        <div className="sfdr-table-page-menu">
          <div className="multi-step-main-div margin-bottom-1">
            <ul className="ul-three-columns form-stepper">
              <li className={`form-stepper-${tabPage === 1 ? "active" : "unfinished"} form-stepper-list`} step="1" onClick={() => setTabPage(1)}>
                <a>
                  <div className="label font-style-montserrat-family-regular extra-small-text">PRINCIPAL ADVERSE IMPACTS OF INVESTMENT DECISIONS ON SUSTAINABILITY FACTORS (MANDATORY DISCLOSURES)</div>
                </a>
              </li>
              <li className={`form-stepper-${tabPage === 2 ? "active" : "unfinished"} form-stepper-list`} onClick={() => setTabPage(2)} step="2">
                <a>
                  <div className="label font-style-montserrat-family-regular extra-small-text">ADDITIONAL CLIMATE AND OTHER ENVIRONMENT-RELATED INDICATORS (ADDITIONAL DISCLOSURES)</div>
                </a>
              </li>
              <li className={`form-stepper-${tabPage === 3 ? "active" : "unfinished"} form-stepper-list`} onClick={() => setTabPage(3)} step="3">
                <a>
                  <div className="label font-style-montserrat-family-regular extra-small-text">ADDITIONAL INDICATORS FOR SOCIAL AND EMPLOYEE, RESPECT FOR HUMAN RIGHTS, ANTI-CORRUPTION AND ANTI-BRIBERY MATTERS (ADDITIONAL DISCLOSURES)</div>
                </a>
              </li>
            </ul>
          </div>
          <div className="sfdr-table-page-menu-active-div">
            <div className={`${tabPage === 1 ? "active" : "non-active"}`} ></div>
            <div className={`${tabPage === 2 ? "active" : "non-active"}`} ></div>
            <div className={`${tabPage === 3 ? "active" : "non-active"}`} ></div>
          </div>
        </div>
        {tabPage === 1 ? <MainIndicators selectedFund={selectedFund} dataDynamic={dataDynamic?.mainIndicators} dataStatic={dataStatic?.mainIndicators} /> : ""}
        {tabPage === 2 ? <AdditionalClimateIndicators selectedFund={selectedFund} dataDynamic={dataDynamic?.additionalClimate} dataStatic={dataStatic?.additionalClimate} /> : ""}
        {tabPage === 3 ? <AdditionalSocialEmployeeHumanRightsIndicators selectedFund={selectedFund} dataDynamic={dataDynamic?.additionalSocial} dataStatic={dataStatic?.additionalSocial} /> : ""}
        <p className="margin-top-2 copyright-factset-label font-style-montserrat-family-regular extra-extra-small-text color-white">Copyright © 2023 FactSet UK Limited. All rights reserved.</p>
      </div>
        :
        <div className="pai-no-data-div font-style-montserrat-family-semibold small-text color-white">There is no data available for this step yet.</div>}
    </div>
  );
}

export default StepTwo;