import React from "react";

import "./TextCard.scss"

import { CardProperties } from "../../../assets/styles/UIKit/UIKit";

const TextCard = ({ title, data, cardType }) => {

    return (
        <CardProperties className={`CardProperties text-card-div ${cardType} extra-small-text font-style-montserrat-family-regular color-white`}>
            <p>{title}</p>
            <ul>
                {data?.map((child, index) => <li key={index}>{child?.name} with {`${(child?.percentage * 100)?.toFixed(1).replace(/[.,]0/, "")}%`}</li>)}
            </ul>
        </CardProperties>
    );
}

export default TextCard;