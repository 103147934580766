import React, { useState, useContext } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

import "./MetricQuestionBox.scss"
import ThemeContext, { ThemeVariables } from "../../assets/styles/darkLightMode/theme-context";
import { HiPlus } from "react-icons/hi";

import ModalWindow from "../ModalWindow/ModalWindow"
import { BorderTextFullWidthButton, BorderTextIconFullWidthFullHeightButton } from "../../assets/styles/UIKit/UIKit";

const MetricQuestionGraph = ({ text, children, sizeClass }) => {
    const { theme } = useContext(ThemeContext);
    const [isModalOpen, setisModalOpen] = useState(false);

    return (
        <>
            <BorderTextIconFullWidthFullHeightButton text={text} icon={<HiPlus className="icon color-white" />} clickAction={() => setisModalOpen(!isModalOpen)} />
            <ModalWindow title={text} sizeClass={sizeClass ? "two-charts-visualization" : "charts-visualization"} open={isModalOpen} setOpen={setisModalOpen}>
                <div className="metric-question-graph-modal">
                    {children}
                </div>
            </ModalWindow>
        </>
    );
}

export default MetricQuestionGraph;