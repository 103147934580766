import React, { useContext } from "react";
import Select from 'react-select'

import "./FilterCompanyCard.scss";

import { CardProperties, NormalControlReactSelect, NormalDropdown } from "../../../assets/styles/UIKit/UIKit";
import ThemeContext, { ThemeVariables } from "../../../assets/styles/darkLightMode/theme-context";
import { InputOptionSingleValue } from "../../../assets/styles/global";
import { TransparentSearchBarControlReactSelect } from "../../../assets/styles/UIKit/UIKit";

function FilterCompanyCard({ cardType, companies, data_1, data_2, data_3 }) {
    const { theme } = useContext(ThemeContext);

    return (
        <CardProperties className={`CardProperties filter-card-company-div ${cardType}`}>
            <div className="filter-company-card-select-company-div small-text font-style-montserrat-family-semibold color-white">
                <p>Choose Company</p>
                <NormalDropdown noOptionsMessage='No results match your searching criteria.' extraClasses="navbar-dropdown-search-bar"
                    list={companies} components={{ Option: InputOptionSingleValue, Control: NormalControlReactSelect }}
                    defaultValue={companies && companies[0]} />
            </div>
            <div className="filter-company-card-three-stats">
                <div className="filter-company-card-stat">
                    <p className="font-style-montserrat-family-semibold large-text color-white">{`${(data_1?.percentage * 100)?.toFixed(1).replace(/[.,]0/, "")}%`}</p>
                    <p className="font-style-montserrat-family-regular small-text color-white">{data_1?.name}</p>
                </div>
                <div className="filter-company-card-stat">
                    <p className="font-style-montserrat-family-semibold large-text color-white">{`${(data_2?.percentage * 100)?.toFixed(1).replace(/[.,]0/, "")}%`}</p>
                    <p className="font-style-montserrat-family-regular small-text color-white">{data_2?.name}</p>
                </div>
                <div className="filter-company-card-stat">
                    <p className="font-style-montserrat-family-semibold large-text color-white">{`${(data_3?.percentage * 100)?.toFixed(1).replace(/[.,]0/, "")}%`}</p>
                    <p className="font-style-montserrat-family-regular small-text color-white">{data_3?.name}</p>
                </div>
            </div>
        </CardProperties>
    );
}

export default FilterCompanyCard;