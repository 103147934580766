import React, { useContext } from "react";

import "./ManageFund.scss"
import { ManageFundContext } from "../../../utils/functions_variables/variables";

import CreatedAssetCard from "./CreatedAssetCard";
import AddedAssetCard from "./AddedAssetCard";

const Assets = ({ setButtonEnable }) => {
    const { purpose, fundAssets, selectedDate, createdAssets } = useContext(ManageFundContext)

    return (
        <div className="added-assets-div">
            <div className="added-assets-label-div">
                <label className="added-assets-label font-style-montserrat-family-semibold extra-small-text color-white">Assets</label>
                <label className="added-assets-label font-style-montserrat-family-semibold extra-small-text color-white">Asset type</label>
                <label className="added-assets-label font-style-montserrat-family-semibold extra-small-text color-white">Shares</label>
                <label className="added-assets-label font-style-montserrat-family-semibold extra-small-text color-white">Amount</label>
                <label className="added-assets-label font-style-montserrat-family-semibold extra-small-text color-white">Currency</label>
            </div>
            <div className="added-assets-list-div">
                {(fundAssets[selectedDate] && fundAssets[selectedDate]?.length > 0) || (createdAssets[selectedDate] && createdAssets[selectedDate]?.length > 0)
                    ?
                    <React.Fragment>
                        {purpose !== "details" && (createdAssets[selectedDate] && createdAssets[selectedDate]?.length > 0) &&
                            createdAssets[selectedDate]?.map((label, index) => (
                                <CreatedAssetCard key={index} keySecondary={label?.keySecondary} text={label?.label} type={label?.type} currency={label?.currency} amount={label?.amount} share={label?.share} setButtonEnable={setButtonEnable} />))
                        }
                        {(fundAssets[selectedDate] && fundAssets[selectedDate]?.length > 0) &&
                            fundAssets[selectedDate]?.map((label, index) => (
                                <AddedAssetCard key={index} keySecondary={label?.keySecondary} text={label?.label} type={label?.type} currency={label?.currency} amount={label?.amount} share={label?.share} setButtonEnable={setButtonEnable} />))
                        }
                    </React.Fragment>
                    :
                    <label className="label-empty-assets extra-small-text font-style-montserrat-family-semibold color-blue">Add assets to your portfolio.</label>}
            </div>
        </div>
    );
}

export default Assets;