import React, { useEffect, useContext } from "react";
import ThemeContext, { ThemeVariables } from "../../../assets/styles/darkLightMode/theme-context";
import { ManageFundContext } from "../../../utils/functions_variables/variables";

import "./ManageFund.scss"

import { InputOptionSingleValue } from "../../../assets/styles/global";
import { BackgroundSearchBarControlReactSelect, NormalDropdown, NormalDropdownSearchBar, TransparentSearchBarControlReactSelect } from "../../../assets/styles/UIKit/UIKit";
import Select from 'react-select';
import FormField from "./FormField";
import { NoBorderTextUnderlineButton } from "../../../assets/styles/UIKit/UIKit";

const AddAsset = ({ handleNotFound }) => {
    const { theme } = useContext(ThemeContext);
    const { assetsList, fundAssets, setFundAssets, selectedAsset, setSelectedAsset, selectedDate, createdAssets, setCreatedAssets } = useContext(ManageFundContext)

    const filterOptions = (option, inputValue) => {
        const { label, isin, ticker } = option?.data;
        const searchValue = inputValue?.toLowerCase();
        return (
            isin !== null ? (ticker !== null ?
                label?.toLowerCase().includes(searchValue) ||
                (isin?.toLowerCase().includes(searchValue)) ||
                (ticker?.toLowerCase().includes(searchValue))
                :
                label?.toLowerCase().includes(searchValue) ||
                (isin?.toLowerCase().includes(searchValue)))
                :
                (ticker !== null ?
                    label?.toLowerCase().includes(searchValue) ||
                    (ticker?.toLowerCase().includes(searchValue))
                    :
                    label?.toLowerCase().includes(searchValue))
        );
    };

    function handleAddAsset(selectedOption) {
        setSelectedAsset(selectedOption);
        let newArray = {}
        if (selectedOption?.created) {
            newArray = {
                ...createdAssets,
                [selectedDate]: [
                    {
                        value: selectedOption?.value,
                        keySecondary: Math.floor(Math.random() * 1000000000),
                        label: selectedOption?.label,
                        type: "",
                        currency: "",
                        amount: "",
                        share: "",
                        isin: selectedOption?.isin,
                        ticker: selectedOption?.ticker,
                        public_asset: selectedOption?.public,
                        country: selectedOption?.countries,
                        investment_type: selectedOption?.investmentType
                    },
                    ...(createdAssets[selectedDate] || [])
                ]
            };
            setCreatedAssets(newArray);
        } else {
            newArray = {
                ...fundAssets,
                [selectedDate]: [
                    {
                        value: selectedOption?.value,
                        keySecondary: Math.floor(Math.random() * 1000000000),
                        label: selectedOption?.label,
                        type: "",
                        currency: "",
                        amount: "",
                        share: ""
                    },
                    ...(fundAssets[selectedDate] || [])
                ]
            };
            setFundAssets(newArray);
        }
    }

    const getOptionLabel = (option) => (option?.ticker !== null && option?.ticker !== "" ? (option?.label + " (Ticker: " + option?.ticker + ")") : option?.isin !== null && option?.isin !== "" ? (option?.label + " (ISIN: " + option?.isin + ")") : option?.label);
    const getOptionValue = (option) => option?.value;

    return (
        <div className="add-asset-div">
            <FormField fieldName="Search asset" field={
                <NormalDropdown noOptionsMessage='No assets found. Check if it is correct and, if yes, create a new asset.' value={selectedAsset} list={assetsList} placeholder="Search an asset..." handleChange={handleAddAsset}
                filterOption={filterOptions} getOptionLabel={getOptionLabel} getOptionValue={getOptionValue} components={{ Option: InputOptionSingleValue, Control: BackgroundSearchBarControlReactSelect }} />
            } />
            <div className="not-found-actions-div">
                <label className="not-found-label extra-small-text font-style-montserrat-family-regular color-white">Not found in our database?</label>
                <NoBorderTextUnderlineButton text="Give us some info about the asset" clickAction={handleNotFound} />
            </div>
        </div>
    );
}

export default AddAsset;