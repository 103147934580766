import { useContext } from "react";
import styled from "styled-components";
import Select, { components } from 'react-select';

import "./UIKit.scss";

import { FaChevronUp } from "react-icons/fa";
import { handleKeyDownEvent, toTop } from "../../../utils/functions_variables/functions";
import { PasswordEyeButton, reactSelectNavbarSearchBarStyles, reactSelectNormalSearchBarStyles, reactSelectNormalStyles } from "../global";
import { BiSearch } from "react-icons/bi";
import ThemeContext, { ThemeVariables } from "../darkLightMode/theme-context";
import { RxCross2 } from "react-icons/rx";

// CARDS
export const CardProperties = styled.div`
  padding: var(--size-four);
  border-radius: var(--border-radius);
  align-items: flex-start;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  box-sizing: border-box;
`;

//////////////////////

// BUTTONS
export function ScrollToTopButton() {
  return (
    <button id="scroll-to-top-button" onClick={() => { toTop() }} className="scroll-to-top-button small-text">
      <FaChevronUp />
    </button>
  );
}

export function BorderIconTextButton({ clickAction, icon, text, extraClasses, disabled }) {
  return (
    <button onClick={clickAction} className={`${extraClasses} button-styled border-icon-text-button color-white`} disabled={disabled}>
      {icon}
      <p className="font-style-montserrat-family-regular extra-small-text color-white">{text}</p>
    </button>
  );
}

export function BorderTextButton({ clickAction, text, extraClasses, type, disabled }) {
  return (
    <button onClick={clickAction} className={`${extraClasses} button-styled text-border-button color-white`} type={type} disabled={disabled}>
      <p className="font-style-montserrat-family-regular extra-small-text color-white">{text}</p>
    </button>
  );
}

export function BorderTextFullWidthButton({ clickAction, text, extraClasses, type, disabled }) {
  return (
    <button onClick={clickAction} className={`${extraClasses} button-styled border-text-full-width-button color-white`} type={type} disabled={disabled}>
      <p className="font-style-montserrat-family-regular extra-small-text color-white">{text}</p>
    </button>
  );
}

export function BorderTextIconFullWidthFullHeightButton({ clickAction, text, icon, extraClasses, type, disabled }) {
  return (
    <button onClick={clickAction} className={`${extraClasses} button-styled border-text-icon-full-width-full-height-button color-white`} type={type} disabled={disabled}>
      <p className="font-style-montserrat-family-regular extra-small-text color-white">{text}</p>
      {icon}
    </button>
  );
}

export function BorderIconTextFullWidthButton({ clickAction, icon, text, extraClasses, type, disabled }) {
  return (
    <button onClick={clickAction} className={`${extraClasses} button-styled border-icon-text-full-width-button color-white`} type={type} disabled={disabled}>
      {icon}
      <p className="font-style-montserrat-family-semibold extra-small-text color-white">{text}</p>
    </button>
  );
}

export function NoBorderTextIconFullWidthButton({ clickAction, icon, text, extraClasses, type, disabled }) {
  return (
    <button onClick={clickAction} className={`${extraClasses} button-styled no-border-text-icon-full-width-button color-white`} type={type} disabled={disabled}>
      <p className="font-style-montserrat-family-regular extra-small-text color-white">{text}</p>
      {icon}
    </button>
  );
}

export function NoBorderIconTextButton({ clickAction, icon, text, extraClasses, type, disabled }) {
  return (
    <button onClick={clickAction} className={`${extraClasses} button-styled no-border-icon-text-button color-white`} type={type} disabled={disabled}>
      {icon}
      <p className="font-style-montserrat-family-regular extra-small-text color-white">{text}</p>
    </button>
  );
}

export function NoBorderTextButton({ clickAction, text, extraClasses, type, disabled }) {
  return (
    <button onClick={clickAction} className={`${extraClasses} button-styled no-border-text-button color-white`} type={type} disabled={disabled}>
      <p className="font-style-montserrat-family-regular extra-small-text color-white">{text}</p>
    </button>
  );
}

export function NoBorderIconButton({ extraClasses, clickAction, icon, type, disabled }) {
  return (
    <button onClick={clickAction} className={`${extraClasses} button-styled no-border-icon-button`} type={type} disabled={disabled}>
      {icon}
    </button>
  );
}

export function NoBorderTextUnderlineButton({ extraClasses, clickAction, text, type, disabled }) {
  return (
    <button onClick={clickAction} className={`${extraClasses} button-styled no-border-text-underline-button font-style-montserrat-family-regular extra-small-text color-white`} type={type} disabled={disabled}>
      <p>{text}</p>
    </button>
  );
}

export function BackgroundStraighAnglesFullWidthIconTextButton({ extraClasses, clickAction, text, icon, type, disabled }) {
  return (
    <button onClick={clickAction} className={`${extraClasses} button-styled background-straight-angles-full-width-icon-text-button font-style-montserrat-family-regular extra-small-text color-white`} type={type} disabled={disabled}>
      {icon}
      <p>{text}</p>
    </button>
  );
}

export function BackgroundStraighAnglesFullWidthIconButton({ extraClasses, clickAction, icon, type, disabled }) {
  return (
    <button onClick={clickAction} className={`${extraClasses} button-styled background-straight-angles-full-width-icon-button font-style-montserrat-family-regular extra-small-text color-white`} type={type} disabled={disabled}>
      {icon}
    </button>
  );
}

export function ExpandableLeftMenuTopButton({ extraClasses, clickAction, text, icon, open }) {
  return (
    <button className={`${extraClasses} button-styled expandable-left-menu-top-button font-style-montserrat-family-semibold extra-small-text color-white`} onClick={clickAction}>
      {open && <p >{text}</p>}
      {icon}
    </button>
  );
}

export function ExpandableLeftMenuTabButton({ extraClasses, clickAction, statusIcon, icon, text, open }) {
  return (
    <button className={`button-styled expandable-left-menu-tab-button ${extraClasses} font-style-montserrat-family-regular extra-small-text color-white`} onClick={clickAction}>
      {statusIcon}
      {icon}
      {open && <p>{text}</p>}
    </button>
  );
}

//////////////////////

// INPUTS

export function NormalInput({ extraClasses, type, maxLength, name, placeholder, handleKeyDown, text, setText, onBlur, onFocus, autoFocus, required, disabled }) {
  return (
    <input type={type} placeholder={placeholder} className={`${extraClasses} font-style-montserrat-family-regular extra-small-text color-darkgray`}
      onChange={(e) => setText(e?.target?.value)} name={name} onKeyDown={(event) => handleKeyDownEvent(handleKeyDown, event)}
      value={text} onBlur={onBlur} onFocus={onFocus} autoFocus={autoFocus} maxLength={maxLength} required={required} disabled={disabled} />
  );
}

export function PasswordInput({ extraClasses, placeholder, handleKeyDown, password, setPassword, showPassword, toggleShowPassword, autoComplete, required }) {
  return (
    <div className={`${extraClasses} password-div`}>
      <input type={showPassword ? 'text' : 'password'} placeholder={placeholder} onKeyDown={(event) => handleKeyDownEvent(handleKeyDown, event)} className="font-style-montserrat-family-regular extra-small-text color-darkgray"
        onChange={(e) => setPassword(e?.target?.value)}
        value={password} autoComplete={autoComplete} required={required} />
      <PasswordEyeButton clickAction={toggleShowPassword} showCondition={showPassword} />
    </div>
  );
}

export function Textarea({ placeholder, text, setText, numberRows, name, maxLength, type, required }) {
  return (
    <textarea rows={numberRows} maxLength={maxLength} type={type} className="font-style-montserrat-family-regular extra-small-text color-darkgray" placeholder={placeholder}
      onChange={(e) => setText(e?.target?.value)} value={text} name={name} required={required} />
  )
}

export function CheckboxInput({ name, extraClasses, value, handleChange, required, disabled }) {
  return (
    <input type="checkbox" style={{
      width: "var(--size-three)",
      height: "var(--size-three)"
    }} name={name} className={`${extraClasses} font-style-montserrat-family-regular extra-small-text color-darkgray`}
      onChange={handleChange} checked={value} required={required} disabled={disabled} />
  );
}

export function RadioInput({ name, extraClasses, value, handleChange, required, disabled }) {
  return (
    <input type="radio" name={name} className={`${extraClasses} font-style-montserrat-family-regular extra-small-text color-darkgray`}
      onChange={handleChange} value={value} required={required} disabled={disabled} />
  );
}

export function NormalSearchBar({ searchHandler, placeholder, search }) {
  return (
    <div className="normal-search-bar">
      <input type="text" className="search-bar-input font-style-manrope-family-regular extra-small-text color-white" value={search} onChange={searchHandler} placeholder={placeholder}></input>
      <BiSearch className="search-bar-icon small-text color-lightgray" ></BiSearch>
    </div>
  )
}

//////////////////////

// REACT-SELECT

// transparent search bar control component //
export const TransparentSearchBarControlReactSelect = ({ children, ...props }) => {
  return (
    <components.Control {...props}>
      <div className="search-bar-control-react-select color-white">
        <BiSearch className="icon small-text color-white" />
        {children}
      </div>
    </components.Control>
  );
};

// background search bar control component //
export const BackgroundSearchBarControlReactSelect = ({ children, ...props }) => {
  return (
    <components.Control {...props}>
      <div className="search-bar-control-react-select color-black">
        <BiSearch className="icon small-text color-black" />
        {children}
      </div>
    </components.Control>
  );
};

export const NormalControlReactSelect = ({ children, ...props }) => {
  return (
    <components.Control {...props}>
      <div className="normal-control-react-select color-black">
       {children}
      </div>
    </components.Control>
  );
};

// dropdown list separator header //
export const GroupHeading = (
  props
) => {
  return (<div className="group-heading-styles-div">
    <components.GroupHeading className="color-white" {...props} />
  </div>)
};

// ismulti option box //
export const IsMultiOptionBox = (
  option
) => {
  return (<div className="is-multi-option-box">
    <p className="font-style-montserrat-family-regular extra-small-text color-white">{option.option}</p>
    <NoBorderIconButton clickAction={() => option.onClickAction(option.option)} icon={<RxCross2 className="icon extra-small-text color-white" />} />
  </div>)
};

// dropdown option item for navbar searchbar assets and funds items //
export const ReactSelectNavbarFundOrAssetOption = ({
  data,
  ...rest
}) => {

  return (
    <>
      {data?.label &&
        <components.Option {...rest} >
          <div className="fund-asset-option-value">
            <div className={`left-block ${data.type === "fund" ? "background-blue" : "background-green"}`}>
              <p className="font-style-montserrat-family-semibold extra-extra-extra-small-text color-white">{data.type === "fund" ? "F" : "A"}</p>
            </div>
            <div className="right-block">
              <p className="name font-style-montserrat-family-semibold extra-small-text color-white">{data.label}</p>
              <p className="secondary-info font-style-montserrat-family-regular extra-extra-small-text color-dark-gray">{data.type === "fund" ? data.nr_assets > 1 ? data.nr_assets + " assets" : data.nr_assets + " asset" : data.ticker}</p>
            </div>
          </div>
        </components.Option>
      }
    </>
  );
}

// navbar searchbar with dropdown  //
export function NavbarDropdownSearchBar({ list, placeholder, inputText, setInputText, components, handleChange, handleInput }) {
  const { theme } = useContext(ThemeContext);
  const search_bar_styles = reactSelectNavbarSearchBarStyles(ThemeVariables[theme])

  return (
    <Select className={`${!list && "button-disabled"} navbar-dropdown-search-bar font-style-montserrat-family-regular extra-small-text color-white`}
      styles={search_bar_styles}
      noOptionsMessage={() => 'No results match your search criteria.'}
      onChange={(event) => handleChange(event)}
      inputValue={inputText}
      maxMenuHeight={600}
      value={""}
      menuPosition={'fixed'}
      minMenuHeight={250}
      onInputChange={(input, action) => handleInput(input, action, setInputText)}
      options={list}
      placeholder={placeholder}
      isDisabled={list ? false : true}
      components={components}
    />
  )
}

export function NormalDropdown({ extraClasses, value, list, placeholder, components, handleChange, noOptionsMessage, isMulti, closeMenuOnSelect, hideSelectedOptions, getOptionLabel, getOptionValue, controlShouldRenderValue, defaultValue, filterOptions, borderStatus }) {
  const { theme } = useContext(ThemeContext);
  const search_bar_styles = reactSelectNormalStyles(ThemeVariables[theme], borderStatus)

  return (
    <Select className={`${!list && "button-disabled"} ${extraClasses} font-style-montserrat-family-regular extra-small-text color-white`}
      styles={search_bar_styles}
      minMenuHeight={100}
      maxMenuHeight={300}
      noOptionsMessage={() => noOptionsMessage}
      getOptionLabel={getOptionLabel}
      filterOption={filterOptions}
      getOptionValue={getOptionValue}
      onChange={(event) => handleChange(event)}
      value={value}
      defaultValue={defaultValue}
      menuPlacement="auto"
      options={list}
      isMulti={isMulti}
      closeMenuOnSelect={closeMenuOnSelect}
      hideSelectedOptions={hideSelectedOptions}
      controlShouldRenderValue={controlShouldRenderValue}
      placeholder={placeholder}
      isDisabled={list ? false : true}
      components={components}
    />
  )
}

//////////////////////