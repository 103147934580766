import React from "react";

import "./Configurations.scss"

import HeaderTitle from "../../components/HeaderTitle/HeaderTitle";

const TermsAndConditions = () => {

  return (
    <div className="configurations-main-div">
      <HeaderTitle text="Terms & Conditions"></HeaderTitle>
    </div>

  );
}

export default TermsAndConditions;