import React, { useState, useContext } from "react";

import "../FundProfile.scss"

import ThemeContext, { ThemeVariables } from "../../../../../assets/styles/darkLightMode/theme-context";
import { FundProfileContext } from "../../../../../utils/functions_variables/variables";
import { InputOptionSingleValue } from "../../../../../assets/styles/global";
import { NormalDropdown, NormalControlReactSelect } from "../../../../../assets/styles/UIKit/UIKit";

import { Tooltip } from 'recharts';
import { HiPlus } from "react-icons/hi";

import HeaderTitle from "../../../../HeaderTitle/HeaderTitle"
import LineChartGraphCard from "../../../../DataVisualization/LineChartGraphCard/LineChartGraphCard";
import MultipleLinesChartWithThresholdCard from "../../../../DataVisualization/MultipleLinesChartWithThresholdCard/MultipleLinesChartWithThresholdCard";
import MetricsBoardAnalysisCard from "../../../../Cards/MetricsBoardAnalysisCard/MetricsBoardAnalysisCard";
import RankingDotsTwoCategoriesChartCard from "../../../../DataVisualization/RankingDotsTwoCategoriesChartCard/RankingDotsTwoCategoriesChartCard";
import ChoroplethMapWorldCard from "../../../../DataVisualization/ChoroplethMapWorldCard/ChoroplethMapWorldCard";
import HorizontalBarChartPercentageCard from "../../../../DataVisualization/HorizontalBarChartPercentageCard/HorizontalBarChartPercentageCard";
import { AmountTooltip } from "../../../../DataVisualization/Tooltips";

import { CardProperties } from "../../../../../assets/styles/UIKit/UIKit";

const FundProfileEnvironmental = () => {
  const { fundCharacteristicsData, availableMetrics, fundSelectedMetrics, fundEnvironmentalData, handleAddMetricToFundCharacteristicsData, handleRemoveMetricToFundCharacteristicsData } = useContext(FundProfileContext)

  const { theme } = useContext(ThemeContext);

  function generateUniqueKey() {
    return Math.random().toString(36).substr(2, 9);
  }
  return (
    <div className="fund-profile-content">
      <HeaderTitle text="General metrics"></HeaderTitle>
      <MultipleLinesChartWithThresholdCard title="Total emissions" linesKeys={[{ label: "Scope 1", value: "scope1" }, { label: "Scope 2", value: "scope2" }, { label: "Scope 3", value: "scope3" }, { label: "Total", value: "total" }]} cardType="medium-chart-card-size card-height-3" multipleLines={true} data={fundEnvironmentalData?.CO2T?.totalEmissionData?.sort((a, b) => a.year - b.year)} />
      <LineChartGraphCard title="CO2 emissions intensity (per EUR invested)" cardType="medium-chart-card-size card-height-3" multipleLines={true} data={fundEnvironmentalData?.CO2T?.emissionsIntensityPerEurInvested?.sort((a, b) => a.year - b.year)} />
      <LineChartGraphCard title="CO2 emissions intensity (per revenue)" cardType="medium-chart-card-size card-height-3" multipleLines={true} data={fundEnvironmentalData?.CO2T?.emissionsIntensityPerRevenue?.sort((a, b) => a.year - b.year)} />
      <RankingDotsTwoCategoriesChartCard title="Top 5 best and worst emitters (rate per EUR invested)" data={fundEnvironmentalData?.CO2T?.bestAndWorstAssetsPerEurInvested} cardType="medium-chart-card-size card-height-3" />
      <RankingDotsTwoCategoriesChartCard title="Top 5 best and worst emitters (rate per revenue)" data={fundEnvironmentalData?.CO2T?.bestAndWorstAssetsPerRevenue} cardType="medium-chart-card-size card-height-3" />
      <RankingDotsTwoCategoriesChartCard title="Top 3 best and worst industries (rate per EUR invested)" data={fundEnvironmentalData?.CO2T?.bestAndWorstIndustriesPerEurInvested} cardType="medium-chart-card-size card-height-3" />
      <RankingDotsTwoCategoriesChartCard title="Top 3 best and worst industries (rate per revenue)" data={fundEnvironmentalData?.CO2T?.bestAndWorstIndustriesPerRevenue} cardType="medium-chart-card-size card-height-3" />
      <ChoroplethMapWorldCard title="CO2 emissions per Country" cardType="medium-chart-card-size card-height-3" data={fundEnvironmentalData?.CO2T?.co2PerCountry}></ChoroplethMapWorldCard>
      <HorizontalBarChartPercentageCard title="CO2 emissions per Industry" data={fundEnvironmentalData?.CO2T?.co2PerIndustry} dataKey="percentage" yAxisLabel="label" categoryName="Emissions" cardType="medium-chart-card-size card-height-3">
        <Tooltip wrapperStyle={{ outline: 'none' }} content={<AmountTooltip />} shared={false} />
      </HorizontalBarChartPercentageCard>
      <HorizontalBarChartPercentageCard title="CO2 emissions per Asset type" data={fundEnvironmentalData?.CO2T?.co2PerAssetType} dataKey="percentage" yAxisLabel="label" categoryName="Emissions" cardType="medium-chart-card-size card-height-3">
        <Tooltip wrapperStyle={{ outline: 'none' }} content={<AmountTooltip />} shared={false} />
      </HorizontalBarChartPercentageCard>
      {/* <HorizontalBarChartPercentageCard title="CO2 emissions per Investment type" data={fundEnvironmentalData?.CO2T?.co2PerInvestmentType} dataKey="percentage" yAxisLabel="label" categoryName="Emissions" cardType="medium-chart-card-size card-height-3">
        <Tooltip wrapperStyle={{ outline: 'none' }} content={<AmountTooltip />} shared={false} />
      </HorizontalBarChartPercentageCard> */}
      <HeaderTitle text="Optional metrics">
        <div className="metrics-selection-div">
          <NormalDropdown value={null} noOptionsMessage='No results match your searching criteria.'
            list={availableMetrics} handleChange={event => handleAddMetricToFundCharacteristicsData(event?.label, event?.value, event?.metric_id)} placeholder={< p className="placeholder color-black"><HiPlus></HiPlus> Add metric</p>}
          extraClasses={`${(fundCharacteristicsData === null || fundSelectedMetrics?.length > 9) && 'button-disabled'} navbar-dropdown-search-bar`} components={{ Option: InputOptionSingleValue, Control: NormalControlReactSelect }}
          isDisabled={fundCharacteristicsData === null || fundSelectedMetrics?.length > 9} />
        </div>
      </HeaderTitle>
      {Object.keys(fundSelectedMetrics)?.length > 0 && fundSelectedMetrics ?
        <div className="metrics-board-analysis-main-div">
          {fundSelectedMetrics?.map((entry) => (
            <MetricsBoardAnalysisCard key={generateUniqueKey()} name={entry.metric} metric_id={entry.metric_id} data={fundEnvironmentalData[entry.metric_id]} handleRemoveMetricToFundCharacteristicsData={handleRemoveMetricToFundCharacteristicsData} />
          ))}
        </div>
        :
        <CardProperties className="CardProperties big-big-chart-card-size">
          <p className="no-selected-metrics font-style-montserrat-family-regular small-text color-white">Add a metric to analyse this fund.</p>
        </CardProperties>
      }
    </div>
  );
}

export default FundProfileEnvironmental;