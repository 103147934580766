
import React, { useState, useEffect, useContext } from "react";
import { useNavigate, NavLink } from "react-router-dom";

import { AiFillExclamationCircle, AiOutlineInfoCircle, AiOutlineSave, AiOutlineCloseCircle } from "react-icons/ai";
import { TbFileDollar, TbLayoutSidebarLeftCollapse, TbLayoutSidebarLeftExpand } from "react-icons/tb";
import { IoCheckmarkCircle, IoGitNetworkOutline, IoBuildOutline } from "react-icons/io5";
import { HiOutlineCubeTransparent } from "react-icons/hi";

import { ManageSimulationContext } from "../../../../utils/functions_variables/variables";

import "../ManageSimulation.scss"
import { BackgroundStraighAnglesFullWidthIconButton, BackgroundStraighAnglesFullWidthIconTextButton, ExpandableLeftMenuTabButton, ExpandableLeftMenuTopButton, NoBorderIconButton, NoBorderTextButton } from "../../../../assets/styles/UIKit/UIKit";
import { FlexBlockDiv } from "../../../../assets/styles/global";

export default function ManageSimulationSteps() {
  const { tab, setTab, stepOneDone, stepTwoDone, stepThreeDone } = useContext(ManageSimulationContext)
  const [menuOpen, setMenuOpen] = useState(true);

  const navigate = useNavigate();

  function handleSimulate() {

  }

  function handleSaveSimulation() {

  }

  return (
    <div className="create-simulation-menu-div">
      <ExpandableLeftMenuTopButton text="Create simulation" extraClasses="margin-bottom-1" icon={menuOpen ? <TbLayoutSidebarLeftCollapse className="icon small-text color-white" /> : <TbLayoutSidebarLeftExpand className="margin-center icon small-text color-white" />} open={menuOpen} clickAction={() => setMenuOpen(!menuOpen)} />

      <ExpandableLeftMenuTabButton text="Type" extraClasses={tab === 1 ? "active" : "inactive"} statusIcon={stepOneDone ? <IoCheckmarkCircle className="status-icon color-green small-text"></IoCheckmarkCircle> : <AiFillExclamationCircle className="status-icon color-orange small-text"></AiFillExclamationCircle>} icon={<IoGitNetworkOutline className="icon small-text color-white" />} open={menuOpen} clickAction={() => setTab(1)} />
      <ExpandableLeftMenuTabButton text="Assets" extraClasses={tab === 2 ? "active" : "inactive"} statusIcon={stepTwoDone ? <IoCheckmarkCircle className="status-icon color-green small-text"></IoCheckmarkCircle> : <AiFillExclamationCircle className="status-icon color-orange small-text"></AiFillExclamationCircle>} icon={<TbFileDollar className="icon small-text color-white" />} open={menuOpen} clickAction={() => setTab(2)} />
      <ExpandableLeftMenuTabButton text="Scenarios" extraClasses={tab === 3 ? "active" : "inactive"} statusIcon={<IoCheckmarkCircle className="status-icon color-transparent small-text" />} icon={<IoBuildOutline className="icon small-text color-white" />} open={menuOpen} clickAction={() => setTab(3)} />
      <ExpandableLeftMenuTabButton text="Details" extraClasses={tab === 4 ? "active" : "inactive"} statusIcon={<IoCheckmarkCircle className="status-icon color-transparent small-text" />} icon={<AiOutlineInfoCircle className="icon small-text color-white" />} open={menuOpen} clickAction={() => setTab(4)} />
      {menuOpen ?
        <BackgroundStraighAnglesFullWidthIconTextButton extraClasses={`margin-top-2`} text="Simulate" icon={<HiOutlineCubeTransparent className="icon medium-text color-white" />} clickAction={handleSimulate} />
        :
        <BackgroundStraighAnglesFullWidthIconButton extraClasses={`margin-top-2`} icon={<HiOutlineCubeTransparent className="medium-text" />} clickAction={handleSimulate} />
      }
      <FlexBlockDiv className="margin-top-1 margin-left-1 margin-right-1">
        <NavLink className="cancel-link nav-link-comp" to="/simulator">
          {menuOpen ?
            <NoBorderTextButton text="Cancel" />
            :
            <NoBorderIconButton icon={<AiOutlineCloseCircle className="icon small-text color-white" />} />
          }
        </NavLink>
        {menuOpen ?
          <NoBorderTextButton clickAction={handleSaveSimulation} text="Save" />
          :
          <NoBorderIconButton clickAction={handleSaveSimulation} icon={<AiOutlineSave className="icon small-text color-white" />} />
        }
      </FlexBlockDiv>
    </div>
  );
}
