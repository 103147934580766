import React, { useState, useContext } from "react";

import "../ManageSimulation.scss"

import HeaderTitle from "../../../HeaderTitle/HeaderTitle";

const SimulationScenarios = () => {

  return (
    <>
      <HeaderTitle text="Scenarios" />
      
    </>
  );
}

export default SimulationScenarios;