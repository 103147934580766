import React, { useState, useContext } from "react";

import "./OverviewTopBar.scss"
import { AiOutlineDownload } from "react-icons/ai";
import { OverviewContext } from "../../utils/functions_variables/variables";
import ThemeContext, { ThemeVariables } from "../../assets/styles/darkLightMode/theme-context";
import { BorderIconTextButton, CardProperties } from "../../assets/styles/UIKit/UIKit";

const OverviewTopBar = () => {
    const { theme } = useContext(ThemeContext);
    const { tab, setTab, metrics, availableMetrics, setAvailableMetrics, fundsList, setFundsList } = useContext(OverviewContext);

    const [isModalOpen, setisModalOpen] = useState(false);

    const selectTabNumber = (tab) => {
        setTab(tab)
    }

    return (
        <>
            <CardProperties className="CardProperties overview-top-bar-div">
                <p className="font-style-montserrat-family-semibold small-text color-white">Portfolio overview</p>
                <BorderIconTextButton text="Create report " icon={<AiOutlineDownload className="icon small-text"></AiOutlineDownload>} clickAction={() => setisModalOpen(!isModalOpen)} />
            </CardProperties>
            <div className="multi-tab-main-div">
                <ul className="form-stepper">
                    <li className={`form-stepper-${tab === 1 ? "active" : "unfinished"} form-stepper-list`} tab="1" onClick={() => selectTabNumber(1)}>
                        <a>
                            <div className="label font-style-montserrat-family-regular small-text">Characteristics</div>
                        </a>
                    </li>
                    <li className={`form-stepper-${tab === 2 ? "active" : "unfinished"} form-stepper-list`} onClick={() => selectTabNumber(2)} tab="2">
                        <a>
                            <div className="label font-style-montserrat-family-regular small-text">CO2 emissions</div>
                        </a>
                    </li>
                    <li className="form-stepper-unfinished form-stepper-list" />
                </ul>
            </div>
        </>
    );
}

export default OverviewTopBar;