import React, { useState, useContext } from "react";

import "../FundProfile.scss"

import { GeneralContext, FundProfileContext } from "../../../../../utils/functions_variables/variables";
import { convertToInternationalCurrencySystem } from "../../../../../utils/Maths/MathsFunctions";

import HeaderTitle from "../../../../HeaderTitle/HeaderTitle";
import DoubleYAxisBarChartCard from "../../../../DataVisualization/DoubleYAxisBarChartCard/DoubleYAxisBarChartCard";
import ChoroplethMapWorldCard from "../../../../DataVisualization/ChoroplethMapWorldCard/ChoroplethMapWorldCard";
import OneOrTwoValueCard from "../../../../Cards/OneOrTwoValueCard/OneOrTwoValueCard";

const FundProfileCharacteristics = () => {
  const { fundCharacteristicsData } = useContext(FundProfileContext)

  function get_number_columns() {
    let row_items_number_class = 0
    if (fundCharacteristicsData?.number_assets_history) {
      row_items_number_class += 1;
    }
    if (fundCharacteristicsData?.weight_percentage) {
      row_items_number_class += 1;
    }
    if (fundCharacteristicsData?.nominal_amount_history) {
      row_items_number_class += 1;
    }
    switch (row_items_number_class) {
      case 1:
        return "one"
      case 2:
        return "two"
      case 3:
        return "three"
      default:
        return "three"
    }
  }

  return (
    <div className="fund-profile-content">
      <HeaderTitle text="Statistics"></HeaderTitle>
      {fundCharacteristicsData?.number_assets_history && <OneOrTwoValueCard text="Number of assets" data={fundCharacteristicsData?.number_assets_history} type="twoValuesWithLabel" cardType={`${get_number_columns()}-items small-small-chart-card-size card-height-1`} />}
      {fundCharacteristicsData?.weight_percentage && <OneOrTwoValueCard text="Weight % in portfolio" data={fundCharacteristicsData?.weight_percentage} type="percentage" cardType={`${get_number_columns()}-items small-small-chart-card-size card-height-1`} />}
      {fundCharacteristicsData?.nominal_amount_history && <OneOrTwoValueCard text="Nominal amount" data={fundCharacteristicsData?.nominal_amount_history && convertToInternationalCurrencySystem(fundCharacteristicsData?.nominal_amount_history, 3)} type="currency" cardType={`${get_number_columns()}-items small-small-chart-card-size card-height-1`} />}
      <DoubleYAxisBarChartCard title="Asset type" yKey1="count" yKey2="value" data={fundCharacteristicsData?.asset_type_list} cardType="medium-chart-card-size card-height-2 card-height-3"></DoubleYAxisBarChartCard>
      {/* <DoubleYAxisBarChartCard title="Investment type" yKey1="count" yKey2="value" data={fundCharacteristicsData?.investment_type_list} cardType="medium-chart-card-size card-height-2 card-height-3"></DoubleYAxisBarChartCard> */}
      <DoubleYAxisBarChartCard title="Industries" yKey1="count" yKey2="value" data={fundCharacteristicsData?.assets_industry_list} cardType="medium-chart-card-size card-height-2 card-height-3"></DoubleYAxisBarChartCard>
      <ChoroplethMapWorldCard title="Number of assets per Country" cardType="medium-chart-card-size card-height-2 card-height-3" data={fundCharacteristicsData?.countries_count_list}></ChoroplethMapWorldCard>
      <ChoroplethMapWorldCard title="Total amount per Country" cardType="medium-chart-card-size card-height-2 card-height-3" data={fundCharacteristicsData?.countries_amount_list}></ChoroplethMapWorldCard>
    </div>
  );
}

export default FundProfileCharacteristics;