import React, { useState, useEffect, useContext } from "react";
import { Auth } from "aws-amplify";

import { GeneralContext } from "../../utils/functions_variables/variables";
import MainLoader from "../../components/MainLoader/MainLoader";
import { FlexBlockDiv } from "../../assets/styles/global";
import { CardProperties, BorderTextFullWidthButton, NormalInput } from "../../assets/styles/UIKit/UIKit";

const ChangeProfileDetails = () => {
    const { setWarningMessage, userName, setUserName, userEmail, setUserEmail } = useContext(GeneralContext)
    const [newUserName, setNewUserName] = useState(userName)
    const [isLoading, setIsLoading] = useState(false)
    const [buttonEnabled, setButtonEnabled] = useState(false)

    useEffect(() => {
        function checkName() {
            setNewUserName(newUserName)
            if (newUserName !== userName) {
                setButtonEnabled(true)
            } else {
                setButtonEnabled(false)
            }
        }
        checkName()
    }, [newUserName]);

    const handleNewName = async () => {
        if (buttonEnabled) {
            setIsLoading(true)
            await Auth?.currentAuthenticatedUser().then(async user => {
                const { attributes } = user;
                const updatedAttributes = {
                    ...attributes,
                    name: newUserName
                };
                await Auth?.updateUserAttributes(user, updatedAttributes).then(data => {
                    setUserName(newUserName)
                    setIsLoading(false)
                    setWarningMessage(["success", "Account name changed successfully."])
                }).catch(error => {
                    setIsLoading(false)
                    setWarningMessage(["error", error.message])
                })
            }).catch(error => {
                setIsLoading(false)
                setWarningMessage(["error", error.message])
            })
        }
    };

    if (isLoading) {
        return <MainLoader></MainLoader>;
    }

    return (
        <CardProperties className="CardProperties configurations-card profile-card">
            <FlexBlockDiv>
                <p className="font-style-montserrat-family-semibold small-text color-white">Profile</p>
            </FlexBlockDiv>
            <div className="profile-card-inside-div authentication-wrapper">
                <NormalInput extraClasses="margin-bottom-1" placeholder="Name" text={newUserName} setText={setNewUserName} autoFocus={true} required={true} type="text" handleKeyDown={handleNewName} />
                <NormalInput extraClasses="input-disabled " text={userEmail} type="text" disabled={true} />
            </div>
            <BorderTextFullWidthButton text="Save changes" extraClasses={buttonEnabled ? "button-hover" : "button-disabled"} clickAction={handleNewName} type="submit" disabled={!buttonEnabled} />
        </CardProperties>
    );
}

export default ChangeProfileDetails;