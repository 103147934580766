import React, { useState, useEffect, useContext } from "react";
import Select from 'react-select';

import { convertToCurrency, convertToBigNumbers } from "../../../utils/Maths/MathsFunctions";
import ThemeContext, { ThemeVariables } from "../../../assets/styles/darkLightMode/theme-context";
import { ManageFundContext } from "../../../utils/functions_variables/variables";
import { checkIfAbleToSave } from "../../../utils/functions_variables/functions";

import { BiTrash } from "react-icons/bi";

import { InputOptionSingleValue } from "../../../assets/styles/global";
import "./ManageFund.scss"
import asset_type from "./asset_type_list.json"
import { NoBorderIconButton, NormalControlReactSelect, NormalDropdown, NormalInput } from "../../../assets/styles/UIKit/UIKit";

const options_currency = [
    { value: 'EUR', label: 'Euro (EUR)' },
    { value: 'USD', label: 'Dollar (USD)' },
    { value: 'GBP', label: 'Pound (GBP)' },
    { value: 'JPY', label: 'Japanese yen (JPY)' },
    { value: 'AUD', label: 'Australian dollar (AUD)' },
    { value: 'CAD', label: 'Canadian dollar (CAD)' },
    { value: 'CHF', label: 'Swiss franc (CHF)' },
    { value: 'CNH', label: 'Chinese renminbi (CNH)' },
    { value: 'HKD', label: 'Hong Kong dollar (HKD)' },
    { value: 'NZD', label: 'New Zealand dollar (NZD)', }
];

const CreatedAssetCard = ({ keySecondary, text, type, currency, amount, share, setButtonEnable }) => {
    const { theme } = useContext(ThemeContext);
    const { purpose, tab, setTab, allStepsDone, setAllStepsDone, fundsNames, setFundsNames, fundID, setFundID,
        fundName, setFundName, stepOneDone, setStepOneDone, regulations, setRegulations, stepTwoDone, setStepTwoDone, assetsList,
        setAssetsList, fundAssets, setFundAssets, dates, setDates, selectedDate, setSelectedDate, createdAssets, setCreatedAssets,
        stepThreeDone, setStepThreeDone } = useContext(ManageFundContext)

    const [unitType, setUnitType] = useState(type !== "" ? asset_type?.find((item) => item?.value === type) : null)
    const [unitCurrency, setUnitCurrency] = useState(currency !== "" ? options_currency?.find((item) => item?.value === currency) : null)
    const [unitAmount, setUnitAmount] = useState(convertToCurrency(Number(amount)))
    const [unitShare, setUnitShare] = useState(convertToBigNumbers(share))
    const [currencyFilled, setCurrencyFilled] = useState(currency !== "" ? true : false)
    const [amountFilled, setAmountFilled] = useState(amount !== "" && amount !== "0.00" && amount !== 0 ? true : false)
    const [sharesFilled, setShareFilled] = useState(share !== "" ? true : false)
    const [typeFilled, setTypeFilled] = useState(type !== "" ? true : false)

    useEffect(() => {
        if (unitShare !== "" && unitShare !== "0" && unitShare !== 0) {
            setShareFilled(true)
        } else {
            setShareFilled(false)
        }
    }, [unitShare]);

    useEffect(() => {
        if (unitAmount !== "" && unitAmount !== "0.00" && unitAmount !== 0) {
            setAmountFilled(true)
        } else {
            setAmountFilled(false)
        }
    }, [unitAmount]);

    const handleRemove = () => {
        let newCreatedAssets = { ...createdAssets }
        newCreatedAssets[selectedDate] = createdAssets[selectedDate]?.filter(element => element?.keySecondary !== keySecondary)
        if (newCreatedAssets[selectedDate]?.length === 0) {
            delete newCreatedAssets[selectedDate];
        }
        setCreatedAssets(newCreatedAssets)
        setButtonEnable(checkIfAbleToSave(fundAssets, newCreatedAssets))
    }

    function handleUpdateCurrenciesCreatedAssets(value) {
        const foundIndex = createdAssets[selectedDate]?.findIndex(item => item?.keySecondary === keySecondary)
        const newCreatedAssets = { ...createdAssets }
        newCreatedAssets[selectedDate][foundIndex].currency = value
        setCreatedAssets(newCreatedAssets)
        setButtonEnable(checkIfAbleToSave(fundAssets, newCreatedAssets))
        if (!checkIfAbleToSave(fundAssets, newCreatedAssets)) { setStepThreeDone(false) }
    }

    function handleUpdateAmountsCreatedAssets(value) {
        const foundIndex = createdAssets[selectedDate]?.findIndex(item => item?.keySecondary === keySecondary)
        const newCreatedAssets = { ...createdAssets }
        newCreatedAssets[selectedDate][foundIndex].amount = value
        setCreatedAssets(newCreatedAssets)
        setButtonEnable(checkIfAbleToSave(fundAssets, newCreatedAssets))
        if (!checkIfAbleToSave(fundAssets, newCreatedAssets)) { setStepThreeDone(false) }
    }

    function handleUpdateShareCreatedAssets(value) {
        const foundIndex = createdAssets[selectedDate]?.findIndex(item => item?.keySecondary === keySecondary)
        const newCreatedAssets = { ...createdAssets }
        newCreatedAssets[selectedDate][foundIndex].share = value
        setCreatedAssets(newCreatedAssets)
        setButtonEnable(checkIfAbleToSave(fundAssets, newCreatedAssets))
        if (!checkIfAbleToSave(fundAssets, newCreatedAssets)) { setStepThreeDone(false) }
    }

    function handleAssetType(selectedOption) {
        setUnitType(selectedOption)
        setTypeFilled(true)
        const foundIndex = createdAssets[selectedDate]?.findIndex(item => item?.keySecondary === keySecondary)
        const newCreatedAssets = { ...createdAssets }
        newCreatedAssets[selectedDate][foundIndex].type = selectedOption?.value
        setCreatedAssets(newCreatedAssets)
        setButtonEnable(checkIfAbleToSave(fundAssets, newCreatedAssets))
        if (!checkIfAbleToSave(fundAssets, newCreatedAssets)) { setStepThreeDone(false) }
    }

    function handleFocusAmount() {
        if (unitAmount === "") {
            setUnitAmount("")
        } else {
            setUnitAmount(Number(parseFloat(unitAmount.replace(/\s/g, ''))))
        }
    }

    function handleAssetCurrency(selectedOption) {
        setUnitCurrency(selectedOption)
        setCurrencyFilled(true)
        handleUpdateCurrenciesCreatedAssets(selectedOption?.value)
    }

    function handleAssetAmount(e) {
        const regex = /^\d{0,15}(\.\d{0,2})?$/;
        const newValue = e;
        if (newValue !== "" && regex.test(newValue)) {
            setUnitAmount(newValue)
        } else if (newValue === "" && regex.test(newValue)) {
            setUnitAmount("")
        }
    }

    function handleFocusShare() {
        if (unitShare === "") {
            setUnitShare("")
        } else {
            setUnitShare(Number(parseFloat(unitShare.replace(/\s/g, ''))))
        }
    }

    function handleAssetShare(e) {
        const regex = /^[0-9]*$/;
        const newValue = e;
        if (regex.test(newValue) && newValue !== "") {
            setUnitShare(newValue)
        } else if (regex.test(newValue) && newValue === "") {
            setUnitShare("")
        }
    }

    function handleInputAmountBlur() {
        if (unitAmount === "") {
            setUnitAmount("");
            setUnitCurrency(null)
            setCurrencyFilled(false)
            handleUpdateCurrenciesCreatedAssets("")
            handleUpdateAmountsCreatedAssets("")
        } else {
            setUnitAmount(convertToCurrency(Number(parseFloat(unitAmount))));
            handleUpdateAmountsCreatedAssets(Number(parseFloat(unitAmount)))
        }
    }

    function handleInputShareBlur() {
        if (unitShare === "") {
            setUnitShare("");
            handleUpdateShareCreatedAssets("")
        } else {
            setUnitShare(convertToBigNumbers(Number(parseFloat(unitShare))));
            handleUpdateShareCreatedAssets(convertToBigNumbers(Number(parseFloat(unitShare))))
        }
    }

    return (
        <div className="created-asset-card font-style-montserrat-family-semibold extra-small-text color-white">
            <div className="label-div">
                <p className="fund-name">{text}</p>
                <p className="label-new font-style-montserrat-family-semibold extra-small-text color-green">CREATED</p>
            </div>
            <NormalDropdown value={unitType} noOptionsMessage='No results match your searching criteria.' borderStatus={`${typeFilled ? "var(--main-green)" : "red"}`}
                list={asset_type} handleChange={handleAssetType} components={{ Option: InputOptionSingleValue, Control: NormalControlReactSelect }} />
            <NormalInput onFocus={handleFocusShare} onBlur={handleInputShareBlur} extraClasses={`${sharesFilled ? "outline-green" : "outline-red"}`} placeholder="0" text={unitShare} setText={handleAssetShare} type="text" required={true} />
            <NormalInput onFocus={handleFocusAmount} onBlur={handleInputAmountBlur} placeholder="0.00" text={unitAmount} setText={handleAssetAmount} type="text" />
            <NormalDropdown value={unitCurrency} noOptionsMessage='No results match your searching criteria.' borderStatus={`${amountFilled && (currencyFilled ? "var(--main-green)" : "red")}`}
                list={options_currency} handleChange={handleAssetCurrency} components={{ Option: InputOptionSingleValue, Control: NormalControlReactSelect }} />
            <NoBorderIconButton clickAction={handleRemove} icon={<BiTrash className="icon-red small-text color-white" />} />
        </div>
    );
}

export default CreatedAssetCard;