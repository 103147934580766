import React, { useState, useContext } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Tooltip } from 'recharts';

import ThemeContext, { ThemeVariables } from "../../../assets/styles/darkLightMode/theme-context";
import { ValueTooltip, TickFormatterYAxisBigText, TickFormatterAxisBigNumber } from "../Tooltips";
import { AiOutlineExpand } from "react-icons/ai";

import ModalWindow from "../../ModalWindow/ModalWindow";
import { FlexBlockDiv } from "../../../assets/styles/global";
import { CardProperties, NoBorderIconButton } from "../../../assets/styles/UIKit/UIKit";

const RankingDotsTwoCategoriesChartCard = (props) => {
    const { title, data, cardType, modal } = props;
    const { theme } = useContext(ThemeContext);
    const [isModalOpen, setisModalOpen] = useState(false);

    return (
        <>
            {data?.length > 0 &&
                <>
                    <CardProperties className={`${!modal && 'CardProperties'} ${cardType}`}>
                        {!modal &&
                            <FlexBlockDiv>
                                <p className="font-style-montserrat-family-regular extra-small-text color-white">{title}</p>
                                <NoBorderIconButton clickAction={() => setisModalOpen(!isModalOpen)} icon={<AiOutlineExpand className="icon small-text color-lightgray" />} />
                            </FlexBlockDiv>}
                        <ResponsiveContainer width="100%" className="font-style-montserrat-family-regular extra-extra-small-text color-white">
                            <LineChart layout="vertical" data={data} margin={{ top: 20, right: 30, bottom: 15 }}>
                                <CartesianGrid style={{ stroke: `${ThemeVariables[theme]?.lines}` }} />
                                <XAxis dy={5} dx={-5} label={{ value: "Emissions intensity", position: "bottom", style: { textAnchor: 'middle' }, fill: `${ThemeVariables[theme]?.extreme_color}` }} type="number" interval={0} tickFormatter={TickFormatterAxisBigNumber} tickCount={6} tick={{ fill: `${ThemeVariables[theme]?.extreme_color}` }} tickLine={false} />
                                <YAxis dx={0} dataKey="label" type="category" width={115} tickFormatter={TickFormatterYAxisBigText} tick={{ fill: `${ThemeVariables[theme]?.extreme_color}` }} tickLine={false} />
                                <Tooltip wrapperStyle={{ outline: 'none' }} content={<ValueTooltip />} shared={false} />
                                <Line animationDuration={250} dataKey="best" name="ESG score" type="best" stroke="none" dot={{ r: 5, fill: 'var(--main-green)' }} activeDot={{ r: 6 }} />
                                <Line animationDuration={500} dataKey="worst" name="ESG score" type="worst" stroke="none" dot={{ r: 5, fill: 'var(--main-red)' }} activeDot={{ r: 6 }} />
                            </LineChart>
                        </ResponsiveContainer>
                    </CardProperties>
                    <ModalWindow title={title} sizeClass="charts-visualization" open={isModalOpen} setOpen={setisModalOpen}>
                        <RankingDotsTwoCategoriesChartCard title={title} data={data} cardType="modal-card" modal={true}></RankingDotsTwoCategoriesChartCard>
                    </ModalWindow>
                </>
            }
        </>
    );
}

export default RankingDotsTwoCategoriesChartCard;