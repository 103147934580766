import React, { useContext } from "react";

import "../OverviewFundContent.scss"

import { OverviewContext } from "../../../utils/functions_variables/variables";
import { convertToInternationalCurrencySystem } from "../../../utils/Maths/MathsFunctions";

import HeaderTitle from "../../HeaderTitle/HeaderTitle";
import DoubleYAxisBarChartCard from "../../DataVisualization/DoubleYAxisBarChartCard/DoubleYAxisBarChartCard";
import ChoroplethMapWorldCard from "../../DataVisualization/ChoroplethMapWorldCard/ChoroplethMapWorldCard";
import OneOrTwoValueCard from "../../Cards/OneOrTwoValueCard/OneOrTwoValueCard";

const OverviewCharacteristics = () => {
  const { overviewCharacteristicsData } = useContext(OverviewContext)

  function get_number_columns() {
    let row_items_number_class = 0
    if (overviewCharacteristicsData?.number_funds) {
      row_items_number_class += 1;
    }
    if (overviewCharacteristicsData?.portfolio_amount) {
      row_items_number_class += 1;
    }
    switch (row_items_number_class) {
      case 1:
        return "one"
      case 2:
        return "two"
      default:
        return "two"
    }
  }

  return (
    <div className="overview-content">
      <HeaderTitle text="Statistics"></HeaderTitle>
      {overviewCharacteristicsData?.number_funds && <OneOrTwoValueCard text="Number of funds" data={overviewCharacteristicsData?.number_funds} type="oneValue" cardType={`${get_number_columns()}-items small-small-chart-card-size card-height-1`} />}
      {overviewCharacteristicsData?.portfolio_amount && <OneOrTwoValueCard text="Portfolio amount" data={overviewCharacteristicsData?.portfolio_amount && convertToInternationalCurrencySystem(overviewCharacteristicsData?.portfolio_amount, 3)} type="currency" cardType={`${get_number_columns()}-items small-small-chart-card-size card-height-1`} />}
      <DoubleYAxisBarChartCard title="Industries" yKey1="count" yKey2="value" data={overviewCharacteristicsData?.assets_industry_list} cardType="medium-chart-card-size card-height-2 card-height-3"></DoubleYAxisBarChartCard>
      <DoubleYAxisBarChartCard title="Asset type" yKey1="count" yKey2="value" data={overviewCharacteristicsData?.asset_type_list} cardType="medium-chart-card-size card-height-2 card-height-3"></DoubleYAxisBarChartCard>
      <ChoroplethMapWorldCard title="Number of assets per Country" cardType="medium-chart-card-size card-height-2 card-height-3" data={overviewCharacteristicsData?.countries_count_list}></ChoroplethMapWorldCard>
      <ChoroplethMapWorldCard title="Total amount per Country" cardType="medium-chart-card-size card-height-2 card-height-3" data={overviewCharacteristicsData?.countries_amount_list}></ChoroplethMapWorldCard>
    </div>
  );
}

export default OverviewCharacteristics;