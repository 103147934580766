import React, { useState, useContext } from "react";
import { LineChart, Line, XAxis, YAxis, Rectangle, CartesianGrid, Tooltip, BarChart, Bar, ResponsiveContainer, Label } from 'recharts';

import "./LineChartGraph.scss"

import ThemeContext, { ThemeVariables } from "../../../assets/styles/darkLightMode/theme-context";

import { ValueTooltip, TickFormatterAxisBigNumber } from "../Tooltips";

const LineChartGraph = ({ title, cardType, data, }) => {
    const { theme } = useContext(ThemeContext);

    return (
        <>
            {data?.length > 0 &&
                <div className={`line-chart-div ${cardType}`}>
                    <p className="font-style-montserrat-family-semibold extra-small-text color-white">{title}</p>
                    <ResponsiveContainer width="100%" height="100%" className={`font-style-montserrat-family-regular extra-extra-small-text color-white`}>
                        <LineChart data={data} margin={{ top: 15, right: 7, left: 25 }}>
                            <CartesianGrid style={{ stroke: `${ThemeVariables[theme]?.lines}` }} vertical={false} />
                            <XAxis dataKey="year" dx={-2} tick={{ width: 130, fill: `${ThemeVariables[theme]?.extreme_color}` }} tickLine={false} />
                            <YAxis tickFormatter={TickFormatterAxisBigNumber} tick={{ fill: `${ThemeVariables[theme]?.extreme_color}` }} tickLine={false} />
                            <Line strokeWidth={2} type="monotone" dataKey="value" stroke="var(--main-blue)" activeDot={{ r: 6 }} dot={{ stroke: `${ThemeVariables[theme]?.extreme_color}`, strokeWidth: 0, r: 4, strokeDasharray: '' }} />
                            <Tooltip wrapperStyle={{ outline: 'none' }} content={<ValueTooltip />} shared={false} />
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            }
        </>
    );
}

export default LineChartGraph;