import React from "react";

import "./MainTitleTransparentCard.scss"

import { CardProperties } from "../../../assets/styles/UIKit/UIKit";

const MainTitleTransparentCard = (props) => {
    const { text } = props;

    return (
        <CardProperties className="background-transparent CardProperties main-title-card-transparent small-text font-style-montserrat-family-semibold color-white">
            {text}
        </CardProperties>
    );
}

export default MainTitleTransparentCard;