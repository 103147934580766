import { dictToURL } from './helpFunctions';
import portfolio_funds_endpoints from "../endpoints/portfolio_funds_endpoints.json"
import asset_endpoints from "../endpoints/asset_endpoints.json"
import funds_endpoints from "../endpoints/portfolio_funds_endpoints.json"
import overview_endpoints from "../endpoints/overview_funds_endpoints.json"
import regulations_endpoints from "../endpoints/regulations_endpoints.json"

// ASSET
export async function getAssets(databaseAddress) {
  const query_params = { clientName: databaseAddress };
  const url = portfolio_funds_endpoints[process.env.REACT_APP_ENVIRONMENT]?.list_assets + "?" + dictToURL(query_params)

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "X-Api-Key": process.env.REACT_APP_API_KEY,
      'Accept': 'application/json',
    }
  })

  if (response?.ok) {
    return response.json()
  } else {
    throw new Error("An error occurred when trying to retrieve the list of assets. Please try again later or, if the problem persists, contact our support team.");
  }
}

export async function getAsset(databaseAddress, id) {
  const query_params = { clientName: databaseAddress, assetId: id };
  const url = asset_endpoints[process.env.REACT_APP_ENVIRONMENT]?.asset_analytics + "?" + dictToURL(query_params)

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "X-Api-Key": process.env.REACT_APP_API_KEY,
      'Accept': 'application/json',
    }
  })

  if (response?.ok) {
    return response.json()
  } else {
    throw new Error("An error occurred when trying to retrieve the details of this asset. Please try again later or, if the problem persists, contact our support team.");
  }
}

export async function getAssetEnvironmental(databaseAddress, id) {
  const query_params = { clientName: databaseAddress, assetId: id };
  const url = asset_endpoints[process.env.REACT_APP_ENVIRONMENT]?.asset_environmental + "?" + dictToURL(query_params)

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "X-Api-Key": process.env.REACT_APP_API_KEY,
      'Accept': 'application/json',
    }
  })

  if (response?.ok) {
    return response.json()
  } else {
    throw new Error("An error occurred when trying to retrieve the environmental data for this asset. Please try again later or, if the problem persists, contact our support team.");
  }
}

// FUND
export async function getFunds(databaseAddress) {
  const query_params = { clientName: databaseAddress };
  const url = portfolio_funds_endpoints[process.env.REACT_APP_ENVIRONMENT]?.list_funds + "?" + dictToURL(query_params)

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "X-Api-Key": process.env.REACT_APP_API_KEY,
      'Accept': 'application/json',
    }
  })

  if (response?.ok) {
    return response.json()
  } else {
    throw new Error("An error occurred when trying to retrieve the list of funds. Please try again later or, if the problem persists, contact our support team.");
  }
}

export async function getFund(databaseAddress, fundName) {
  const query_params = { clientName: databaseAddress, fundName: fundName };
  const url = funds_endpoints[process.env.REACT_APP_ENVIRONMENT]?.fund_details + "?" + dictToURL(query_params)

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "X-Api-Key": process.env.REACT_APP_API_KEY,
      'Accept': 'application/json',
    }
  })

  if (response?.ok) {
    return response.json()
  } else {
    throw new Error("An error occurred when trying to retrieve the details of this fund. Please try again later or, if the problem persists, contact our support team.");
  }
}

export async function getFundCharacteristics(databaseAddress, fundName) {
  const query_params = { clientName: databaseAddress, fundName: fundName };
  const url = funds_endpoints[process.env.REACT_APP_ENVIRONMENT]?.fund_characteristics + "?" + dictToURL(query_params)

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "X-Api-Key": process.env.REACT_APP_API_KEY,
      'Accept': 'application/json',
    }
  })

  if (response?.ok) {
    return response.json()
  } else {
    throw new Error("An error occurred when trying to retrieve the characteristics for this fund. Please try again later or, if the problem persists, contact our support team.");
  }
}

export async function getFundEnvironmental(databaseAddress, fundName) {
  const query_params = { clientName: databaseAddress, fundName: fundName };
  const url = funds_endpoints[process.env.REACT_APP_ENVIRONMENT]?.fund_environmental + "?" + dictToURL(query_params)

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "X-Api-Key": process.env.REACT_APP_API_KEY,
      'Accept': 'application/json',
    }
  })

  if (response?.ok) {
    return response.json()
  } else {
    throw new Error("An error occurred when trying to retrieve the environmental data for this fund. Please try again later or, if the problem persists, contact our support team.");
  }
}

// OVERVIEW
export async function getOverviewCharacteristics(databaseAddress) {
  const query_params = { clientName: databaseAddress };
  const url = overview_endpoints[process.env.REACT_APP_ENVIRONMENT]?.overview_characteristics + "?" + dictToURL(query_params)

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "X-Api-Key": process.env.REACT_APP_API_KEY,
      'Accept': 'application/json',
    }
  })

  if (response?.ok) {
    return response.json()
  } else {
    if (response?.status === 404) {
      throw new Error('Failed to retrieve overview data. There are no funds created.');
    } else {
      throw new Error("Failed to retrieve overview characteristics data. Please try again later or, if the problem persists, contact our support team.");
    }
  }
}

export async function getOverviewEnvironmental(databaseAddress) {
  const query_params = { clientName: databaseAddress };
  const url = overview_endpoints[process.env.REACT_APP_ENVIRONMENT]?.overview_environmental + "?" + dictToURL(query_params)

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "X-Api-Key": process.env.REACT_APP_API_KEY,
      'Accept': 'application/json',
    }
  })

  if (response?.ok) {
    return response.json()
  } else {
    throw new Error("Failed to retrieve overview environmental data. Please try again later or, if the problem persists, contact our support team.");
  }
}

// FUND REPORT DOWNLOAD
export async function getFundReportDownload(databaseAddress, fundName, metrics) {
  const query_params = { clientName: databaseAddress, fundName: fundName, metrics: [...metrics.keys()].join("---") };
  const url = overview_endpoints[process.env.REACT_APP_ENVIRONMENT]?.fund_report_download + "?" + dictToURL(query_params)

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "X-Api-Key": process.env.REACT_APP_API_KEY,
      'Accept': 'application/json',
    }
  })

  if (response?.ok) {
    return response.json()
  } else {
    throw new Error("An error occurred when trying to download the fund report. Please try again later or, if the problem persists, contact our support team.");
  }
}

// EU TAXONOMY
export async function getEUTaxonomyAlignment() {
  const url = regulations_endpoints[process.env.REACT_APP_ENVIRONMENT]?.eu_taxonomy?.alignment

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "X-Api-Key": process.env.REACT_APP_API_KEY,
      'Accept': 'application/json',
    }
  })

  if (response?.ok) {
    return response.json()
  } else {
    throw new Error("An error occurred when trying to retrieve eu taxonomy alignment data. Please try again later or, if the problem persists, contact our support team.");
  }
} 

export async function getEUTaxonomyEligibility() {
  const url = regulations_endpoints[process.env.REACT_APP_ENVIRONMENT]?.eu_taxonomy?.eligibility

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "X-Api-Key": process.env.REACT_APP_API_KEY,
      'Accept': 'application/json',
    }
  })

  if (response?.ok) {
    return response.json()
  } else {
    throw new Error("An error occurred when trying to retrieve eu taxonomy eligibility data. Please try again later or, if the problem persists, contact our support team.");
  }
} 

export async function getEUTaxonomyFinancial() {
  const url = regulations_endpoints[process.env.REACT_APP_ENVIRONMENT]?.eu_taxonomy?.financial

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "X-Api-Key": process.env.REACT_APP_API_KEY,
      'Accept': 'application/json',
    }
  })

  if (response?.ok) {
    return response.json()
  } else {
    throw new Error("An error occurred when trying to retrieve eu taxonomy financial data. Please try again later or, if the problem persists, contact our support team.");
  }
} 

export async function getEUTaxonomyNonFinancial() {
  const url = regulations_endpoints[process.env.REACT_APP_ENVIRONMENT]?.eu_taxonomy?.nonFinancial

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "X-Api-Key": process.env.REACT_APP_API_KEY,
      'Accept': 'application/json',
    }
  })

  if (response?.ok) {
    return response.json()
  } else {
    throw new Error("An error occurred when trying to retrieve eu taxonomy non financial data. Please try again later or, if the problem persists, contact our support team.");
  }
} 

export async function getEUTaxonomyInsurance() {
  const url = regulations_endpoints[process.env.REACT_APP_ENVIRONMENT]?.eu_taxonomy?.insurance

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "X-Api-Key": process.env.REACT_APP_API_KEY,
      'Accept': 'application/json',
    }
  })

  if (response?.ok) {
    return response.json()
  } else {
    throw new Error("An error occurred when trying to retrieve eu taxonomy insurance data. Please try again later or, if the problem persists, contact our support team.");
  }
} 

// PAI
export async function getPAIInputFieldsData(databaseAddress, fundName, step, step_number) {
  const query_params = { client: databaseAddress, fundName: fundName, step: step };
  const url = regulations_endpoints[process.env.REACT_APP_ENVIRONMENT]?.sfdr?.get_pai_input_fields + "?" + dictToURL(query_params)

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "X-Api-Key": process.env.REACT_APP_API_KEY,
      'Accept': 'application/json',
    }
  })

  if (response?.ok) {
    return response.json()
  } else {
    throw new Error("An error occurred when trying to retrieve the step " + step_number + " data. Please try again later or, if the problem persists, contact our support team.");
  }
} 

export async function getPAIStepTwoTableData(databaseAddress, fundName) {
  const query_params = { fundName: fundName, client: databaseAddress };
  const url = regulations_endpoints[process.env.REACT_APP_ENVIRONMENT]?.sfdr?.get_pai+ "?" + dictToURL(query_params)

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "X-Api-Key": process.env.REACT_APP_API_KEY,
      'Accept': 'application/json',
    }
  })

  if (response?.ok) {
    return response.json()
  } else {
    throw new Error("An error occurred when trying to retrive the step 2 data for the PAI report. Please try again later or, if the problem persists, contact our support team.");
  }
} 

export async function getPAIReportDownload(databaseAddress, fundName, optionalMetrics) {
  const query_params = { clientName: databaseAddress, fundName: fundName, optionalMetrics: optionalMetrics };
  const url = regulations_endpoints[process.env.REACT_APP_ENVIRONMENT]?.sfdr?.pai_report_download + "?" + dictToURL(query_params)

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "X-Api-Key": process.env.REACT_APP_API_KEY,
      'Accept': 'application/json',
    }
  })

  if (response?.ok) {
    return response.json()
  } else {
    throw new Error("An error occurred when trying to download the PAI report. Please try again later or, if the problem persists, contact our support team.");
  }
} 