import React, { useState, useContext } from "react";
import ThemeContext, { ThemeVariables } from "../../../assets/styles/darkLightMode/theme-context";

import { BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Cell, Rectangle } from 'recharts';
import { AiOutlineExpand } from "react-icons/ai";
import ModalWindow from "../../ModalWindow/ModalWindow";
import { FlexBlockDiv } from "../../../assets/styles/global";
import { CardProperties, NoBorderIconButton } from "../../../assets/styles/UIKit/UIKit";

const MultipleBarChartPercentageCard = (props) => {
    const { title, data, cardType, modal, colors } = props;
    const { theme, setTheme } = useContext(ThemeContext);

    const [isModalOpen, setisModalOpen] = useState(false);

    const ticks = [0, 0.25, 0.5, 0.75, 1];
    const labelFormatter = (x) => {
        return ((x / 1) * 100).toFixed(1).replace(/[.,]0/, "") + '%'
    };

    return (
        <>
            <CardProperties className={`${!modal && 'CardProperties'} ${cardType}`}>
                {!modal &&
                    <FlexBlockDiv>
                        <p className="font-style-montserrat-family-regular extra-small-text color-white">{title}</p>
                        <NoBorderIconButton clickAction={() => setisModalOpen(!isModalOpen)} icon={<AiOutlineExpand className="icon small-text color-lightgray" />} />
                    </FlexBlockDiv>}
                <ResponsiveContainer width="100%" height="100%" className="extra-extra-small-text font-style-montserrat-family-regular">
                    <BarChart width="100%" height="100%" data={data} margin={{ top: 20, left: 0, right: 0, bottom: 10 }}>
                        <CartesianGrid style={{ stroke: `${ThemeVariables[theme]?.lines}` }} vertical={false} />
                        <XAxis dataKey="name" tick={{ width: 170, fill: `${ThemeVariables[theme]?.extreme_color}` }} interval={0} tickLine={false} />
                        <YAxis ticks={ticks} tickCount={25} tickFormatter={(tick) => { return `${(tick * 100)}%` }} hide />
                        <Bar shape={<Rectangle radius={[3, 3, 0, 0]} />} animationDuration={800} dataKey="percentage" barSize={120} minPointSize={1} label={{ fill: `${ThemeVariables[theme]?.contrast_color}`, formatter: labelFormatter, position: "top" }}>
                            {data?.map((entry, index) => (
                                <Cell cursor="pointer" fill={colors[index]} name={data[index]?.name} key={`cell-${index}`} />
                            ))}
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            </CardProperties>
            <ModalWindow title={title} sizeClass="charts-visualization" open={isModalOpen} setOpen={setisModalOpen}>
                <MultipleBarChartPercentageCard title={title} data={data} cardType="modal-card" modal={true}></MultipleBarChartPercentageCard>
            </ModalWindow>
        </>
    );
}

export default MultipleBarChartPercentageCard;