import React, { useState, useContext } from "react";

import "./OverviewFundContent.scss"
import ThemeContext, { ThemeVariables } from "../../assets/styles/darkLightMode/theme-context";
import { OverviewContext } from "../../utils/functions_variables/variables";

import OverviewCharacteristics from "./tabs/OverviewCharacteristics";
import OverviewEnvironmental from "./tabs/OverviewEnvironmental";

const OverviewFundContent = () => {
  const { tab, overviewCharacteristicsData, fundSelectedMetrics, availableMetrics } = useContext(OverviewContext);
  const { theme } = useContext(ThemeContext);

  return (
    <>
      {tab === 1 &&
        <OverviewCharacteristics />
      }
      {tab === 2 &&
        <OverviewEnvironmental />
      }
    </>
  );
}

export default OverviewFundContent;