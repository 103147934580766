import React, { useState, useEffect } from "react";

import ChangeProfileDetails from "../../components/ChangeProfileDetails/ChangeProfileDetails";
import ChangePassword from "../../components/ChangePassword/ChangePassword";
import HeaderTitle from "../../components/HeaderTitle/HeaderTitle";

import "./Configurations.scss"

const Profile = () => {

    return (
        <div className="configurations-main-div">
            <HeaderTitle text="Profile preferences" />
            <ChangeProfileDetails />
            <ChangePassword />
        </div>
    );
}

export default Profile;