import React, { PureComponent, useContext, useState } from "react";
import { Treemap, ResponsiveContainer, Tooltip } from 'recharts';

import ThemeContext, { ThemeVariables } from "../../../assets/styles/darkLightMode/theme-context";
import { PercentageTooltip } from "../Tooltips";

import { AiOutlineExpand } from "react-icons/ai";

import ModalWindow from "../../ModalWindow/ModalWindow";
import { CardProperties, NoBorderIconButton } from "../../../assets/styles/UIKit/UIKit";
import { FlexBlockDiv } from "../../../assets/styles/global";

const TreeMapCard = (props) => {
    const { title, data, cardType, colors, modal } = props;

    const { theme, setTheme } = useContext(ThemeContext);

    const [isModalOpen, setisModalOpen] = useState(false);

    class CustomizedContent extends PureComponent {
        render() {
            const { x, y, width, height, index, colors, name, percentage } = this.props;
            const calc_y = y - 20
            return (
                <g className="treemap-rectangle">
                    <rect x={x} y={calc_y} width={width} height={height} style={{
                        fill: colors[index % colors?.length], opacity: 0.85, stroke: `${ThemeVariables[theme]?.card}`,
                        strokeWidth: 1, strokeOpacity: 1
                    }} />
                    {name !== undefined && (width / (name.length + 3)) > 8 ? (
                        <text x={x + 6} y={y + height - 26} textAnchor="start" style={{ fill: `${ThemeVariables[theme]?.card}`, fontSize: 11 }} >
                            {name} {`${(percentage * 100)?.toFixed(1).replace(/[.,]0/, "")}%`}
                        </text>
                    ) : null}
                </g>
            );
        }
    }

    return (
        <>
            <CardProperties className={`${!modal && 'CardProperties'} ${cardType}`}>
                {!modal &&
                    <FlexBlockDiv>
                        <p className="font-style-montserrat-family-regular extra-small-text color-white">{title}</p>
                        <NoBorderIconButton clickAction={() => setisModalOpen(!isModalOpen)} icon={<AiOutlineExpand className="icon small-text color-lightgray" />} />
                    </FlexBlockDiv>}
                <ResponsiveContainer width="100%" height="100%">
                    <Treemap animationDuration={800} className="extra-extra-small-text font-style-montserrat-family-regular"
                        data={data}
                        dataKey="percentage"
                        content={<CustomizedContent colors={colors} />}>
                        <Tooltip wrapperStyle={{ outline: 'none' }} content={<PercentageTooltip />} />
                    </Treemap>
                </ResponsiveContainer>
            </CardProperties>
            <ModalWindow title={title} sizeClass="charts-visualization" open={isModalOpen} setOpen={setisModalOpen}>
                <TreeMapCard title={title} data={data} cardType="modal-card" modal={true} colors={colors}></TreeMapCard>
            </ModalWindow>
        </>
    );
}

export default TreeMapCard;