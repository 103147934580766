import React, { useState, useEffect } from "react";

import translatedJson from "../../../../../../../views/Regulations/SFDR/PAIjsonTranslation.json"
import { CheckboxInput } from "../../../../../../../assets/styles/UIKit/UIKit";

const OptionalMetricsSelectionCheckboxTable = ({ data, indicator, additional, table, checkedItems, setCheckedItems }) => {
    const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
    let colName_prefix = ""
    if (additional) { colName_prefix = indicator + "_" + table + "_" + additional } else { colName_prefix = indicator + "_" + table }

    const handleRowHover = (index) => {
        setHoveredRowIndex(index);
    };

    const handleRowLeave = () => {
        setHoveredRowIndex(null);
    };

    function handleMetricSelection(id, bool_event) {
        const newCheckedItems = new Map(checkedItems);
        if (bool_event) { newCheckedItems?.set(id, bool_event); } else { newCheckedItems?.delete(id) }
        setCheckedItems(newCheckedItems)
    }

    const tbodies = Object.entries(data)?.map(([mainKey, mainValue]) => {
        const sizeMainIndicator = Object.values(mainValue)?.reduce((acc, arr) => acc + Object.keys(arr)?.length, 0);
        const fixedDataValues = Object.entries(mainValue)
        let i = 0;
        const subtopicRows = fixedDataValues?.map((indicator, index) => {
            const sizeInsideMetrics = Object.values(indicator[1])?.length;
            let m = 0;
            let group_indicator_prefix = colName_prefix + "_" + mainKey + "_" + indicator[0]
            const rows = Object.entries(indicator[1])?.map(([keyOne, valueOne]) => {
                let id = colName_prefix + "_" + mainKey + "_" + indicator[0] + "_" + keyOne
                const checkbox = (m === 0) ? <td rowSpan={sizeInsideMetrics} id={id} className={`${hoveredRowIndex === group_indicator_prefix ? 'hovered-row' : 'unhovered-row'} checkbox-td`} onMouseEnter={() => handleRowHover(group_indicator_prefix)}
                    onMouseLeave={handleRowLeave} onClick={() => handleMetricSelection(group_indicator_prefix, !checkedItems?.get(group_indicator_prefix))} >
                    <CheckboxInput value={checkedItems?.get(group_indicator_prefix)} handleChange={(event) => handleMetricSelection(group_indicator_prefix, event?.target?.checked)} />
                </td> : null
                const subtopicName = (m === 0 && i === 0) ? <td rowSpan={sizeMainIndicator}>{translatedJson[mainKey]}</td> : null
                const metricName = m === 0 ? <td className={hoveredRowIndex === group_indicator_prefix ? 'hovered-row' : 'unhovered-row'} onMouseEnter={() => handleRowHover(group_indicator_prefix)}
                    onMouseLeave={handleRowLeave} rowSpan={sizeInsideMetrics} onClick={() => handleMetricSelection(group_indicator_prefix, !checkedItems?.get(group_indicator_prefix))} >{translatedJson[indicator[0]]}</td> : null
                m = m + 1
                return (<tr key={m + i} className="font-style-montserrat-family-regular extra-extra-small-text color-white">
                    {subtopicName}
                    {checkbox}
                    {metricName}
                    <td>{translatedJson[keyOne]}</td>
                </tr>)
            })
            i = i + 1
            return rows
        })
        return <tbody key={mainKey}>
            {subtopicRows}
        </tbody>
    })
    return tbodies
}

export default OptionalMetricsSelectionCheckboxTable;