import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import validator from 'validator'
import { Auth } from "aws-amplify";
import PasswordChecklist from "react-password-checklist"

import { RxCross2 } from "react-icons/rx";
import { HiCheck } from "react-icons/hi";
import { ImArrowLeft2 } from "react-icons/im";

import "../../views/Authentication/Authentication.scss"

import { GeneralContext } from "../../utils/functions_variables/variables";
import MainLoader from "../MainLoader/MainLoader";
import { handleKeyDownEvent } from "../../utils/functions_variables/functions";
import ThemeContext from "../../assets/styles/darkLightMode/theme-context";
import { BorderTextFullWidthButton, CardProperties, NoBorderIconButton, NormalInput, PasswordInput } from "../../assets/styles/UIKit/UIKit";
import { PasswordEyeButton } from "../../assets/styles/global";

const icons = { ValidIcon: <HiCheck className="icon-check"></HiCheck>, InvalidIcon: <RxCross2 className="icon-cross"></RxCross2> }

export default function ResetPassword({ setResetPassword }) {
    const { setWarningMessage } = useContext(GeneralContext)
    const { theme } = useContext(ThemeContext)
    const lynxai_full_logo = (theme === 'light' ? require('../../assets/images/lynxai_full_logo_black_blue.png') : require('../../assets/images/lynxai_full_logo_white_blue.png'));
    const [isLoading, setIsLoading] = useState(false)
    const [passswordIsValid, setPasswordIsValid] = useState("");
    const [email, setEmail] = useState("");
    const [buttonSendCodeEnable, setButtonSendCodeEnable] = useState(false)
    const [buttonConfirmNewPasswordsEnable, setButtonConfirmNewPasswordsEnable] = useState(false)
    const [code, setCode] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [step, setStep] = useState(1);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        function checkFieldEmail() {
            if (validator.isEmail(email)) {
                setButtonSendCodeEnable(true)
            } else { setButtonSendCodeEnable(false) }
        }
        checkFieldEmail()
    }, [email]);

    useEffect(() => {
        function checkNewPasswordsCode() {
            if (code?.length === 6 && passswordIsValid) {
                setButtonConfirmNewPasswordsEnable(true)
            } else { setButtonConfirmNewPasswordsEnable(false) }
        }
        checkNewPasswordsCode()
    }, [code, passswordIsValid]);

    const handleGoBack = async (e) => {
        setResetPassword(false)
    }

    const toggleNewPasswordVisibility = (event) => {
        event.preventDefault();
        setShowNewPassword(!showNewPassword);
    };

    const toggleConfirmNewPasswordVisibility = (event) => {
        event.preventDefault();
        setShowConfirmNewPassword(!showConfirmNewPassword);
    };

    const handleSendCode = async (e) => {
        e.preventDefault();
        if (buttonSendCodeEnable) {
            setIsLoading(true)
            await Auth?.forgotPassword(email).then(data => {
                setStep(2);
                setIsLoading(false)
            }).catch(error => {
                setIsLoading(false)
                setWarningMessage(["error", error.message])
            });
        }
    };

    const handleResetPassword = async (e) => {
        e.preventDefault();
        if (buttonConfirmNewPasswordsEnable) {
            setIsLoading(true)
            await Auth?.forgotPasswordSubmit(email, code, newPassword).then((user) => {
                setIsLoading(false)
                navigate('/login', { state: { info: "Password changed successfully." } })
            }).catch(error => {
                setIsLoading(false)
                setWarningMessage(["error", error.message])
            })
        }
    };

    if (isLoading) {
        return <MainLoader></MainLoader>;
    }

    return (
        <CardProperties className="CardProperties authentication-main-div">
            <NoBorderIconButton icon={<ImArrowLeft2 className="icon medium-text color-white" />} extraClasses="back-button" clickAction={handleGoBack} />
            <img src={lynxai_full_logo} alt="img" />
            <div className="divider margin-top-3 margin-bottom-1"></div>
            {step === 1 && (
                <>
                    <h1 className="margin-top-3 margin-bottom-2 font-style-montserrat-family-semibold small-text color-lightgray">Reset password</h1>
                    <form onSubmit={handleSendCode}>
                        <NormalInput placeholder="Email" text={email} setText={setEmail} type="email" handleKeyDown={handleSendCode} autoFocus={true} required={true} />
                        <BorderTextFullWidthButton text="Reset password" clickAction={handleSendCode} extraClasses={`${buttonSendCodeEnable ? "button-hover" : "button-disabled"} margin-top-1`} type="submit" disabled={!buttonSendCodeEnable} />
                    </form>
                </>
            )}
            {step === 2 && (
                <>
                    <h1 className="margin-top-3 margin-bottom-1 font-style-montserrat-family-semibold small-text color-lightgray">Enter new password</h1>
                    <h2 className="margin-top-1 margin-bottom-1 font-style-montserrat-family-regular extra-small-text color-green">A confirmation code was sent to your email.</h2>
                    <form onSubmit={handleResetPassword}>
                        <NormalInput placeholder="Code" text={code} setText={setCode} type="number" handleKeyDown={handleResetPassword} autoFocus={true} required={true} extraClasses="margin-top-1" />
                        <div className="divider margin-top-1 margin-bottom-1"></div>
                        <PasswordInput extraClasses="margin-bottom-1" placeholder="New password" required={true} password={newPassword} showPassword={showNewPassword} autoComplete="new-password" setPassword={setNewPassword} handleKeyDown={handleResetPassword} toggleShowPassword={toggleNewPasswordVisibility} />
                        <PasswordInput extraClasses="margin-bottom-1" placeholder="Confirm new password" required={true} password={confirmNewPassword} autoComplete="new-password" showPassword={showConfirmNewPassword} setPassword={setConfirmNewPassword} handleKeyDown={handleResetPassword} toggleShowPassword={toggleConfirmNewPasswordVisibility} />
                        <PasswordChecklist className="extra-small-text font-style-montserrat-family-regular color-white"
                            rules={["capital", "lowercase", "minLength", "number", "specialChar", "match"]}
                            minLength={8}
                            fontSize={10}
                            value={newPassword}
                            valueAgain={confirmNewPassword}
                            iconComponents={icons} onChange={(isValid) => setPasswordIsValid(isValid)} />
                        <BorderTextFullWidthButton text="Change password" clickAction={handleResetPassword} extraClasses={`${buttonConfirmNewPasswordsEnable ? "button-hover" : "button-disabled"} margin-top-2`} type="submit" disabled={!buttonConfirmNewPasswordsEnable} />
                    </form>
                </>
            )}
        </CardProperties>
    );
}