import React, { useState, useEffect, useContext, useRef } from "react";
import { NavLink } from "react-router-dom";

import { GeneralContext } from "../../utils/functions_variables/variables";
import openSupportTicket from "../../utils/requests/supportMessage";
import { handleKeyDownEvent } from "../../utils/functions_variables/functions";

import "./Configurations.scss"

import LocalLoader from "../../components/LocalLoader/LocalLoader";
import HeaderTitle from "../../components/HeaderTitle/HeaderTitle";
import { BorderTextFullWidthButton, CardProperties, NormalInput, Textarea } from "../../assets/styles/UIKit/UIKit";
import { FlexBlockDiv } from "../../assets/styles/global";

const Support = () => {
    const [isLoading, setIsLoading] = useState(false)
    const { userName, userEmail, setWarningMessage } = useContext(GeneralContext);
    const [subject, setSubject] = useState("");
    const [description, setDescription] = useState("");
    const [buttonEnable, setButtonEnable] = useState(false)
    const form = useRef();

    useEffect(() => {
        if (subject !== "" && subject?.length > 2 && description !== "" && description?.length > 15) {
            setButtonEnable(true)
        } else {
            setButtonEnable(false)
        }
    }, [description, subject]);

    if (isLoading) {
        return <LocalLoader></LocalLoader>;
    }

    const onSubmit = async (event) => {
        if (buttonEnable) {
            event.preventDefault();
            setIsLoading(true)
            await openSupportTicket(form).then((data) => {
                setIsLoading(false)
                setWarningMessage(data)
            }).catch((error) => {
                setIsLoading(false)
                setWarningMessage(["error", error.message])
            })
        }
    }

    return (
        <div className="configurations-main-div">
            <HeaderTitle text="Support - How can we help you?"></HeaderTitle>
            <CardProperties className="CardProperties configurations-card support-card">
                <FlexBlockDiv>
                    <p className="faq-reminder extra-small-text font-style-montserrat-family-regular color-white">Before submitting your support request, please take a moment to review our <NavLink to="/configurations/faq" state={{ from: 2 }}>FAQ</NavLink> page.
                        <br />It's possible that your question has already been answered there, saving you time and effort. If you can't find the information you need, feel free to proceed with filling out the form below.
                        <br />We reply within 48 hours.</p>
                </FlexBlockDiv>
                <form ref={form} onSubmit={onSubmit}>
                    <div className="form-div">
                        <NormalInput extraClasses="input-small margin-bottom-1" name="name" text={userName} type="text" disabled={true} />
                        <NormalInput extraClasses="input-small margin-bottom-1" name="email" text={userEmail} type="text" disabled={true} />
                        <input type="hidden" name="name" value={userName} />
                        <input type="hidden" name="email" value={userEmail} />
                        <NormalInput extraClasses="margin-bottom-1" placeholder="Subject" name="subject" text={subject} setText={setSubject} autoFocus={true} required={true} type="text" handleKeyDown={onSubmit} />
                        <Textarea placeholder="Description" name="message" maxLength={1200} type="text" numberRows="7" text={description} setText={setDescription} required={true} />
                    </div>
                </form>
                <BorderTextFullWidthButton text="Send" extraClasses={buttonEnable ? "button-hover" : "button-disabled"} clickAction={onSubmit} type="submit" disabled={!buttonEnable} />
            </CardProperties>
        </div>
    );
}

export default Support;