import React, { useContext, useState, useEffect } from "react";

import "./OptionalMetricsSelectionExport.scss"

import { AiFillExclamationCircle, AiOutlineDownload } from "react-icons/ai";
import { IoCheckmarkCircle } from "react-icons/io5";

import OptionalMetricsSelectionTable from "./OptionalMetricsSelectionTable/OptionalMetricsSelectionTable";
import LocalLoader from "../../../../LocalLoader/LocalLoader";

import { getPAIStepTwoTableData } from "../../../../../utils/requests/getRequests";
import { GeneralContext } from "../../../../../utils/functions_variables/variables";

const OptionalMetricsSelectionExport = ({ selectedFund, loadingText, setLoadingText, checkedItemsAdditionalClimate, setCheckedItemsAdditionalClimate, checkedItemsAdditionalSocial, setCheckedItemsAdditionalSocial, tableOneDone, setTableOneDone, tableTwoDone, setTableTwoDone }) => {
    const { databaseAddress, setWarningMessage } = useContext(GeneralContext)
    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState(null)
    const [step, setStep] = useState(1)

    useEffect(() => {
        const hasTrueValue = Array.from(checkedItemsAdditionalClimate?.values())?.some(Boolean);
        setTableOneDone(hasTrueValue)
    }, [checkedItemsAdditionalClimate]);

    useEffect(() => {
        const hasTrueValue = Array.from(checkedItemsAdditionalSocial?.values())?.some(Boolean);
        setTableTwoDone(hasTrueValue)
    }, [checkedItemsAdditionalSocial]);

    

    const selectTable = (tableNumber) => {
        setStep(tableNumber)
        navigateToTable(tableNumber);
    }

    const navigateToTable = (tableNumber) => {
        document.querySelectorAll(".list-item").forEach((listItem) => {
            listItem.classList.add("list-item-unfinished");
            listItem.classList.remove("list-item-active");
        });
        const listItemActive = document.querySelector('.list-item[step="' + tableNumber + '"]');
        listItemActive.classList.remove("list-item-unfinished");
        listItemActive.classList.add("list-item-active");
    };

    useEffect(() => {
        const getStepTwo = async () => {
            setIsLoading(true)
            await getPAIStepTwoTableData(databaseAddress, selectedFund?.label).then(data => {
                if (Object.keys(data)?.length > 0) { setData(data) }
                setLoadingText(null)
                setIsLoading(false)
            }).catch(error => {
                setIsLoading(false)
                setWarningMessage(["error", error.message])
            })
        }

        getStepTwo()
    }, [])

    if (isLoading) {
        return <LocalLoader text={loadingText}></LocalLoader>;
    }

    return (
        <div className="optional-metrics-main-div">
            {data ? <>
                <div className="optional-metrics-list">
                    <div className="table-selection-div">
                        <ul>
                            <li className="list-item-active list-item" step="1" onClick={() => selectTable(1)}>
                                <a>
                                    {tableOneDone ? <IoCheckmarkCircle className="icon color-green medium-text"></IoCheckmarkCircle> : <AiFillExclamationCircle className="icon color-orange medium-text"></AiFillExclamationCircle>}
                                    <div className="label font-style-montserrat-family-regular extra-small-text">ADDITIONAL CLIMATE AND OTHER ENVIRONMENT-RELATED INDICATORS (ADDITIONAL DISCLOSURES)</div>
                                </a>
                            </li>
                            <li className="list-item-unfinished list-item" onClick={() => selectTable(2)} step="2">
                                <a>
                                    {tableTwoDone ? <IoCheckmarkCircle className="icon color-green medium-text"></IoCheckmarkCircle> : <AiFillExclamationCircle className="icon color-orange medium-text"></AiFillExclamationCircle>}
                                    <div className="label font-style-montserrat-family-regular extra-small-text">ADDITIONAL INDICATORS FOR SOCIAL AND EMPLOYEE, RESPECT FOR HUMAN RIGHTS, ANTI-CORRUPTION AND ANTI-BRIBERY MATTERS (ADDITIONAL DISCLOSURES)</div>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="selected-table-div">
                        {step === 1 ? <>
                            <OptionalMetricsSelectionTable checkedItems={checkedItemsAdditionalClimate} setCheckedItems={setCheckedItemsAdditionalClimate} title="INDICATORS APPLICABLE TO INVESTMENTS IN INVESTEE COMPANIES" data={data?.additionalClimate?.investeeCompanies.climateAndOthers} indicator="additionalClimate" table="investeeCompanies" additional="climateAndOthers" />
                            <OptionalMetricsSelectionTable checkedItems={checkedItemsAdditionalClimate} setCheckedItems={setCheckedItemsAdditionalClimate} title="INDICATORS APPLICABLE TO INVESTMENTS IN SOVEREIGNS AND SUPRANATIONALS" data={data?.additionalClimate?.sovereignsAndSupranationals} indicator="additionalClimate" table="sovereignsAndSupranationals" />
                            <OptionalMetricsSelectionTable checkedItems={checkedItemsAdditionalClimate} setCheckedItems={setCheckedItemsAdditionalClimate} title="INDICATORS APPLICABLE TO INVESTMENTS IN REAL ESTATE ASSETS" data={data?.additionalClimate?.realEstateAssets} indicator="additionalClimate" table="realEstateAssets" />
                        </> : ""}
                        {step === 2 ? <>
                            <OptionalMetricsSelectionTable checkedItems={checkedItemsAdditionalSocial} setCheckedItems={setCheckedItemsAdditionalSocial} title="INDICATORS APPLICABLE TO INVESTMENTS IN INVESTEE COMPANIES" data={data?.additionalSocial?.investeeCompanies.socialEmployeeHumanRights} indicator="additionalSocial" table="investeeCompanies" additional="socialEmployeeHumanRights" />
                            <OptionalMetricsSelectionTable checkedItems={checkedItemsAdditionalSocial} setCheckedItems={setCheckedItemsAdditionalSocial} title="INDICATORS APPLICABLE TO INVESTMENTS IN SOVEREIGNS AND SUPRANATIONALS" data={data?.additionalSocial?.sovereignsAndSupranationals} indicator="additionalSocial" table="sovereignsAndSupranationals" />
                        </> : ""}
                    </div>
                </div>
                {(!tableOneDone || !tableTwoDone) && <p className="note-label font-style-montserrat-family-semibold extra-extra-small-text color-orange">You must select at least one metric from each additional indicator.</p>}</>
                :
                <p className="no-data-label font-style-montserrat-family-regular small-text color-white">There's not enough data available to be exported.</p>}
        </div>
    );
}

export default OptionalMetricsSelectionExport;