import React from "react";

import "./DoubleTextCard.scss"

import { CardProperties } from "../../../assets/styles/UIKit/UIKit";

const DoubleTextCard = ({ title_1, title_2, data_funds, data_sectors, cardType }) => {

    return (
        <CardProperties className={`CardProperties double-text-card-div ${cardType} extra-small-text font-style-montserrat-family-regular color-white`}>
            <p>{title_1}</p>
            <ul>
                {data_funds?.map((child, index) => <li key={index}>{child.name} with {`${(child?.percentage * 100)?.toFixed(1).replace(/[.,]0/, "")}%`}</li>)}
            </ul>
            <p>{title_2}</p>
            <ul>
                {data_sectors?.map((child, index) => <li key={index}>{child.name} with {`${(child?.percentage * 100)?.toFixed(1).replace(/[.,]0/, "")}%`}</li>)}
            </ul>
        </CardProperties>
    );
}

export default DoubleTextCard;