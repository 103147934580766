import React, { useState, useContext } from "react";

import "../ManageSimulation.scss"

import HeaderTitle from "../../../HeaderTitle/HeaderTitle"

const SimulationType = () => {

  return (
    <>
      <HeaderTitle text="Type" />
      
    </>
  );
}

export default SimulationType;