import React, { useState, useContext } from "react";
import "react-datepicker/dist/react-datepicker.css";

import "../ManageFund.scss"
import { ManageFundContext } from "../../../../utils/functions_variables/variables";

import FormField from "../FormField";
import HeaderTitle from "../../../HeaderTitle/HeaderTitle";

import { BorderTextButton, CardProperties, NormalInput } from "../../../../assets/styles/UIKit/UIKit";

const FundDetails = () => {
  const { purpose, setTab, fundsNames, fundName, setFundName, setStepOneDone } = useContext(ManageFundContext)

  const [name, setName] = useState(fundName)
  const [nameFilled, setNameFilled] = useState(fundName !== '' && fundName.length > 2)
  const [sameName, setSameName] = useState(false)

  function handleNameChange(e) {
    setName(e)
    if (!fundsNames?.includes(e)) {
      setSameName(false)
      setFundName(e)
      if (e.length > 2) { setNameFilled(true) }
    } else {
      setNameFilled(false)
      setSameName(true)
    }
  }

  function handleSave() {
    setStepOneDone(true)
    setTab(2)
  }

  return (
    <>
      <HeaderTitle text="Details" />
      <div className="fund-details-div">
        <CardProperties className="CardProperties">
          <FormField fieldName="Fund name" field={
            purpose === "details"
              ?
              <p className="font-style-montserrat-family-regular extra-small-text color-white">{name}</p>
              :
              <NormalInput extraClasses={nameFilled ? "outline-green" : "outline-red"} text={name} setText={handleNameChange} type="text" autoFocus={true} required={true} maxLength={45} placeholder="Fund name" />
          } />
          {sameName && <FormField fieldName="" field={<p className="name-already-exists font-style-montserrat-family-regular extra-small-text color-orange">This fund name already exists</p>} />}
        </CardProperties>
        {purpose !== "details" &&
          <BorderTextButton text="Save" extraClasses={`${nameFilled ? "button-hover" : "button-disabled"} margin-top-2`} clickAction={handleSave} disabled={!nameFilled} />
        }
      </div>
    </>
  );
}

export default FundDetails;