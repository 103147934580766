import React from "react";

import "./HeaderTitle.scss"

import { CardProperties } from "../../assets/styles/UIKit/UIKit";

const HeaderTitle = ({ text, children }) => {

  return (
    <CardProperties className={`CardProperties header-title-div`}>
      <p className="font-style-montserrat-family-semibold small-text color-white">
        {text}
      </p>
      {children}
    </CardProperties>
  );
}

export default HeaderTitle;