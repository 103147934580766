
import React, { useState, useEffect, useRef, useContext } from "react";
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';

import { GeneralContext, PortfolioContext } from "../../utils/functions_variables/variables";
import { getFunds } from "../../utils/requests/getRequests";

import ManageFund from "../../components/ManagePortfolio/ManageFund/ManageFund";
import ManagePortfolio from "../../components/ManagePortfolio/ManagePortfolio";
import AssetProfile from "../../components/AssetProfile/AssetProfile";

export default function Portfolio() {
  const [isLoading, setIsLoading] = useState(false)
  const { databaseAddress, setWarningMessage } = useContext(GeneralContext)
  const [ManagePortfolioData, setManagePortfolioData] = useState(null)
  const [filteredFunds, setFilteredFunds] = useState(null);
  const [fundSearch, setFundSearch] = useState("");

  const dataFetchedRef = useRef(false);

  const getFundsList = async () => {
    setIsLoading(true)
    await getFunds(databaseAddress).then(data => {
      setManagePortfolioData(data)
      setFilteredFunds(data)
      setIsLoading(false)
    }).catch(error => {
      setIsLoading(false)
      setWarningMessage(["error", error.message])
    })
  }

  useEffect(() => {
    if (dataFetchedRef?.current) return;
    dataFetchedRef.current = true;

    getFundsList()
  }, [])

  useEffect(() => {
    if (ManagePortfolioData) {
      const filteredData = ManagePortfolioData?.filter(({ fund_name }) => fund_name?.toLowerCase().includes(fundSearch?.toLowerCase()));
      setFilteredFunds(filteredData)
    }
  }, [fundSearch]);

  const handleFundSearchChange = event => {
    setFundSearch(event?.target?.value);
  };

  return (
    <div className="content-main-div">
      <title>Lynxai | Portfolio</title>
      <PortfolioContext.Provider value={{ getFundsList, isLoading, setIsLoading, ManagePortfolioData, setManagePortfolioData, filteredFunds, setFilteredFunds, fundSearch, setFundSearch }}>
        <Routes>
          <Route path="/*" element={<Navigate replace to="/portfolio" />} />
          <Route exact path='/' element={<ManagePortfolio handleFundSearchChange={handleFundSearchChange} />} />
          <Route exact path='/create-fund' element={<ManageFund purposeSet="create" />} />
          <Route exact path='/edit-fund/:fund' element={<ManageFund purposeSet="edit" />} />
          <Route exact path='/fund-profile/:fund' element={<ManageFund purposeSet="profile" />} />
          <Route exact path='/asset-profile/:id' element={<AssetProfile />} />
        </Routes>
      </PortfolioContext.Provider>
    </div>
  );
}