import React, { useState, useContext } from "react";
import { components } from 'react-select';
import styled from "styled-components";

import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { CheckboxInput } from "./UIKit/UIKit";

export const Container = styled.div`
  display: flex;
  flex-direction:column;
  flex-wrap: no-wrap;
  justify-content: center;
  align-items: center;
  height:100vh;
  margin: 0;
  overflow-x: hidden;
`;

export const InsideContainer = styled.div`
  width:100vw;
  height: 100vh;
  display:none;
  flex-flow:column;
  @media (min-width: 800px) {
    display:flex;
  }
`;

export const MainDiv = styled.div`
  flex-wrap: wrap;
  box-sizing: border-box;
  flex:1;
  width: 100%;
  padding: var(--size-three);
  position: relative;
  display:flex;
`;

export const FlexBlockDiv = styled.div`
  display:flex;
  box-sizing: border-box;
  width: 100%;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  gap: var(--size-three);

  p {
    width: 100% !important;
}
`;

export const PasswordEyeButton = ({ clickAction, extraClasses, showCondition }) => {
  return (
    <button className={`password-eye-button ${extraClasses} small-text color-lightgray`} onClick={clickAction}>
      {showCondition ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
    </button>
  );
}

export const InputOptionSingleValue = ({
  children,
  ...rest
}) => {

  return (
    <components.Option className="listOptionSingle" {...rest}>
      <p className="font-style-montserrat-family-regular color-white extra-small-text">{children}</p>
    </components.Option>
  );
}

export const CheckboxOptionReactSelect = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  let bg = "transparent";
  if (isFocused) bg = "transparent";
  if (isActive) bg = "var(--main-blue)";

  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}>
      <div className="listOptionIsMulti">
        <CheckboxInput value={isSelected} handleChange={e => { }} />
        <p className="font-style-montserrat-family-regular color-white extra-small-text">{children}</p>
      </div>
    </components.Option>
  );
};

export const TooSmallMessage = styled.div`
  display:none;
  height: 100%;
  text-align: center;
  line-height: var(--size-four);
  width:100%;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  @media (max-width: 800px) {
    display:flex;
  }
`;

// REACT-SELECT STYLING //


const dropdownIndicatorStyle = (base, theme) => ({
  ...base,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  width: "25px",
  padding: "0",
  color: `${theme?.card}`,
  "&:hover": {
    color: `${theme?.textarea}`,
  },
})

const noOptionsMessageStyle = (base, theme) => ({
  ...base,
  color: `${theme?.extreme_color}`,
})

const menuStyle = (base) => ({
  ...base,
  background: "transparent",
  borderRadius: "5px",
  paddingTop: "0",
  zIndex: 7,
  width: '100%',
  position: "absolute",
})

const menuPortalStyle = (base) => ({
  ...base,
  zIndex: 7,
})

const menuListStyle = (base, theme) => ({
  ...base,
  background: `${theme?.menu_list_background}`,
  border: `1px solid ${theme?.contrast_text_color}`,
  width: "100%",
  padding: 0,
  borderRadius: "5px"
})

const controlStyle = (base, isFocused, theme, borderStatus) => ({
  ...base,
  height: "35px",
  width: "100%",
  display: "flex",
  alignItems: "center",
  padding: "0 var(--size-one)",
  overflow: "hidden",
  borderRadius: "var(--border-radius)",
  border: borderStatus ? `1px solid ${borderStatus}` : isFocused ? "1px solid var(--main-blue)" : `1px solid ${theme?.contrast_color}`,
  background: "var(--gray-4)",
  outline: 'none',
  color: `${theme?.secondary_background}`,
  boxShadow: null,
  "&:hover": {
    background: `${theme?.button_color}`,
  },
})

const optionStyle = (base, isFocused, theme) => ({
  ...base,
  backgroundColor: isFocused && `${theme?.translucid_hover}`,
  borderLeft: isFocused ? `2px solid var(--main-blue)` : "2px solid transparent",
  height: "var(--size-six)",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  display: 'flex',
  alignItems: 'center',
  overflow: "hidden",
})

const inputStyle = (base, theme) => ({
  ...base,
  color: `${theme?.secondary_background}`
})

const clearIndicatorStyle = () => ({
  display: "none"
})

const indicatorSeparatorStyle = () => ({
  display: "none"
})

// react-select normal dropdown styling //
export const reactSelectNormalStyles = (theme, borderStatus) => {
  return {
    control: (base, { isFocused }) => controlStyle(base, isFocused, theme, borderStatus),
    menu: () => menuStyle,
    indicatorSeparator: () => indicatorSeparatorStyle,
    clearIndicator: () => clearIndicatorStyle,
    option: (base, { isFocused }) => optionStyle(base, isFocused, theme),
    menuList: base => menuListStyle(base, theme),
    input: (base) => inputStyle(base, theme),
    dropdownIndicator: (base) => dropdownIndicatorStyle(base, theme),
    menuPortal: (base) => menuPortalStyle(base),
    noOptionsMessage: (base) => noOptionsMessageStyle(base, theme)
  }
}

// react-select navbar searchbar dropdown styling //
export const reactSelectNavbarSearchBarStyles = (theme) => {
  return {
    control: (base, state) => ({
      ...base,
      height: "35px",
      width: "100%",
      display: "flex",
      alignItems: "center",
      padding: "0 var(--size-two)",
      overflow: "hidden",
      border: state.isFocused ? "1px solid var(--main-blue)" : `1px solid ${theme?.contrast_color}`,
      background: "transparent",
      color: `${theme?.extreme_color}`,
      boxShadow: null,
      "&:hover": {
        background: `${theme?.translucid_hover}`,
      },
    }),
    menu: () => menuStyle,
    indicatorSeparator: () => indicatorSeparatorStyle,
    clearIndicator: () => clearIndicatorStyle,
    option: (styles, { isSelected, isFocused }) => {
      return {
        ...styles,
        padding: '0',
        backgroundColor: isFocused && `${theme?.translucid_hover}`,
        borderLeft: isFocused ? `2px solid var(--main-blue)` : "2px solid transparent",
      };
    },
    group: base => ({
      ...base,
      padding: 0,
    }),
    menuList: base => ({
      ...base,
      background: `${theme?.menu_list_background}`,
      border: `1px solid ${theme?.contrast_text_color}`,
      width: "100%",
      minWidth: "250px",
      position: "absolute",
      padding: 0,
      top: "0",
      right: "0",
      borderRadius: "5px"
    }),
    input: (base) => {
      return {
        ...base,
        color: `${theme?.extreme_color}`,
      }
    },
    dropdownIndicator: (base) => ({
      ...base,
      display: "none"
    }),
    menuPortal: (base) => menuPortalStyle(base),
    noOptionsMessage: (base) => noOptionsMessageStyle(base, theme)
  }
}

///////////////////////////

export const COLORS_1 = ['#FFBB28', '#9747FF'];
export const COLORS_2 = ['#0071dc', '#36E7FF', '#35D272', '#FFBB28', '#FF8042', '#FE562F', '#9747FF', '#4fe8b0'];