import React from "react";

import "./FAQ.scss"

import { CardProperties, NoBorderTextIconFullWidthButton } from "../../../assets/styles/UIKit/UIKit";
import { FiChevronRight } from "react-icons/fi";

const FAQContentCard = ({ title, topics, setOpen, setModalTitle, setModalContent }) => {

  const handleOpenModal = (item) => {
    setModalTitle(item?.title)
    setModalContent(item?.description)
    setOpen(true)
  }

  return (
    <CardProperties className="faq-content-card-div CardProperties">
        <p className="margin-bottom-2 font-style-montserrat-family-semibold extra-small-text color-white">{title}</p>
        {topics.map((item, index) =>
        <NoBorderTextIconFullWidthButton extraClasses="margin-bottom-1" text={item?.title} icon={<FiChevronRight className="icon small-text" />} clickAction={() =>handleOpenModal(item)} />
        )}
    </CardProperties>
  );
}

export default FAQContentCard;