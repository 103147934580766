import React, { useState, useContext } from "react";

import "../ManageSimulation.scss"

import HeaderTitle from "../../../HeaderTitle/HeaderTitle"

const SimulationAssets = () => {

  return (
    <>
      <HeaderTitle text="Assets" />
      
    </>
  );
}

export default SimulationAssets;