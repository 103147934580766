import React, { useState, useEffect, useRef, useContext } from "react";

import "../Regulations.scss"

import HeaderTitle from "../../../components/HeaderTitle/HeaderTitle";
import LocalLoader from "../../../components/LocalLoader/LocalLoader";
import MainTitleTransparentCard from "../../../components/Cards/MainTitleTransparentCard/MainTitleTransparentCard";
import OneValueLabelBottomCard from "../../../components/Cards/OneValueLabelBottomCard/OneValueLabelBottomCard";
import PieChartPercentageCard from "../../../components/DataVisualization/PieChartPercentageCard/PieChartPercentageCard";
import DoughnutChartPercentageCard from "../../../components/DataVisualization/DoughnutChartPercentageCard/DoughnutChartPercentageCard";
import BarChartCard from "../../../components/DataVisualization/BarChartCard/BarChartCard"
import BarChartPercentageCard from "../../../components/DataVisualization/BarChartPercentageCard/BarChartPercentageCard"
import TreeMapCard from "../../../components/DataVisualization/TreeMapCard/TreeMapCard";
import ChoroplethMapEuropeCard from "../../../components/DataVisualization/ChoroplethMapEuropeCard/ChoroplethMapEuropeCard";
import TextCard from "../../../components/Cards/TextCard/TextCard";
import FilterFieldCard from "../../../components/Cards/FilterFieldCard/FilterFieldCard";

import { getEUTaxonomyEligibility } from "../../../utils/requests/getRequests";
import { COLORS_1, COLORS_2 } from "../../../assets/styles/global";
import { GeneralContext } from "../../../utils/functions_variables/variables";

const InvestmentFundEligibility = () => {
  const { setWarningMessage } = useContext(GeneralContext)
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true)
  const dataFetchedRef = useRef(false);

  useEffect(() => {
    if (dataFetchedRef?.current) return;
    dataFetchedRef.current = true;

    const EUTaxonomyEligibility = async () => {
      setIsLoading(true)
      await getEUTaxonomyEligibility().then(data => {
        setData(data);
        setIsLoading(false)
      }).catch(error => {
        setIsLoading(false)
        setWarningMessage(["error", error.message])
      })
    }

    EUTaxonomyEligibility()
  }, [data])

  if (isLoading) {
    return <LocalLoader></LocalLoader>;
  }

  return (
    <div className="regulations-content">
      <HeaderTitle text="EU Taxonomy"></HeaderTitle>
      <FilterFieldCard title="Choose Investment Fund" data={data?.portfolios_turnover_alignment_percentage} listContent={data?.funds} cardType="one-third-chart-card-size"></FilterFieldCard>
      <FilterFieldCard title="Choose Asset Type" data={data?.portfolios_capex_alignment_percentage} listContent={data?.asset_types} cardType="one-third-chart-card-size"></FilterFieldCard>
      <FilterFieldCard title="Choose Sector" data={data?.portfolios_opex_alignment_percentage} listContent={data?.sectors} cardType="one-third-chart-card-size"></FilterFieldCard>
      <MainTitleTransparentCard text="Turnover Metric Analysis"></MainTitleTransparentCard>
      <DoughnutChartPercentageCard title="Total Eligibility Turnover %" data={data?.total_eligible_turnover_percentage} cardType="small-chart-card-size card-height-3"></DoughnutChartPercentageCard>
      <BarChartPercentageCard title="Total Eligibility Turnover % by Investment Fund" data={data?.total_eligible_turnover_percentage_by_investment_fund} cardType="big-chart-card-size card-height-3"></BarChartPercentageCard>
      <BarChartCard title="Total Eligibility Turnover € by Investment Fund" data={data?.total_eligible_turnover_by_investment_fund} cardType="big-chart-card-size card-height-3"></BarChartCard>
      <PieChartPercentageCard title="Total Eligible Turnover % by Type of Asset" data={data?.total_eligible_turnover_percentage_by_asset_manager} cardType="small-chart-card-size card-height-3" colors={COLORS_1}></PieChartPercentageCard>
      <PieChartPercentageCard title="Eligible Turnover % by Sector" data={data?.eligible_turnover_percentage_by_sector} cardType="big-chart-card-size card-height-3" colors={COLORS_2}></PieChartPercentageCard>
      <ChoroplethMapEuropeCard title="Total Eligible Turnover % by Country" cardType="small-chart-card-size card-height-3" data={data?.total_eligible_turnover_percentage_by_country}></ChoroplethMapEuropeCard>
      <MainTitleTransparentCard text="CAPEX Metric Analysis"></MainTitleTransparentCard>
      <OneValueLabelBottomCard text="Total Eligible CAPEX %" value={data?.total_eligible_capex_percentage} cardType="small-small-chart-card-size card-height-1"></OneValueLabelBottomCard>
      <OneValueLabelBottomCard text="Total Non-Eligible CAPEX %" value={data?.total_non_eligible_capex_percentage} cardType="small-small-chart-card-size card-height-1"></OneValueLabelBottomCard>
      <TextCard title="Top 3 Funds for Total Portfolio Eligible OPEX % - Non_Financials are:" data={data?.top_three_funds_total_portfolio_eligible_capex.funds} cardType="medium-chart-card-size card-height-1"></TextCard>
      <BarChartPercentageCard title="Total Eligibility CAPEX % by Investment Fund" data={data?.total_eligible_capex_percentage_by_investment_fund} cardType="medium-chart-card-size card-height-3"></BarChartPercentageCard>
      <BarChartCard title="Total Eligibility CAPEX € by Investment Fund" data={data?.total_eligible_capex_currency_by_investment_fund} cardType="medium-chart-card-size card-height-3"></BarChartCard>
      <TextCard title="Top 3 Sector for Total Portfolio Eligible CAPEX % - Non_Financials are:" data={data?.top_three_sector_total_portfolio_eligible_capex.funds} cardType="medium-chart-card-size card-height-1"></TextCard>
      <TextCard title="Top 3 Companies for Total Portfolio Eligible CAPEX % - Non_Financials are:" data={data?.top_three_companies_total_portfolio_eligible_capex.funds} cardType="medium-chart-card-size card-height-1"></TextCard>
      <PieChartPercentageCard title="Total Eligible CAPEX % by Sector" data={data?.total_eligible_capex_percentage_by_sector} cardType="medium-chart-card-size card-height-3" colors={COLORS_2}></PieChartPercentageCard>
      <TreeMapCard title="Total Eligible CAPEX % by Company" data={data?.total_eligible_capex_percentage_by_company} colors={COLORS_2} cardType="medium-chart-card-size card-height-3"></TreeMapCard>
      <MainTitleTransparentCard text="OPEX Metric Analysis"></MainTitleTransparentCard>
      <OneValueLabelBottomCard text="Total Eligible OPEX %" value={data?.total_eligible_opex_percentage} cardType="small-small-chart-card-size card-height-1"></OneValueLabelBottomCard>
      <OneValueLabelBottomCard text="Total Non-Eligible OPEX %" value={data?.total_non_eligible_opex_percentage} cardType="small-small-chart-card-size card-height-1"></OneValueLabelBottomCard>
      <TextCard title="Top 3 Funds for Total Portfolio Eligible OPEX % - Non_Financials are:" data={data?.top_three_funds_total_portfolio_eligible_opex.funds} cardType="medium-chart-card-size card-height-1"></TextCard>
      <BarChartPercentageCard title="Total Eligibility OPEX % by Investment Fund" data={data?.total_eligible_opex_percentage_by_investment_fund} cardType="medium-chart-card-size card-height-3"></BarChartPercentageCard>
      <BarChartCard title="Total Eligibility OPEX € by Investment Fund" data={data?.total_eligible_opex_currency_by_investment_fund} cardType="medium-chart-card-size card-height-3"></BarChartCard>
      <TextCard title="Top 3 Sector for Total Portfolio Eligible OPEX % - Non_Financials are:" data={data?.top_three_sector_total_portfolio_eligible_opex.funds} cardType="medium-chart-card-size card-height-1"></TextCard>
      <TextCard title="Top 3 Companies for Total Portfolio Eligible OPEX % - Non_Financials are:" data={data?.top_three_companies_total_portfolio_eligible_opex.funds} cardType="medium-chart-card-size card-height-1"></TextCard>
      <PieChartPercentageCard title="Total Eligible OPEX % by Sector" data={data?.total_eligible_opex_percentage_by_sector} cardType="medium-chart-card-size card-height-3" colors={COLORS_2}></PieChartPercentageCard>
      <TreeMapCard title="Total Eligible OPEX % by Company" data={data?.total_eligible_opex_percentage_by_company} colors={COLORS_2} cardType="medium-chart-card-size card-height-3"></TreeMapCard>
    </div>
  );
}

export default InvestmentFundEligibility;