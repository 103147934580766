import React, { useEffect, useContext, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';

import "./FundProfile.scss"

import { GeneralContext } from "../../../../utils/functions_variables/variables";
import ThemeContext, { ThemeVariables } from "../../../../assets/styles/darkLightMode/theme-context";
import { FundProfileContext } from "../../../../utils/functions_variables/variables";
import { InputOptionSingleValue, FlexBlockDiv } from "../../../../assets/styles/global";
import { getFund, getFundCharacteristics, getFundEnvironmental, getFundReportDownload } from "../../../../utils/requests/getRequests";
import { getFileFromBucket } from "../../../../utils/requests/downloadFileFromBucket";

import MainLoader from "../../../MainLoader/MainLoader";
import FundReportExportationMetricsSelectionModalContent from "./FundReportExportationMetricsSelectionModalContent/FundReportExportationMetricsSelectionModalContent";
import ModalWindow from "../../../ModalWindow/ModalWindow";
import FundProfileEnvironmental from "./tabs/FundProfileEnvironmental";
import FundProfileCharacteristics from "./tabs/FundProfileCharacteristics";
import FundProfileAssets from "./tabs/FundProfileAssets";
import { NoBorderIconButton, BorderIconTextButton, NoBorderIconTextButton, NormalDropdown, NormalControlReactSelect } from "../../../../assets/styles/UIKit/UIKit";

import { AiOutlineDownload, AiOutlineReload } from "react-icons/ai";
import { ImArrowLeft2 } from "react-icons/im";
import { CiEdit } from "react-icons/ci";

import metrics from "../../../../views/Overview/optional_metrics.json"

const FundProfile = ({ fund, fundsList }) => {
    const { theme, setTheme } = useContext(ThemeContext);
    const { databaseAddress, setWarningMessage } = useContext(GeneralContext)
    const [tab, setTab] = useState(1)

    const [fundID, setFundID] = useState(null);
    const [fundAssets, setFundAssets] = useState(null);
    const [fundCharacteristicsData, setFundCharacteristicsData] = useState(null);
    const [availableMetrics, setAvailableMetrics] = useState(metrics);
    const [fundSelectedMetrics, setFundSelectedMetrics] = useState([]);
    const [fundEnvironmentalData, setFundEnvironmentalData] = useState([]);

    const [isModalOpen, setisModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false)

    const fundDetails = async (fundName) => {
        setIsLoading(true)
        await getFund(databaseAddress, fundName).then(data => {
            loadFundInfo(data)
        }).catch(error => {
            setWarningMessage(["error", error.message])
            setIsLoading(false)
        })
    }

    const fundCharacteristics = async (fundName) => {
        await getFundCharacteristics(databaseAddress, fundName).then(data => {
            setFundCharacteristicsData(data)
        }).catch(error => {
            setWarningMessage(["error", error.message])
            setIsLoading(false)
        })
    }

    const fundEnvironmental = async (fundName) => {
        await getFundEnvironmental(databaseAddress, fundName).then(data => {
            let organizedData = organizeFundEnvironmentalData(data)
            setFundEnvironmentalData(organizedData)
            setIsLoading(false)
        }).catch(error => {
            setWarningMessage(["error", error.message])
            setIsLoading(false)
        })
    }

    useEffect(() => {
        if (fund) {
            fundDetails(fund)
            fundCharacteristics(fund)
            fundEnvironmental(fund)
        }
    }, [fund])

    async function handleExportReportClick() {
        setIsLoading(true)
        let filename = fund + " - " + new Date().toLocaleDateString('gb-GB') + ".docx"
        await getFundReportDownload(databaseAddress, fund, metrics).then(response => {
            getFileFromBucket(response?.bucket_name, response?.document_name, filename, 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')
            setIsLoading(false)
            setWarningMessage(["info", "The download will start soon."])
        }).catch(error => {
            setIsLoading(false)
            setWarningMessage(["error", error.message])
        })
    };

    function organizeFundEnvironmentalData(data) {
        let organizedData = data
        organizedData["CO2T"]["bestAndWorstAssetsPerEurInvested"] = data?.CO2T?.bestAssetsPerEurInvested.map(item => ({ ...item, type: 'best', best: item.value, worst: null })).concat(data?.CO2T?.worstAssetsPerEurInvested.map(item => ({ ...item, type: 'worst', best: null, worst: item.value })))
        delete organizedData?.CO2T?.bestAssetsPerEurInvested;
        delete organizedData?.CO2T?.worstAssetsPerEurInvested;
        organizedData["CO2T"]["bestAndWorstAssetsPerRevenue"] = data?.CO2T?.bestAssetsPerRevenue.map(item => ({ ...item, type: 'best', best: item.value, worst: null })).concat(data?.CO2T?.worstAssetsPerRevenue.map(item => ({ ...item, type: 'worst', best: null, worst: item.value })))
        delete organizedData?.CO2T?.bestAssetsPerRevenue;
        delete organizedData?.CO2T?.worstAssetsPerRevenue;
        organizedData["CO2T"]["bestAndWorstIndustriesPerEurInvested"] = data?.CO2T?.bestIndustriesPerEurInvested.map(item => ({ ...item, type: 'best', best: item.value, worst: null })).concat(data?.CO2T?.worstIndustriesPerEurInvested.map(item => ({ ...item, type: 'worst', best: null, worst: item.value })))
        delete organizedData?.CO2T?.bestIndustriesPerEurInvested;
        delete organizedData?.CO2T?.worstIndustriesPerEurInvested;
        organizedData["CO2T"]["bestAndWorstIndustriesPerRevenue"] = data?.CO2T?.bestIndustriesPerRevenue.map(item => ({ ...item, type: 'best', best: item.value, worst: null })).concat(data?.CO2T?.worstIndustriesPerRevenue.map(item => ({ ...item, type: 'worst', best: null, worst: item.value })))
        delete organizedData?.CO2T?.bestIndustriesPerRevenue;
        delete organizedData?.CO2T?.worstIndustriesPerRevenue;

        let highestValueAssetsPerEuroInvestedCO2S1 = data?.CO2S1?.worstAssetsPerEurInvested.reduce((max, current) => { return current?.value > max ? current?.value : max }, data?.CO2S1?.worstAssetsPerEurInvested[0]?.value)
        let highestValueAssetsPerRevenueCO2S1 = data?.CO2S1?.worstAssetsPerRevenue.reduce((max, current) => { return current?.value > max ? current?.value : max }, data?.CO2S1?.worstAssetsPerRevenue[0]?.value)
        let highestValueIndustriesPerEuroInvestedCO2S1 = data?.CO2S1?.worstIndustriesPerEurInvested.reduce((max, current) => { return current?.value > max ? current?.value : max }, data?.CO2S1?.worstIndustriesPerEurInvested[0]?.value)
        let highestValueIndustriesPerRevenueCO2S1 = data?.CO2S1?.worstIndustriesPerRevenue.reduce((max, current) => { return current?.value > max ? current?.value : max }, data?.CO2S1?.worstIndustriesPerRevenue[0]?.value)
        organizedData["CO2S1"]["domainAssetsPerEuroInvested"] = [[0, Math.ceil(highestValueAssetsPerEuroInvestedCO2S1 / calculateInterval(highestValueAssetsPerEuroInvestedCO2S1)) * calculateInterval(highestValueAssetsPerEuroInvestedCO2S1)], calculateInterval(highestValueAssetsPerEuroInvestedCO2S1)]
        organizedData["CO2S1"]["domainAssetsPerRevenue"] = [[0, Math.ceil(highestValueAssetsPerRevenueCO2S1 / calculateInterval(highestValueAssetsPerRevenueCO2S1)) * calculateInterval(highestValueAssetsPerRevenueCO2S1)], calculateInterval(highestValueAssetsPerRevenueCO2S1)]
        organizedData["CO2S1"]["domainIndustriesPerEuroInvested"] = [[0, Math.ceil(highestValueIndustriesPerEuroInvestedCO2S1 / calculateInterval(highestValueIndustriesPerEuroInvestedCO2S1)) * calculateInterval(highestValueIndustriesPerEuroInvestedCO2S1)], calculateInterval(highestValueIndustriesPerEuroInvestedCO2S1)]
        organizedData["CO2S1"]["domainIndustriesPerRevenue"] = [[0, Math.ceil(highestValueIndustriesPerRevenueCO2S1 / calculateInterval(highestValueIndustriesPerRevenueCO2S1)) * calculateInterval(highestValueIndustriesPerRevenueCO2S1)], calculateInterval(highestValueIndustriesPerRevenueCO2S1)]

        let highestValueAssetsPerEuroInvestedCO2S2 = data?.CO2S2?.worstAssetsPerEurInvested.reduce((max, current) => { return current?.value > max ? current?.value : max }, data?.CO2S2?.worstAssetsPerEurInvested[0]?.value)
        let highestValueAssetsPerRevenueCO2S2 = data?.CO2S2?.worstAssetsPerRevenue.reduce((max, current) => { return current?.value > max ? current?.value : max }, data?.CO2S2?.worstAssetsPerRevenue[0]?.value)
        let highestValueIndustriesPerEuroInvestedCO2S2 = data?.CO2S2?.worstIndustriesPerEurInvested.reduce((max, current) => { return current?.value > max ? current?.value : max }, data?.CO2S2?.worstIndustriesPerEurInvested[0]?.value)
        let highestValueIndustriesPerRevenueCO2S2 = data?.CO2S2?.worstIndustriesPerRevenue.reduce((max, current) => { return current?.value > max ? current?.value : max }, data?.CO2S2?.worstIndustriesPerRevenue[0]?.value)
        organizedData["CO2S2"]["domainAssetsPerEuroInvested"] = [[0, Math.ceil(highestValueAssetsPerEuroInvestedCO2S2 / calculateInterval(highestValueAssetsPerEuroInvestedCO2S2)) * calculateInterval(highestValueAssetsPerEuroInvestedCO2S2)], calculateInterval(highestValueAssetsPerEuroInvestedCO2S2)]
        organizedData["CO2S2"]["domainAssetsPerRevenue"] = [[0, Math.ceil(highestValueAssetsPerRevenueCO2S2 / calculateInterval(highestValueAssetsPerRevenueCO2S2)) * calculateInterval(highestValueAssetsPerRevenueCO2S2)], calculateInterval(highestValueAssetsPerRevenueCO2S2)]
        organizedData["CO2S2"]["domainIndustriesPerEuroInvested"] = [[0, Math.ceil(highestValueIndustriesPerEuroInvestedCO2S2 / calculateInterval(highestValueIndustriesPerEuroInvestedCO2S2)) * calculateInterval(highestValueIndustriesPerEuroInvestedCO2S2)], calculateInterval(highestValueIndustriesPerEuroInvestedCO2S2)]
        organizedData["CO2S2"]["domainIndustriesPerRevenue"] = [[0, Math.ceil(highestValueIndustriesPerRevenueCO2S2 / calculateInterval(highestValueIndustriesPerRevenueCO2S2)) * calculateInterval(highestValueIndustriesPerRevenueCO2S2)], calculateInterval(highestValueIndustriesPerRevenueCO2S2)]

        let highestValueAssetsPerEuroInvestedCO2S3 = data?.CO2S3?.worstAssetsPerEurInvested.reduce((max, current) => { return current?.value > max ? current?.value : max }, data?.CO2S3?.worstAssetsPerEurInvested[0]?.value)
        let highestValueAssetsPerRevenueCO2S3 = data?.CO2S3?.worstAssetsPerRevenue.reduce((max, current) => { return current?.value > max ? current?.value : max }, data?.CO2S3?.worstAssetsPerRevenue[0]?.value)
        let highestValueIndustriesPerEuroInvestedCO2S3 = data?.CO2S3?.worstIndustriesPerEurInvested.reduce((max, current) => { return current?.value > max ? current?.value : max }, data?.CO2S3?.worstIndustriesPerEurInvested[0]?.value)
        let highestValueIndustriesPerRevenueCO2S3 = data?.CO2S3?.worstIndustriesPerRevenue.reduce((max, current) => { return current?.value > max ? current?.value : max }, data?.CO2S3?.worstIndustriesPerRevenue[0]?.value)
        organizedData["CO2S3"]["domainAssetsPerEuroInvested"] = [[0, Math.ceil(highestValueAssetsPerEuroInvestedCO2S3 / calculateInterval(highestValueAssetsPerEuroInvestedCO2S3)) * calculateInterval(highestValueAssetsPerEuroInvestedCO2S3)], calculateInterval(highestValueAssetsPerEuroInvestedCO2S3)]
        organizedData["CO2S3"]["domainAssetsPerRevenue"] = [[0, Math.ceil(highestValueAssetsPerRevenueCO2S3 / calculateInterval(highestValueAssetsPerRevenueCO2S3)) * calculateInterval(highestValueAssetsPerRevenueCO2S3)], calculateInterval(highestValueAssetsPerRevenueCO2S3)]
        organizedData["CO2S3"]["domainIndustriesPerEuroInvested"] = [[0, Math.ceil(highestValueIndustriesPerEuroInvestedCO2S3 / calculateInterval(highestValueIndustriesPerEuroInvestedCO2S3)) * calculateInterval(highestValueIndustriesPerEuroInvestedCO2S3)], calculateInterval(highestValueIndustriesPerEuroInvestedCO2S3)]
        organizedData["CO2S3"]["domainIndustriesPerRevenue"] = [[0, Math.ceil(highestValueIndustriesPerRevenueCO2S3 / calculateInterval(highestValueIndustriesPerRevenueCO2S3)) * calculateInterval(highestValueIndustriesPerRevenueCO2S3)], calculateInterval(highestValueIndustriesPerRevenueCO2S3)]

        return organizedData
    }

    function calculateInterval(value) {
        if (value < 10000) {
            return 1000;
        } else if (value < 100000) {
            return 10000;
        } else if (value < 1000000) {
            return 100000;
        } else if (value < 10000000) {
            return 1000000;
        } else if (value < 100000000) {
            return 10000000;
        } else {
            return 100000000;
        }
    }

    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    }

    function loadFundInfo(data) {
        setFundID(data?.id)
        setFundAssets(generateFundAssets(data?.fundHistory))
    }

    function generateFundAssets(fundHistory) {
        let fundHistoryArr = {}
        for (let i = 0; i < fundHistory?.length; i++) {
            let assetsArr = []
            let date_formatted = new Date(fundHistory[i]?.date).toLocaleDateString('en-GB');
            for (let j = 0; j < fundHistory[i]?.assets?.length; j++) {
                assetsArr.push({ value: fundHistory[i]?.assets[j]?.id, keySecondary: Math.floor(Math.random() * 1000000000), label: fundHistory[i]?.assets[j]?.name, ticker: fundHistory[i]?.assets[j]?.ticker, type: fundHistory[i]?.assets[j]?.asset_type, currency: fundHistory[i]?.assets[j]?.currency, amount: fundHistory[i]?.assets[j]?.nominal_amount, share: fundHistory[i]?.assets[j]?.share_amount })
            }
            fundHistoryArr[date_formatted] = assetsArr
        }
        return fundHistoryArr
    }

    const selectTabNumber = (tab) => {
        setTab(tab)
    }

    function handleEditFund() {
        navigate(`../edit-fund/${fund}`)
    }

    function handleRemoveMetricToFundCharacteristicsData(metric, metric_id) {
        const newAvailableMetrics = [...availableMetrics, { value: metric_id, label: metric }]
        setAvailableMetrics(newAvailableMetrics);
        const newFundSelectedMetrics = fundSelectedMetrics?.filter((item) => item.metric_id !== metric_id);
        setFundSelectedMetrics(newFundSelectedMetrics);
    }

    function handleAddMetricToFundCharacteristicsData(metric, id, metric_id) {
        const newAvailableMetrics = availableMetrics?.filter((item) => item?.label !== metric);
        setAvailableMetrics(newAvailableMetrics);
        let newFundSelectedMetrics = []
        newFundSelectedMetrics = [...fundSelectedMetrics, { metric: metric, id: id, metric_id: metric_id }]
        setFundSelectedMetrics(newFundSelectedMetrics)
    }

    function handleReloadPage() {
        fundDetails(fund)
        fundCharacteristics(fund)
        fundEnvironmental(fund)
    }

    const FundProfileContent = () => {
        switch (tab) {
            case 1:
                return <FundProfileEnvironmental />
            case 2:
                return <FundProfileCharacteristics />
            case 3:
                return <FundProfileAssets fundAssets={fundAssets[Object.keys(fundAssets).pop()]} />
            default:
                return <FundProfileEnvironmental />
        }
    }

    function handleNavigation(event) {
        navigate(`../fund-profile/${event?.label}`)
    }

    if (isLoading) {
        return <MainLoader></MainLoader>;
    }

    return (
        <FundProfileContext.Provider value={{ tab, setTab, fundID, setFundID, fundAssets, setFundAssets, fundCharacteristicsData, setFundCharacteristicsData, availableMetrics, setAvailableMetrics, fundSelectedMetrics, setFundSelectedMetrics, fundEnvironmentalData, setFundEnvironmentalData, handleAddMetricToFundCharacteristicsData, handleRemoveMetricToFundCharacteristicsData }}>
            <div className="fund-profile-main-div">
                <FlexBlockDiv>
                    <NoBorderIconButton clickAction={goBack} icon={<ImArrowLeft2 className="icon medium-text color-lightgray" />} />
                    <NormalDropdown value={fundsList?.find(obj => obj.label === fund)} noOptionsMessage='No results match your searching criteria.'
                        list={fundsList} handleChange={event => handleNavigation(event)}
                        extraClasses={`${fundsList?.length === 0 && "button-disabled"} funds-dropdown`} components={{ Option: InputOptionSingleValue, Control: NormalControlReactSelect }}
                        isDisabled={fundsList?.length === 0} />
                    <NoBorderIconButton clickAction={handleReloadPage} extraClasses="margin-left-auto" icon={<AiOutlineReload className="icon medium-text color-white" />} />
                    <NoBorderIconTextButton text="Edit fund " icon={<CiEdit className="icon small-text"></CiEdit>} clickAction={() => handleEditFund} />
                    <BorderIconTextButton text="Create report" icon={<AiOutlineDownload className="icon small-text"></AiOutlineDownload>} clickAction={() => setisModalOpen(!isModalOpen)} />
                </FlexBlockDiv>
                <div className="multi-tab-main-div">
                    <ul className="form-stepper">
                        <li className={`form-stepper-${tab === 1 ? "active" : "unfinished"}`} onClick={() => selectTabNumber(1)} tab="1">
                            <a>
                                <div className="label font-style-montserrat-family-regular small-text">CO2 emissions</div>
                            </a>
                        </li>
                        <li className={`form-stepper-${tab === 2 ? "active" : "unfinished"}`} tab="2" onClick={() => selectTabNumber(2)}>
                            <a>
                                <div className="label font-style-montserrat-family-regular small-text">Characteristics</div>
                            </a>
                        </li>
                        <li className={`form-stepper-${tab === 3 ? "active" : "unfinished"}`} onClick={() => selectTabNumber(3)} tab="3">
                            <a>
                                <div className="label font-style-montserrat-family-regular small-text">Assets list</div>
                            </a>
                        </li>
                        <li className="form-stepper-unfinished"></li>
                    </ul>
                </div>
                <FundProfileContent />
            </div>
            <ModalWindow title="Select the metric analysis you would like to export" sizeClass="export-pai-metrics-visualization" topBlockItems={<BorderIconTextButton clickAction={handleExportReportClick} icon={<AiOutlineDownload className="icon medium-text" />} text="Export" />} open={isModalOpen} setOpen={setisModalOpen}>
                <FundReportExportationMetricsSelectionModalContent fundName={fund} metrics={metrics} />
            </ModalWindow>
        </FundProfileContext.Provider>
    );
}

export default FundProfile;