import "./App.scss"
import React, { useEffect } from "react";
import { useState } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';

import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';

import ThemeContext from "./assets/styles/darkLightMode/theme-context";
import { GeneralContext } from "./utils/functions_variables/variables"
import { Container, InsideContainer, TooSmallMessage } from "./assets/styles/global";
import getDefaultTheme from "./assets/styles/darkLightMode/ThemeMode";
import { ThemeVariables } from "./assets/styles/darkLightMode/theme-context";
import PrivateRoute from "./views/PrivateRoute/PrivateRoute";
import Authentication from "./views/Authentication/Authentication";
import Footer from "./components/Footer/Footer"
import Navbar from "./components/Navbar/Navbar"
import Overview from "./views/Overview/Overview"
import Configurations from "./views/Configurations/Configurations";
import Portfolio from "./views/Portfolio/Portfolio"
import Regulations from "./views/Regulations/Regulations"
import Simulator from "./views/Simulator/Simulator";
import { MainDiv } from "./assets/styles/global";

import { Amplify, Auth, Hub } from "aws-amplify";
import awsExports from './aws-exports';
import { RxCross2 } from "react-icons/rx";
import { ScrollToTopButton } from "./assets/styles/UIKit/UIKit";
Amplify.configure(awsExports);
Auth.configure(awsExports)

function App() {
  const [theme, setTheme] = useState(getDefaultTheme());
  const [warningMessage, setWarningMessage] = useState(null);
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [databaseAddress, setDatabaseAddress] = useState("");
  const [userRoles, setUserRoles] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const location = useLocation();

  let checkAuthState = async authState => {
    try {
      await Auth?.currentAuthenticatedUser().then(user => {
        if (user?.signInUserSession?.accessToken?.payload["cognito:groups"] !== undefined) {
          setUserName(user?.attributes?.name)
          setUserEmail(user?.attributes?.email)
          setDatabaseAddress(user?.attributes?.address)
          setUserRoles(user?.signInUserSession?.accessToken?.payload["cognito:groups"]);
          setIsAuthenticated(true)
        }
      })
    } catch {
      setIsAuthenticated(false)
      setUserName('')
      setUserEmail('')
      setDatabaseAddress('')
      setUserRoles('')
    }
  }

  useEffect(() => {
    Hub?.listen('auth', checkAuthState)
    checkAuthState()
  }, [isAuthenticated, location?.pathname]);

  useEffect(() => {
    if (warningMessage) {
      setTimeout(() => {
        const myDiv = document.getElementById('error-alert-box');
        myDiv.style.transform = 'translate(-50%,  50%)'
        const timeoutId = setTimeout(() => {
          myDiv.style.transform = 'translate(-50%,  -120%)'
          setTimeout(() => {
            setWarningMessage(null);
          }, 1000);
        }, 3500);

        return () => clearTimeout(timeoutId);
      }, 250);
    }
  }, [warningMessage])

  const listenToScroll = () => {
    if (isAuthenticated) {
      const winScroll = document.getElementById("Container")?.scrollTop
      if (winScroll > 150) {
        const button_el = document.getElementById("scroll-to-top-button")
        button_el.style.visibility = "visible"
        button_el.style.opacity = "1"
      } else {
        const button_el = document.getElementById("scroll-to-top-button")
        button_el.style.visibility = "collapse"
        button_el.style.opacity = "0"
      }
    }
  };

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <Container id="Container" className={`theme-${theme}`} onScroll={listenToScroll}>
        <TooSmallMessage className="TooSmallMessage font-style-montserrat-family-semibold color-white">THE SCREEN WIDTH IS TOO SMALL.<br />PLEASE USE A WIDER SCREEN.</TooSmallMessage>
        <GeneralContext.Provider value={{ isAuthenticated, userName, setUserName, userEmail, setUserEmail, databaseAddress, setDatabaseAddress, userRoles, setUserRoles, warningMessage, setWarningMessage }}>
          {warningMessage &&
            <div id="error-alert-box" className="alert-box-div">
              <Alert variant="outlined" sx={{ bgcolor: `${ThemeVariables[theme]?.translucid_hover}` }} color={warningMessage[0] ? warningMessage[0] : "primary"} action={<IconButton onClick={() => { setWarningMessage(null) }}><RxCross2 className="medium-text color-white" /></IconButton>} severity={warningMessage[0]} onClose={() => { setWarningMessage(null) }}>
                <p className="font-style-montserrat-family-regular extra-small-text color-white">{warningMessage[1]}</p>
              </Alert>
            </div>
          }
          <InsideContainer>
            {isAuthenticated && <ScrollToTopButton />}
            {isAuthenticated && <Navbar />}
            <MainDiv className="MainDiv">
              <Routes>
                <Route exact path='/overview' element={<PrivateRoute allowedRoles={['admin', 'vision']}><Overview /></PrivateRoute>} />
                <Route exact path='/login' element={<Authentication />} />
                <Route exact path='/portfolio/*' element={<PrivateRoute allowedRoles={['admin', 'vision', 'rcr']} ><Portfolio /></PrivateRoute>} />
                <Route exact path='/simulator/*' element={<PrivateRoute allowedRoles={['admin', 'vision']} ><Simulator /></PrivateRoute>} />
                <Route exact path='/regulations/*' element={<PrivateRoute allowedRoles={['admin', 'vision', 'rcr']} ><Regulations /></PrivateRoute>} />
                <Route exact path='/configurations/*' element={<PrivateRoute allowedRoles={['admin', 'vision', 'rcr']} ><Configurations /></PrivateRoute>} />
                <Route path="/*" element={<Navigate replace to="/overview" />} />
              </Routes>
            </MainDiv>
            {isAuthenticated && <Footer />}
          </InsideContainer>
        </GeneralContext.Provider>
      </Container>
    </ThemeContext.Provider>
  );
}

export default App;
