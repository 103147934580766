import React, { useState, useContext, useEffect } from "react";
import Select from 'react-select';

import "./CreateAsset.scss"
import countries from "./countries_list.json"
import investment_types from "./investment_type_list.json"
import ThemeContext, { ThemeVariables } from "../../../assets/styles/darkLightMode/theme-context";
import { ManageFundContext } from "../../../utils/functions_variables/variables";
import { CheckboxOptionReactSelect } from "../../../assets/styles/global";
import FormField from "./FormField";
import CheckboxField from "./CheckboxField";
import { InputOptionSingleValue } from "../../../assets/styles/global";
import { BackgroundSearchBarControlReactSelect, BorderTextFullWidthButton, NormalInput, RadioInput, NormalDropdownSearchBar, NormalDropdown, NormalControlReactSelect, IsMultiOptionBox } from "../../../assets/styles/UIKit/UIKit";

// IT IS NOT ALLOWED TO STORE THE ISIN's FROM THIS COUNTRY CODES 
const mandatoryTickerCountries = ['US', 'CA', 'KY', 'BM', 'VI', 'VG', 'UM', 'TT', 'SR', 'GS', 'SX', 'VC', 'MF', 'LC', 'KN', 'BL', 'PR', 'PH', 'PW', 'MP', 'FM', 'YT', 'MH', 'HT', 'GY', 'GU', 'GD', 'DM', 'CW', 'BQ', 'BZ', 'BS', 'AW', 'AG', 'AI', 'AS'];

const CreateAsset = () => {
    const { theme } = useContext(ThemeContext);
    const { assetsList, setAssetsList, setSelectedAsset, selectedDate, createdAssets, setCreatedAssets } = useContext(ManageFundContext)

    const [sameNameWarning, setSameNameWarning] = useState(null);
    const [formReady, setFormReady] = useState(false);
    const [assetName, setAssetName] = useState("");
    const [assetNameFilled, setAssetNameFilled] = useState(false)
    const [publicAsset, setPublicAsset] = useState(true);
    const [selectedInvestmentOption, setselectedInvestmentOption] = useState('');
    const [selectedAssetPublicPrivate, setSelectedAssetPublicPrivate] = useState('');
    const [assetType, setAssetType] = useState("")
    const [isin, setIsin] = useState("")
    const [isinFilledCorrectly, setIsinFilledCorrectly] = useState(false)
    const [ticker, setTicker] = useState("")
    const [tickerFilled, setTickerFilled] = useState(false)
    const [selectedCountries, setSelectedCountries] = useState([])


    useEffect(() => {
        if (assetNameFilled) {
            if (selectedInvestmentOption === 'sovereign_bond' || selectedInvestmentOption === 'supranational_bond') {
                if (selectedCountries?.length > 0 && tickerFilled && checkIsin()) {
                    setFormReady(true)
                } else {
                    setFormReady(false)
                }
            } else if (selectedInvestmentOption === 'investee_company') {
                if (selectedAssetPublicPrivate === 'public') {
                    if (tickerFilled && checkIsin()) {
                        setFormReady(true)
                    } else {
                        setFormReady(false)
                    }
                } else if (selectedAssetPublicPrivate === 'private') {
                    if (selectedCountries?.length > 0) {
                        setFormReady(true)
                    } else {
                        setFormReady(false)
                    }
                }

            } else if (selectedInvestmentOption === 'real_estate') {
                if (selectedCountries?.length > 0) {
                    setFormReady(true)
                } else {
                    setFormReady(false)
                }
            } else if (selectedInvestmentOption === 'reit') {
                if (selectedAssetPublicPrivate === 'public') {
                    if (tickerFilled && checkIsin()) {
                        setFormReady(true)
                    } else {
                        setFormReady(false)
                    }
                } else if (selectedAssetPublicPrivate === 'private') {
                    if (selectedCountries?.length > 0) {
                        setFormReady(true)
                    } else {
                        setFormReady(false)
                    }
                }
            } else if (selectedInvestmentOption === 'etf' || selectedInvestmentOption === 'uit' || selectedInvestmentOption === 'structured_product' || selectedInvestmentOption === 'mutual_fund' || selectedInvestmentOption === 'municipal_bond' || selectedInvestmentOption === 'mlp' || selectedInvestmentOption === 'high_yield_bond' || selectedInvestmentOption === 'forex' || selectedInvestmentOption === 'convertible_bond' || selectedInvestmentOption === 'asset_backed_securities' || selectedInvestmentOption === 'cash_equivalents' || selectedInvestmentOption === 'corporate_bond' || selectedInvestmentOption === 'closed_end_fund' || selectedInvestmentOption === 'commodity') {
                if (tickerFilled && checkIsin()) {
                    setFormReady(true)
                } else {
                    setFormReady(false)
                }
            }
        } else {
            setFormReady(false)
        }
    }, [selectedInvestmentOption, assetName, selectedAssetPublicPrivate, assetType, isin, ticker, selectedCountries]);


    function handleCreateAsset() {
        if (formReady) {
            const random_id = Math.floor(Math.random() * 1000000000)
            let newAsset = { value:random_id, label: assetName, created: true, assetName: assetName, investmentType: selectedInvestmentOption, assetType: assetType, isin: isin, ticker: ticker, countries: selectedCountries, currency: "", amount: "", share: "", public: publicAsset }
            let newAssetsList = [...assetsList, newAsset]
            newAssetsList = newAssetsList?.sort((a, b) => a?.label?.localeCompare(b?.label))
            setAssetsList(newAssetsList)
            setSelectedAsset(newAsset)
            const newArray = {
                ...createdAssets,
                [selectedDate]: [
                    {
                        value: random_id,
                        keySecondary:random_id,
                        label: assetName,
                        type: "",
                        currency: "",
                        amount: "",
                        share: "",
                        isin: isin,
                        ticker: ticker,
                        public_asset: publicAsset,
                        country: selectedCountries,
                        investment_type: selectedInvestmentOption
                    },
                    ...(createdAssets[selectedDate] || [])
                ]
            };
            setCreatedAssets(newArray);
        }
    }

    function handleSelectChange(selectedOption) {
        cleanAll()
        setselectedInvestmentOption(selectedOption?.value);
    };

    function checkIsin() {
        if (isin !== "") {
            if (isinFilledCorrectly) {
                return true
            } else {
                return false
            }
        } else {
            return true
        }
    }

    function cleanAll() {
        setFormReady(false)
        setAssetName("")
        setAssetNameFilled(false)
        setselectedInvestmentOption('')
        setSelectedAssetPublicPrivate('')
        setPublicAsset(true)
        setAssetType("")
        setIsin("")
        setTicker("")
        setTickerFilled(false)
        setIsinFilledCorrectly(false)
        setSelectedCountries([])
    }

    function handleAssetName(event) {
        const newValue = event
        setAssetName(newValue);
        if (assetsList?.some((dict) => dict?.label?.toLowerCase() === newValue?.toLowerCase())) {
            setSameNameWarning("Asset name already exists.")
            setAssetNameFilled(false)
        } else {
            setSameNameWarning(null)
            setAssetNameFilled(newValue !== "" && newValue?.length >= 3)
        }
    }

    function handleCountry(value) {
        setSelectedCountries([value?.label])
    }

    function handleSelectedCountries(value) {
        setSelectedCountries(value)
    }

    function removeSelectedCountries(option) {
        let new_options = selectedCountries
        setSelectedCountries(new_options?.filter(element => element.label !== option))
    }

    function handlePublicPrivate(e) {
        setAssetName("")
        setAssetNameFilled(false)
        setFormReady(false)
        setAssetType("")
        setIsin("")
        setTicker("")
        setTickerFilled(false)
        setIsinFilledCorrectly(false)
        setSelectedCountries([])
        setSelectedAssetPublicPrivate(e?.target?.value)
        setPublicAsset(e?.target?.value === 'public')
    }

    function handleISIN(e) {
        const input = e;
        const maxLength = 12;
        const isValid = /^([a-zA-Z]{0,2}|[a-zA-Z]{2}[a-zA-Z0-9]{0,9}|[a-zA-Z]{2}[a-zA-Z0-9]{9}[0-9]{0,1}|[a-zA-Z]{2}[a-zA-Z0-9]{9}[0-9]{1}\d)$/.test(input);
        if (isValid && input?.length <= maxLength) {
            setIsin(input?.toUpperCase());
            if (input?.length === maxLength) { setIsinFilledCorrectly(true) } else { setIsinFilledCorrectly(false) }
        }
    }

    function handleTicker(e) {
        const newValue = e
        setTicker(newValue?.toUpperCase());
        if (newValue?.length > 0) {
            setTickerFilled(true)
        } else {
            setTickerFilled(false)
        }
    }

    return (
        <div className="create-asset-fields">
            <FormField fieldName="Investment type" field={<NormalDropdown noOptionsMessage='No results match your search criteria.' list={investment_types} placeholder="Select an option" handleChange={handleSelectChange}
                components={{ Option: InputOptionSingleValue, Control: BackgroundSearchBarControlReactSelect }} borderStatus={`${selectedInvestmentOption !== '' ? "var(--main-green)" : "red"}`} />} />
            <div className="optional-fields-div">
                {(selectedInvestmentOption === 'sovereign_bond' || selectedInvestmentOption === 'supranational_bond') && <>
                    {sameNameWarning && <FormField fieldName="" field={<p className="font-style-montserrat-family-regular extra-small-text color-red">{sameNameWarning}</p>} />}
                    <FormField fieldName="Asset name" field={<NormalInput placeholder="Asset name" extraClasses={`${assetNameFilled ? "outline-green" : "outline-red"} margin-top-1`} type="text" text={assetName} setText={handleAssetName} autoFocus={true} required={true} />} />
                    <FormField fieldName="ISIN" field={<NormalInput type="text" extraClasses={`${isin !== "" ? (isinFilledCorrectly ? "outline-green" : "outline-red") : "outline-lightgray"} margin-top-1`} placeholder="XX-00000000-0" text={isin} setText={handleISIN} />} />
                    <FormField fieldName="Ticker" field={<NormalInput type="text" extraClasses={`${tickerFilled ? "outline-green" : "outline-red"} margin-top-1`} placeholder="XXXX" text={ticker} setText={handleTicker} />} />
                    <FormField fieldName="Country(ies)" field={<NormalDropdown extraClasses="margin-top-1" closeMenuOnSelect={false} hideSelectedOptions={false} isMulti={true} controlShouldRenderValue={false} placeholder="Select country(ies)" noOptionsMessage='No results match your searching criteria.'
                        list={countries} value={selectedCountries} handleChange={handleSelectedCountries} components={{ Option: CheckboxOptionReactSelect, Control: NormalControlReactSelect }} borderStatus={`${selectedCountries.length > 0 ? "var(--main-green)" : "red"}`} />} />
                    {selectedCountries.length > 0 && <FormField fieldName="" field={<div className="margin-top-1 is-multi-selected-options-div">
                        {
                            selectedCountries?.map((item) => (
                                <IsMultiOptionBox key={item.value} option={item.label} onClickAction={removeSelectedCountries} />
                            ))}
                    </div>} />}
                </>}
                {selectedInvestmentOption === 'investee_company' && (<>
                    <FormField fieldName="" field={<div className="checkboxes-div margin-top-1 margin-bottom-1">
                        <CheckboxField text="Public" infoBoolean={false} ><RadioInput name="asset_type" value="public" handleChange={handlePublicPrivate} /></CheckboxField>
                        <CheckboxField text="Private" infoBoolean={false} ><RadioInput name="asset_type" value="private" handleChange={handlePublicPrivate} /></CheckboxField>
                    </div>} />
                    {selectedAssetPublicPrivate === 'public' &&
                        (<>
                            {sameNameWarning && <FormField fieldName="" field={<p className="font-style-montserrat-family-regular extra-small-text color-red">{sameNameWarning}</p>} />}
                            <FormField fieldName="Asset name" field={<NormalInput placeholder="Asset name" extraClasses={`${assetNameFilled ? "outline-green" : "outline-red"} margin-top-1`} type="text" text={assetName} setText={handleAssetName} autoFocus={true} required={true} />} />
                            <FormField fieldName="ISIN" field={<NormalInput type="text" extraClasses={`${isin !== "" ? (isinFilledCorrectly ? "outline-green" : "outline-red") : "outline-lightgray"} margin-top-1`} placeholder="XX-00000000-0" text={isin} setText={handleISIN} />} />
                            <FormField fieldName="Ticker" field={<NormalInput type="text" extraClasses={`${tickerFilled ? "outline-green" : "outline-red"} margin-top-1`} placeholder="XXXX" text={ticker} setText={handleTicker} />} />
                        </>)}
                    {selectedAssetPublicPrivate === 'private' &&
                        (<>
                            {sameNameWarning && <FormField fieldName="" field={<p className="font-style-montserrat-family-regular extra-small-text color-red">{sameNameWarning}</p>} />}
                            <FormField fieldName="Asset name" field={<NormalInput placeholder="Asset name" extraClasses={`${assetNameFilled ? "outline-green" : "outline-red"} margin-top-1`} type="text" text={assetName} setText={handleAssetName} autoFocus={true} required={true} />} />
                            <FormField fieldName="Country" field={<NormalDropdown noOptionsMessage='No results match your search criteria.' extraClasses="margin-top-1" list={countries} placeholder="Select a country" handleChange={handleCountry}
                                components={{ Option: InputOptionSingleValue, Control: BackgroundSearchBarControlReactSelect }} borderStatus={`${selectedCountries.length === 1 ? "var(--main-green)" : "red"}`} />} />
                            <FormField fieldName="Form link" field={<a href="http://private-companies-investee-companies-form-bucket.s3-website.eu-west-2.amazonaws.com" target="_blank" className="margin-top-1 font-style-montserrat-family-regular extra-small-text color-white">http://private-companies-investee-companies-form-bucket.s3-website.eu-west-2.amazonaws.com</a>} />
                            <FormField fieldName="" field={<p className="margin-top-1 font-style-montserrat-family-regular extra-small-text color-orange">Share this form link with the private company so that it can be filled.</p>} />
                        </>)}
                </>
                )}
                {selectedInvestmentOption === 'real_estate' && <>
                    {sameNameWarning && <FormField fieldName="" field={<p className="font-style-montserrat-family-regular extra-small-text color-red">{sameNameWarning}</p>} />}
                    <FormField fieldName="Asset name" field={<NormalInput placeholder="Asset name" extraClasses={`${assetNameFilled ? "outline-green" : "outline-red"} margin-top-1`} type="text" text={assetName} setText={handleAssetName} autoFocus={true} required={true} />} />
                    <FormField fieldName="Country" field={<NormalDropdown noOptionsMessage='No results match your search criteria.' extraClasses="margin-top-1" list={countries} placeholder="Select a country" handleChange={handleCountry}
                        components={{ Option: InputOptionSingleValue, Control: BackgroundSearchBarControlReactSelect }} borderStatus={`${selectedCountries.length === 1 ? "var(--main-green)" : "red"}`} />} />
                    <FormField fieldName="Form link" field={<a href="http://private-companies-real-estate-form-bucket.s3-website.eu-west-2.amazonaws.com" target="_blank" className="margin-top-1 font-style-montserrat-family-regular extra-small-text color-white">http://private-companies-real-estate-form-bucket.s3-website.eu-west-2.amazonaws.com</a>} />
                    <FormField fieldName="" field={<p className="margin-top-1 font-style-montserrat-family-regular extra-small-text color-orange">Share this form link with the private company so that it can be filled.</p>} />
                </>}
                {selectedInvestmentOption === 'reit' && (<>
                    <FormField fieldName="" field={<div className="checkboxes-div margin-top-1 margin-bottom-1">
                        <CheckboxField text="Public" infoBoolean={false} ><RadioInput name="asset_type" value="public" handleChange={handlePublicPrivate} /></CheckboxField>
                        <CheckboxField text="Private" infoBoolean={false} ><RadioInput name="asset_type" value="private" handleChange={handlePublicPrivate} /></CheckboxField>
                    </div>} />
                    {selectedAssetPublicPrivate === 'public' &&
                        (<>
                            {sameNameWarning && <FormField fieldName="" field={<p className="font-style-montserrat-family-regular extra-small-text color-red margin-top-1">{sameNameWarning}</p>} />}
                            <FormField fieldName="Asset name" field={<NormalInput placeholder="Asset name" extraClasses={`${assetNameFilled ? "outline-green" : "outline-red"} margin-top-1`} type="text" text={assetName} setText={handleAssetName} autoFocus={true} required={true} />} />
                            <FormField fieldName="ISIN" field={<NormalInput type="text" extraClasses={`${isin !== "" ? (isinFilledCorrectly ? "outline-green" : "outline-red") : "outline-lightgray"} margin-top-1`} placeholder="XX-00000000-0" text={isin} setText={handleISIN} />} />
                            <FormField fieldName="Ticker" field={<NormalInput type="text" extraClasses={`${tickerFilled ? "outline-green" : "outline-red"} margin-top-1`} placeholder="XXXX" text={ticker} setText={handleTicker} />} />
                        </>)}
                    {selectedAssetPublicPrivate === 'private' &&
                        (<>
                            {sameNameWarning && <FormField fieldName="" field={<p className="font-style-montserrat-family-regular extra-small-text color-red">{sameNameWarning}</p>} />}
                            <FormField fieldName="Asset name" field={<NormalInput placeholder="Asset name" extraClasses={`${assetNameFilled ? "outline-green" : "outline-red"} margin-top-1`} type="text" text={assetName} setText={handleAssetName} autoFocus={true} required={true} />} />
                            <FormField fieldName="Country" field={<NormalDropdown noOptionsMessage='No results match your search criteria.' extraClasses="margin-top-1" list={countries} placeholder="Select a country" handleChange={handleCountry}
                                components={{ Option: InputOptionSingleValue, Control: BackgroundSearchBarControlReactSelect }} borderStatus={`${selectedCountries.length === 1 ? "var(--main-green)" : "red"}`} />} />
                            <FormField fieldName="Form link" field={<a href="http://private-companies-real-estate-form-bucket.s3-website.eu-west-2.amazonaws.com" target="_blank" className="font-style-montserrat-family-regular extra-small-text color-white margin-top-1">http://private-companies-real-estate-form-bucket.s3-website.eu-west-2.amazonaws.com</a>} />
                            <FormField fieldName="" field={<p className="font-style-montserrat-family-regular extra-small-text color-orange margin-top-1">Share this form link with the private company so that it can be filled.</p>} />
                        </>)}
                </>
                )}
                {(selectedInvestmentOption === 'etf' || selectedInvestmentOption === 'uit' || selectedInvestmentOption === 'structured_product' || selectedInvestmentOption === 'mutual_fund' || selectedInvestmentOption === 'municipal_bond' || selectedInvestmentOption === 'mlp' || selectedInvestmentOption === 'high_yield_bond' || selectedInvestmentOption === 'forex' || selectedInvestmentOption === 'convertible_bond' || selectedInvestmentOption === 'asset_backed_securities' || selectedInvestmentOption === 'cash_equivalents' || selectedInvestmentOption === 'corporate_bond' || selectedInvestmentOption === 'closed_end_fund' || selectedInvestmentOption === 'commodity') && (
                    <>
                        {sameNameWarning && <FormField fieldName="" field={<p className="font-style-montserrat-family-regular extra-small-text color-red">{sameNameWarning}</p>} />}
                        <FormField fieldName="Asset name" field={<NormalInput placeholder="Asset name" extraClasses={`${assetNameFilled ? "outline-green" : "outline-red"} margin-top-1`} type="text" text={assetName} setText={handleAssetName} autoFocus={true} required={true} />} />
                        <FormField fieldName="ISIN" field={<NormalInput type="text" extraClasses={`${isin !== "" ? (isinFilledCorrectly ? "outline-green" : "outline-red") : "outline-lightgray"} margin-top-1`} placeholder="XX-00000000-0" text={isin} setText={handleISIN} />} />
                        <FormField fieldName="Ticker" field={<NormalInput type="text" extraClasses={`${tickerFilled ? "outline-green" : "outline-red"} margin-top-1`} placeholder="XXXX" text={ticker} setText={handleTicker} />} />
                    </>
                )}
            </div>
            <BorderTextFullWidthButton text="Create" extraClasses={`${formReady ? "button-hover" : "button-disabled"} margin-top-3`} clickAction={handleCreateAsset} disabled={!formReady} />
        </div>
    );
}

export default CreateAsset;