import React, { useState, useEffect, useRef } from "react";

import { BsChevronDown } from "react-icons/bs";

import "./MainDescriptionCard.scss"

import { CardProperties, NoBorderIconButton } from "../../../assets/styles/UIKit/UIKit";

const MainDescriptionCard = ({ title, description, cardType }) => {
    const [showMore, setShowMore] = useState(false);
    const textRef = useRef(null);

    useEffect(() => {
        if (textRef.current && textRef.current.scrollHeight < 135) {
          setShowMore(true);
        }
      }, [description]);

    return (
        <CardProperties className={`CardProperties main-description-card ${cardType}`}>
            <p className="margin-bottom-2 small-text font-style-montserrat-family-semibold color-white">{title}</p>
            <p ref={textRef} className={`${showMore && "show-more"} description extra-small-text font-style-montserrat-family-regular`}>
                {description}
            </p>
            {!showMore && 
            <NoBorderIconButton extraClasses="margin-center" icon={<BsChevronDown className="icon small-text color-white" />} clickAction={() => setShowMore(!showMore)} />
            }
        </CardProperties>
    );
}

export default MainDescriptionCard;