import React, { useState, useContext } from "react";
import ThemeContext, { ThemeVariables } from "../../../assets/styles/darkLightMode/theme-context";

import { BarChart, Bar, XAxis, YAxis, Legend, ResponsiveContainer, Rectangle } from 'recharts';
import { AiOutlineExpand } from "react-icons/ai";
import ModalWindow from "../../ModalWindow/ModalWindow";
import { FlexBlockDiv } from "../../../assets/styles/global";
import { CardProperties, NoBorderIconButton } from "../../../assets/styles/UIKit/UIKit";

const FullWidthBarChartCard = (props) => {
    const { title, data, cardType, modal } = props;
    const { theme, setTheme } = useContext(ThemeContext);

    const [isModalOpen, setisModalOpen] = useState(false);

    const ticks = [0, 0.25, 0.5, 0.75, 1];
    const labelFormatter = (x) => {
        return ((x / 1) * 100).toFixed(1).replace(/[.,]0/, "") + '%'
    };

    return (
        <>
            <CardProperties className={`${!modal && 'CardProperties'} ${cardType}`}>
            {!modal &&
                    <FlexBlockDiv>
                        <p className="font-style-montserrat-family-regular extra-small-text color-white">{title}</p>
                        <NoBorderIconButton clickAction={() => setisModalOpen(!isModalOpen)} icon={<AiOutlineExpand className="icon small-text color-lightgray" />} />
                    </FlexBlockDiv>}
                <ResponsiveContainer width={"100%"} height={"100%"} className="extra-extra-small-text font-style-montserrat-family-regular">
                    <BarChart barCategoryGap="0%" data={data} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                        <XAxis dataKey="name" hide/>
                        <YAxis ticks={ticks} tickCount={25} tickFormatter={(tick) => { return `${(tick * 100)}%` }} hide />
                        <Legend wrapperStyle={{whiteSpace:"break-spaces", paddingTop: 10}} iconType="square" formatter={(value) => <span className="legend-items-piechart font-style-montserrat-family-regular color-white">{value}</span>} layout="horizontal" verticalAlign="bottom" align="center" />
                        <Bar shape={ <Rectangle radius={[0, 0, 3, 3]} /> } animationDuration={800} stackId="a" name="Taxonomy - Eligible" dataKey="eligible" fill="#0071dc" minPointSize={1} label={{ fill: `${ThemeVariables[theme]?.extreme_color}`, formatter: labelFormatter, position: "middle" }}></Bar>
                        <Bar shape={ <Rectangle radius={[3, 3, 0, 0]} /> } animationDuration={800} stackId="a" name="Taxonomy - Non-Eligible" dataKey="non_eligible"  fill="#35D272" minPointSize={1} label={{ fill: `${ThemeVariables[theme]?.extreme_color}`, formatter: labelFormatter, position: "middle" }}></Bar>
                    </BarChart>
                </ResponsiveContainer>
            </CardProperties>
            <ModalWindow title={title} sizeClass="charts-visualization" open={isModalOpen} setOpen={setisModalOpen}>
                <FullWidthBarChartCard title={title} data={data} cardType="modal-card" modal={true}></FullWidthBarChartCard>
            </ModalWindow>
        </>
    );
}

export default FullWidthBarChartCard;