import { createContext } from 'react';

const ThemeContext = createContext({
    theme: 'dark',
    setTheme: (theme) => { }
});

export default ThemeContext;

export const ThemeVariables = {
    "light": {
        "secondary_background": "var(--gray-1)",
        "card": "var(--gray-2)",
        "card_secondary": "var(--gray-5)",
        "main_text_color": "var(--gray-11)",
        "secondary_text_color": "var(--gray-7)",
        "translucid_hover": "var(--gray-4)",
        "contrast_color": "var(--gray-9)",
        "extreme_color": "var(--gray-11)",
        "contrast_text_color": "var(--gray-6)",
        "lines": "var(--gray-5)",
        "left_menu_background": "var(--white)",
        "textarea": "var(--gray-3)",
        "info_card_text": "var(--gray-6)",
        "button_color": "var(--gray-5)",
        "no_data_color": "var(--gray-3)",
        "input": "var(--gray-1)",
        "input_disabled": "var(--gray-4)", 
        "menu_list_background": "var(--gray-2)"
    },
    "dark": {
        "secondary_background": "var(--gray-11)",
        "card": "var(--gray-10)",
        "card_secondary": "var(--gray-6)",
        "main_text_color": "var(--white)",
        "secondary_text_color": "var(--gray-5)",
        "translucid_hover": "var(--gray-8)",
        "contrast_color": "var(--gray-4)",
        "extreme_color": "var(--white)",
        "contrast_text_color": "var(--gray-4)",
        "lines": "var(--gray-7)",
        "left_menu_background": "var(--gray-9)",
        "textarea": "var(--gray-6)",
        "info_card_text": "var(--gray-2)",
        "button_color": "var(--gray-3)",
        "no_data_color": "var(--gray-9)",
        "input": "var(--gray-2)",
        "input_disabled": "var(--gray-5)",
        "menu_list_background": "var(--gray-5)"
    }
};
