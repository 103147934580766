import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import { BsChevronDown } from "react-icons/bs";

const LeftSlideMenuItem = (props) => {
    const { item, isOpened, onClickSubnav, subItem, closeMenu } = props;
    const [openedSubMenuIndex, setOpenedSubMenuIndex] = useState(null);

    if (item?.childrens) {
        return (
            <div className={isOpened ? "left-slide-menu-item-main-div open" : "left-slide-menu-item-main-div"}>
                <button className={`${subItem ? "menu-sub-item-text font-style-montserrat-family-regular" : "menu-item-text font-style-montserrat-family-regular"} left-slide-menu-list-item extra-small-text color-white`} onClick={onClickSubnav}>{item?.title} <BsChevronDown className={`${isOpened ? "icon-close" : "icon-open"}`}></BsChevronDown></button>
                <div className="left-slide-menu-list-subitem extra-extra-small-text">
                    {item?.childrens.map((child, index) => <LeftSlideMenuItem key={index} item={child} subItem={true} closeMenu={closeMenu} isOpened={openedSubMenuIndex === index} onClickSubnav={() => {openedSubMenuIndex === index ? setOpenedSubMenuIndex(null) : setOpenedSubMenuIndex(index)}}></LeftSlideMenuItem>)}
                </div>
            </div>
        );
    } else {
        return (
            <NavLink onClick={() => closeMenu()} to={item?.path || "#"} className="left-slide-menu-list-item plain color-white">
            <div className="left-slide-menu-item-main-div">
                <button className="menu-sub-item-text left-slide-menu-list-item font-style-montserrat-family-regular extra-small-text">{item?.title}</button>
            </div>
            </NavLink>
        );
    }
}

export default LeftSlideMenuItem;