import React, { useContext } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ReferenceLine, ResponsiveContainer } from 'recharts';

import ThemeContext, { ThemeVariables } from "../../../assets/styles/darkLightMode/theme-context";
import { CardProperties } from "../../../assets/styles/UIKit/UIKit";

import { convertToCurrency, convertToBigNumbers, roundStringToTwoDecimalPlaces } from '../../../utils/Maths/MathsFunctions';

import "./OneOrTwoValueCard.scss"
import { ImArrowDown2, ImArrowUp2 } from "react-icons/im";

const OneOrTwoValueCard = ({ data, type, text, cardType }) => {
    const { theme, setTheme } = useContext(ThemeContext);

    return (
        <CardProperties className={`CardProperties one-or-two-value-card ${cardType} small-text font-style-montserrat-family-regular color-white`}>
            <p className="two-value-card-label-label-top">{text}</p>
            {
                data === null || data === undefined ?
                    <div className="no-value-card-value-label-to"></div>
                    :
                    type === 'twoValuesWithLabel' ?
                        <div className="two-values-card-label">
                            {data?.value_public !== 0 &&
                                <div className="vertical-div">
                                    <p className="two-value-card-value-label-top font-style-montserrat-family-semibold extra-large-text">{data?.value_public}</p>
                                    <p className="label font-style-montserrat-family-semibold extra-small-text color-lightgray">Public</p>
                                </div>
                            }
                            {data?.value_private !== 0 &&
                                <div className="vertical-div">
                                    <p className="two-value-card-value-label-top font-style-montserrat-family-semibold extra-large-text">{data?.value_private}</p>
                                    <p className="label font-style-montserrat-family-semibold extra-small-text color-lightgray">Private</p>
                                </div>
                            }
                        </div>
                        :
                        type === 'twoValuesWithArrows' ?
                            <div className="two-values-card-label">
                                {data?.value_public !== 0 &&
                                    <div className="horizontal-div">
                                        <ImArrowUp2 className="large-text color-red" />
                                        <p className="two-value-card-value-label-top font-style-montserrat-family-semibold extra-large-text">{data?.highest_value}</p>
                                    </div>
                                }
                                {data?.value_private !== 0 &&
                                    <div className="horizontal-div">
                                        <ImArrowDown2 className="large-text color-green" />
                                        <p className="two-value-card-value-label-top font-style-montserrat-family-semibold extra-large-text">{data?.lowest_value}</p>
                                    </div>
                                }
                            </div>
                            :
                            <div className="two-values-card-label">
                                <div className="vertical-div">
                                    <p className="two-value-card-value-label-top font-style-montserrat-family-semibold extra-large-text">{
                                        type === 'percentage' ?
                                            data < 0.000001 ?
                                                "<0.001%"
                                                :
                                                (data * 100)?.toFixed(3) + "%"
                                            :
                                            type === 'oneValue' ?
                                                text === 'Country' ?
                                                    <>
                                                        {data[1]}
                                                        {data[0]}
                                                    </>
                                                    :
                                                    convertToBigNumbers(data)
                                                :
                                                type === 'currency' ?
                                                    '€ ' + convertToCurrency(data)
                                                    :
                                                    type === 'greenRedPercentage' &&
                                                    <>
                                                        {data < 0 ?
                                                            <ImArrowDown2 className="large-text color-green" />
                                                            :
                                                            <ImArrowUp2 className="large-text color-red" />
                                                        }
                                                        {roundStringToTwoDecimalPlaces(data) + '%'}
                                                    </>
                                    }</p>
                                </div>
                            </div>
            }
        </CardProperties>
    );
}

export default OneOrTwoValueCard;