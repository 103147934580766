
import React, { useContext, useState, useRef, useEffect } from "react";

import "../MultiStep.scss"
import { IoChevronDown } from "react-icons/io5";

import { putPAIInput } from "../../../../utils/requests/putRequests";
import { GeneralContext } from "../../../../utils/functions_variables/variables";
import { getPAIInputFieldsData } from "../../../../utils/requests/getRequests";

import LocalLoader from "../../../LocalLoader/LocalLoader";
import { BorderIconTextFullWidthButton, BorderTextButton } from "../../../../assets/styles/UIKit/UIKit";

const StepThree = ({ selectedFund, step, stepsInfo, setStepsInfo }) => {
    const { databaseAddress, setWarningMessage } = useContext(GeneralContext)
    const contentRef = useRef(null);
    const [isLoading, setIsLoading] = useState(true)
    const [articlesOpen, setArticlesOpen] = useState(false);
    const [height, setHeight] = useState(0);
    const textareaRef = React.useRef(null);
    const [valueText, setValueText] = useState("");
    const [buttonEnable, setButtonEnable] = useState(false)

    useEffect(() => {
        if (contentRef?.current) {
            setHeight(contentRef.current.scrollHeight);
        }
    }, [articlesOpen]);

    const toggleShowArticles = () => {
        setArticlesOpen(!articlesOpen);
    }

    const handleChangeText = (evt) => {
        const val = evt?.target?.value;
        setValueText(val);
    };

    function checkFields() {
        if (valueText !== '') {
            setButtonEnable(true)
        } else { setButtonEnable(false) }
    }

    useEffect(() => {
        checkFields()
    }, [valueText]);

    function cleanFields() {
        setValueText("")
    }

    const getInputFieldsData = async () => {
        setIsLoading(true)
        await getPAIInputFieldsData(databaseAddress, selectedFund?.label, "step3", 3).then(data => {
            if (Object.keys(data)?.length === 0) {
                cleanFields()
            } else {
                if (data?.step3) { setValueText(data?.step3); }
                let stepFilled = false
                if (data?.step3 !== '') {
                    stepFilled = true
                }
                setStepsInfo(prevState => ({ ...prevState, stepThree: { filled: stepFilled } }))
            }
            setIsLoading(false)
        }).catch(error => {
            cleanFields()
            setIsLoading(false)
            setWarningMessage(["error", error.message])
        })
    }

    useEffect(() => {
        getInputFieldsData()
    }, [selectedFund])

    const handleSaveStep = async (e) => {
        e.preventDefault();
        if (buttonEnable) {
            setIsLoading(true)
            const bodyContent = JSON.stringify({ client: databaseAddress, fundName: selectedFund?.label, insertValue: { step3: valueText } })
            await putPAIInput(bodyContent, 3).then(data => {
                getInputFieldsData()
                setStepsInfo(prevState => ({ ...prevState, stepThree: { filled: true } }))
                setIsLoading(false)
                setWarningMessage(["success", "Content saved successfully."])
            }).catch(error => {
                setIsLoading(false)
                setWarningMessage(["error", error.message])
            })
        }
    }

    if (isLoading) {
        return <LocalLoader></LocalLoader>;
    }

    return (
        <div style={{ display: step === 3 ? 'flex' : 'none' }} className="steps-main-div color-white">
            <div className="articles">
            <BorderIconTextFullWidthButton text="Articles" icon={<IoChevronDown className={`icon ${articlesOpen && "close-180-deg"}`} />} clickAction={toggleShowArticles} />
                <div ref={contentRef} className="articles-div" style={{ height: articlesOpen ? `${height}px` : '0', marginBottom: articlesOpen ? `15px` : '0' }}>
                    <p className="title font-style-montserrat-family-semibold extra-small-text">Article 7 - Description of policies to identify and prioritise principal adverse impacts of investment decisions on sustainability factors</p>
                    <p className="text font-style-montserrat-family-regular extra-small-text "><label className="font-style-montserrat-family-semibold">1. </label>In the section ‘Description of policies to identify and prioritise principal adverse impacts on sustainability factors’ in Table 1 of Annex I, financial market participants shall describe their policies to identify and prioritise principal adverse impacts on sustainability factors and how those policies are kept up to date and applied, including all of the following:<br />
                        <label className="font-style-montserrat-family-semibold">(a)</label> the date on which the governing body of the financial market participant approved those policies;<br />
                        <label className="font-style-montserrat-family-semibold">(b)</label> how the responsibility for the implementation of those policies within organisational strategies and procedures is allocated;<br />
                        <label className="font-style-montserrat-family-semibold">(c)</label> the methodologies to select the additional indicators (previous slides) and to identify and assess the PAI disclosed in the previous section, and in particular an explanation of how those methodologies consider the probability of occurrence and the severity of those principal adverse impacts, including their potentially irremediable character;<br />
                        <label className="font-style-montserrat-family-semibold">(d)</label> any associated margin of error within the methodologies referred to in point (c), with an explanation of that margin;<br />
                        <label className="font-style-montserrat-family-semibold">(e)</label> the data sources used.</p>
                    <p className="text font-style-montserrat-family-regular extra-small-text "><label className="font-style-montserrat-family-semibold">2. </label>Where information relating to any of the indicators used is not readily available, financial market participants shall include in the section ‘Description of policies to identify and prioritise principal adverse impacts on sustainability factors’ in Table 1 of Annex I details of the best efforts used to obtain the information either directly from investee companies, or by carrying out additional research, cooperating with third party data providers or external experts or making reasonable assumptions.</p>
                </div>
            </div>
            <div className="step-three-four-five margin-top-2 margin-bottom-3">
                <textarea rows={1} onChange={handleChangeText} ref={textareaRef}
                    type="text" className="first-input font-style-montserrat-family-regular extra-extra-small-text color-white"
                    value={valueText}
                    placeholder={valueText === "" ? "Description of policies to identify and prioritise PAI on sustainability factors" : ""} />
                <BorderTextButton text="Save" extraClasses={`${buttonEnable ? "button-hover" : "button-disabled"} margin-top-2`} clickAction={handleSaveStep} type="submit" disabled={!buttonEnable} />
            </div>
        </div>
    );
}

export default StepThree;


