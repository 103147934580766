import React, { useState, useContext } from "react";

import "../OverviewFundContent.scss"

import { OverviewContext } from "../../../utils/functions_variables/variables";

import { Tooltip } from 'recharts';

import HeaderTitle from "../../HeaderTitle/HeaderTitle"
import LineChartGraphCard from "../../DataVisualization/LineChartGraphCard/LineChartGraphCard"
import MultipleLinesChartWithThresholdCard from "../../DataVisualization/MultipleLinesChartWithThresholdCard/MultipleLinesChartWithThresholdCard";
import HorizontalBarChartCard from "../../DataVisualization/HorizontalBarChartCard/HorizontalBarChartCard";
import ChoroplethMapWorldCard from "../../DataVisualization/ChoroplethMapWorldCard/ChoroplethMapWorldCard";
import RankingDotsTwoCategoriesChartCard from "../../DataVisualization/RankingDotsTwoCategoriesChartCard/RankingDotsTwoCategoriesChartCard";
import HorizontalBarChartPercentageCard from "../../DataVisualization/HorizontalBarChartPercentageCard/HorizontalBarChartPercentageCard";
import { AmountTooltip } from "../../DataVisualization/Tooltips";

const OverviewEnvironmental = () => {
  const { overviewEnvironmentalData } = useContext(OverviewContext)

  return (
    <div className="overview-content">
      <HeaderTitle text="General metrics"></HeaderTitle>
      <MultipleLinesChartWithThresholdCard title="Total emissions" linesKeys={[{ label: "Scope 1", value: "scope1" }, { label: "Scope 2", value: "scope2" }, { label: "Scope 3", value: "scope3" }, { label: "Total", value: "total" }]} cardType="medium-chart-card-size card-height-2 card-height-3" multipleLines={true} data={overviewEnvironmentalData?.totalEmissionData?.sort((a, b) => a.year - b.year)} />
      <LineChartGraphCard title="CO2 emissions intensity (per EUR invested)" cardType="medium-chart-card-size card-height-2 card-height-3" multipleLines={true} data={overviewEnvironmentalData?.emissionsIntensityPerEurInvested?.sort((a, b) => a.year - b.year)} />
      <LineChartGraphCard title="CO2 emissions intensity (per revenue)" cardType="medium-chart-card-size card-height-2 card-height-3" multipleLines={true} data={overviewEnvironmentalData?.emissionsIntensityPerRevenue?.sort((a, b) => a.year - b.year)} />
      <ChoroplethMapWorldCard title="CO2 emissions per Country" cardType="medium-chart-card-size card-height-2 card-height-3" data={overviewEnvironmentalData?.co2PerCountry}></ChoroplethMapWorldCard>
      <HorizontalBarChartPercentageCard title="CO2 emissions per Asset type" data={overviewEnvironmentalData?.co2PerAssetType} dataKey="percentage" yAxisLabel="label" categoryName="Emissions" cardType="medium-chart-card-size card-height-2 card-height-3">
        <Tooltip wrapperStyle={{ outline: 'none' }} content={<AmountTooltip />} shared={false} />
      </HorizontalBarChartPercentageCard>
      <HorizontalBarChartPercentageCard title="CO2 emissions per Industry" data={overviewEnvironmentalData?.co2PerIndustry} dataKey="percentage" yAxisLabel="label" categoryName="Emissions" cardType="medium-chart-card-size card-height-2 card-height-3">
        <Tooltip wrapperStyle={{ outline: 'none' }} content={<AmountTooltip />} shared={false} />
      </HorizontalBarChartPercentageCard>
      <HorizontalBarChartCard title="Fund ranking per CO2 emissions (per EUR invested)" showLegend={false} data={overviewEnvironmentalData?.rankingFundsPerEurInvested} dataKey="value" yAxisLabel="label" categoryName="Emissions" cardType="medium-chart-card-size card-height-2 card-height-3" />
      <HorizontalBarChartCard title="Fund ranking per CO2 emissions (per revenue)" showLegend={false} data={overviewEnvironmentalData?.rankingFundsPerRevenue} dataKey="value" yAxisLabel="label" categoryName="Emissions" cardType="medium-chart-card-size card-height-2 card-height-3" />
      <RankingDotsTwoCategoriesChartCard title="Top 5 best and worst emitters (rate per EUR invested)" data={overviewEnvironmentalData?.bestAndWorstAssetsPerEurInvested} cardType="medium-chart-card-size card-height-2 card-height-3" />
      <RankingDotsTwoCategoriesChartCard title="Top 5 best and worst emitters (rate per revenue)" data={overviewEnvironmentalData?.bestAndWorstAssetsPerRevenue} cardType="medium-chart-card-size card-height-2 card-height-3" />
      <RankingDotsTwoCategoriesChartCard title="Top 3 best and worst industries (rate per EUR invested)" data={overviewEnvironmentalData?.bestAndWorstIndustriesPerEurInvested} cardType="medium-chart-card-size card-height-2 card-height-3" />
      <RankingDotsTwoCategoriesChartCard title="Top 3 best and worst industries (rate per revenue)" data={overviewEnvironmentalData?.bestAndWorstIndustriesPerRevenue} cardType="medium-chart-card-size card-height-2 card-height-3" />
    </div>
  );
}

export default OverviewEnvironmental;