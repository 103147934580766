import React from "react";
import { convertToInternationalCurrencySystem } from "../../../utils/Maths/MathsFunctions";

import "./BigOneValueCard.scss"

import { CardProperties } from "../../../assets/styles/UIKit/UIKit";

const BigOneValueCard = ({ title, data, cardType }) => {


    return (
        <CardProperties className={`CardProperties ${cardType} color-white`}>
            <p className="font-style-montserrat-family-regular extra-small-text">{title}</p>
            <div className="big-one-value-card-value-div font-style-montserrat-family-semibold large-text">
                <div className="ring">
                    €{convertToInternationalCurrencySystem(data, 3)}
                </div>
            </div>
        </CardProperties>
    );
}

export default BigOneValueCard;

