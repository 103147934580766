import React, { useState, useEffect, useRef, useContext } from "react";

import "../Regulations.scss"

import HeaderTitle from "../../../components/HeaderTitle/HeaderTitle";
import LocalLoader from "../../../components/LocalLoader/LocalLoader";
import MainTitleTransparentCard from "../../../components/Cards/MainTitleTransparentCard/MainTitleTransparentCard";
import PieChartPercentageCard from "../../../components/DataVisualization/PieChartPercentageCard/PieChartPercentageCard";
import BarChartPercentageThreeBarsCard from "../../../components/DataVisualization/BarChartPercentageThreeBarsCard/BarChartPercentageThreeBarsCard";
import HorizontalBarChartPercentageCard from "../../../components/DataVisualization/HorizontalBarChartPercentageCard/HorizontalBarChartPercentageCard";
import DoubleTextCard from "../../../components/Cards/DoubleTextCard/DoubleTextCard";
import FilterFieldCard from "../../../components/Cards/FilterFieldCard/FilterFieldCard";

import { getEUTaxonomyAlignment } from "../../../utils/requests/getRequests";
import { COLORS_1, COLORS_2 } from "../../../assets/styles/global";
import { GeneralContext } from "../../../utils/functions_variables/variables";

const InvestmentFundAlignment = () => {
  const { setWarningMessage } = useContext(GeneralContext)
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true)
  const dataFetchedRef = useRef(false);

  useEffect(() => {
    if (dataFetchedRef?.current) return;
    dataFetchedRef.current = true;

    const EUTaxonomyAlignment = async () => {
      setIsLoading(true)
      await getEUTaxonomyAlignment().then(data => {
        setData(data);
        setIsLoading(false)
      }).catch(error => {
        setIsLoading(false)
        setWarningMessage(["error", error.message])
      })
    }

    EUTaxonomyAlignment()
  }, [])

  if (isLoading) {
    return <LocalLoader></LocalLoader>;
  }

  return (
    <div className="regulations-content">
      <HeaderTitle text="EU Taxonomy"></HeaderTitle>
      <FilterFieldCard title="Choose Investment Fund" data={data?.portfolios_turnover_alignment_percentage} listContent={data?.funds} cardType="one-third-chart-card-size"></FilterFieldCard>
      <FilterFieldCard title="Choose Asset Type" data={data?.portfolios_capex_alignment_percentage} listContent={data?.asset_types} cardType="one-third-chart-card-size"></FilterFieldCard>
      <FilterFieldCard title="Choose Sector" data={data?.portfolios_opex_alignment_percentage} listContent={data?.sectors} cardType="one-third-chart-card-size"></FilterFieldCard>
      <BarChartPercentageThreeBarsCard title="Alignment Metrics % by Investment Fund" data={data?.aligned_metrics_percentage_by_investment_fund} cardType="big-big-chart-card-size"></BarChartPercentageThreeBarsCard>
      <MainTitleTransparentCard text="Turnover Metric Analysis"></MainTitleTransparentCard>
      <HorizontalBarChartPercentageCard title="Total Alignment Turnover % by Investment Fund" data={data?.total_alignment_turnover_percentage_by_investment_fund} dataKey="eligible" yAxisLabel="name" showLegend={true} categoryName="Taxonomy - Eligible" cardType="medium-chart-card-size card-height-3"></HorizontalBarChartPercentageCard>
      <DoubleTextCard title_1="Top 3 Funds for Total Portfolio Aligned Turnover % - Non_Financials are:" data_funds={data?.top_three_for_total_portfolio_aligned_turnover.funds} title_2="Top 3 Sectors for Total Portfolio Aligned Turnover % - Non_Financials are:" data_sectors={data?.top_three_for_total_portfolio_aligned_turnover.sectors} cardType="medium-chart-card-size card-height-3"></DoubleTextCard>
      <PieChartPercentageCard title="Total Alignment Turnover % by Sector" data={data?.total_alignment_turnover_percentage_by_sector} cardType="medium-chart-card-size card-height-3" colors={COLORS_2}></PieChartPercentageCard>
      <PieChartPercentageCard title="Total Alignment Turnover % by Asset Type" data={data?.total_alignment_turnover_percentage_by_asset_type} cardType="medium-chart-card-size card-height-3" colors={COLORS_1}></PieChartPercentageCard>
      <MainTitleTransparentCard text="CAPEX Metric Analysis"></MainTitleTransparentCard>
      <HorizontalBarChartPercentageCard title="Total Alignment CAPEX % by Investment Fund" data={data?.total_alignment_capex_percentage_by_investment_fund} dataKey="eligible" yAxisLabel="name" showLegend={true} categoryName="Taxonomy - Eligible" cardType="medium-chart-card-size card-height-3"></HorizontalBarChartPercentageCard>
      <DoubleTextCard title_1="Top 3 Funds for Total Portfolio Aligned CAPEX % - Non_Financials are:" data_funds={data?.top_three_for_total_portfolio_aligned_capex.funds} title_2="Top 3 Sectors for Total Portfolio Aligned CAPEX % - Non_Financials are:" data_sectors={data?.top_three_for_total_portfolio_aligned_capex.sectors} cardType="medium-chart-card-size card-height-3"></DoubleTextCard>
      <PieChartPercentageCard title="Total Alignment CAPEX % by Sector" data={data?.total_alignment_capex_percentage_by_sector} cardType="medium-chart-card-size card-height-3" colors={COLORS_2}></PieChartPercentageCard>
      <PieChartPercentageCard title="Total Alignment CAPEX % by Asset Type" data={data?.total_alignment_capex_percentage_by_asset_type} cardType="medium-chart-card-size card-height-3" colors={COLORS_1}></PieChartPercentageCard>
      <MainTitleTransparentCard text="OPEX Metric Analysis"></MainTitleTransparentCard>
      <HorizontalBarChartPercentageCard title="Total Alignment OPEX % by Investment Fund" data={data?.total_alignment_opex_percentage_by_investment_fund} dataKey="eligible" yAxisLabel="name" showLegend={true} categoryName="Taxonomy - Eligible" cardType="medium-chart-card-size card-height-3"></HorizontalBarChartPercentageCard>
      <DoubleTextCard title_1="Top 3 Funds for Total Portfolio Aligned OPEX % - Non_Financials are:" data_funds={data?.top_three_for_total_portfolio_aligned_opex.funds} title_2="Top 3 Sectors for Total Portfolio Aligned OPEX % - Non_Financials are:" data_sectors={data?.top_three_for_total_portfolio_aligned_opex.sectors} cardType="medium-chart-card-size card-height-3"></DoubleTextCard>
      <PieChartPercentageCard title="Total Alignment OPEX % by Sector" data={data?.total_alignment_opex_percentage_by_sector} cardType="medium-chart-card-size card-height-3" colors={COLORS_2}></PieChartPercentageCard>
      <PieChartPercentageCard title="Total Alignment OPEX % by Asset Type" data={data?.total_alignment_opex_percentage_by_asset_type} cardType="medium-chart-card-size card-height-3" colors={COLORS_1}></PieChartPercentageCard>
    </div>
  );
}

export default InvestmentFundAlignment;