
import React, { useContext, useState, useRef, useEffect } from "react";

import useAutosizeTextArea from "../utils/useAutosizeTextArea";
import LocalLoader from "../../../LocalLoader/LocalLoader";

import "./StepOne.scss"

import { GeneralContext } from "../../../../utils/functions_variables/variables";
import { getPAIInputFieldsData } from "../../../../utils/requests/getRequests";
import { putPAIInput } from "../../../../utils/requests/putRequests";

import { IoChevronDown } from "react-icons/io5";
import { BorderIconTextFullWidthButton, BorderTextButton } from "../../../../assets/styles/UIKit/UIKit";

const StepOne = ({ selectedFund, step, stepsInfo, setStepsInfo }) => {
    const { databaseAddress, setWarningMessage } = useContext(GeneralContext)
    const textareaNameRef = React.useRef(null);
    const placeholderName = "";
    const [valueName, setValueName] = useState("");
    const textareaFMPRef = React.useRef(null);
    const placeholderFMP = "";
    const [valueFMP, setValueFMP] = useState("");
    const textareaInitialDateRef = React.useRef(null);
    const placeholderInitialDate = "";
    const [valueInitialDate, setValueInitialDate] = useState("");
    const textareaEndDateRef = React.useRef(null);
    const placeholderEndDate = "";
    const [valueEndDate, setValueEndDate] = useState("");
    const [isLoading, setIsLoading] = useState(false)
    const [articlesOpen, setArticlesOpen] = useState(false);
    const [height, setHeight] = useState(0);
    const contentRef = useRef(null);
    const [buttonEnable, setButtonEnable] = useState(false)

    useEffect(() => {
        if (contentRef?.current) {
            setHeight(contentRef.current.scrollHeight);
        }
    }, [articlesOpen]);

    const toggleShowArticles = () => {
        setArticlesOpen(!articlesOpen);
    }

    useAutosizeTextArea(textareaNameRef?.current, valueName);
    useAutosizeTextArea(textareaFMPRef?.current, valueFMP);
    useAutosizeTextArea(textareaInitialDateRef?.current, valueInitialDate);
    useAutosizeTextArea(textareaEndDateRef?.current, valueEndDate);

    const handleChangeName = (evt) => {
        const val = evt?.target?.value;
        setValueName(val);
    };

    const handleChangeFMP = (evt) => {
        const val = evt?.target?.value;
        setValueFMP(val);
    };

    const handleChangeInitialDate = (evt) => {
        const val = evt?.target?.value;
        setValueInitialDate(val);
    };

    const handleChangeEndDate = (evt) => {
        const val = evt?.target?.value;
        setValueEndDate(val);
    };

    function checkFields() {
        if (valueName !== '' && valueFMP !== '' && valueInitialDate !== '' && valueEndDate !== '') {
            setButtonEnable(true)
        } else { setButtonEnable(false) }
    }

    useEffect(() => {
        checkFields()
    }, [valueName, valueFMP, valueInitialDate, valueEndDate]);

    function cleanFields() {
        setValueName("")
        setValueFMP("")
        setValueInitialDate("")
        setValueEndDate("")
    }

    const getInputFieldsData = async () => {
        setIsLoading(true)
        await getPAIInputFieldsData(databaseAddress, selectedFund?.label, "step1", 1).then(data => {
            if (Object.keys(data)?.length === 0) {
                cleanFields()
            } else {
                if (data?.step1?.name) { setValueName(data?.step1?.name); }
                if (data?.step1?.participant) { setValueFMP(data?.step1?.participant); }
                if (data?.step1?.startDate) { setValueInitialDate(data?.step1?.startDate); }
                if (data?.step1?.endDate) { setValueEndDate(data?.step1?.endDate); }
                let stepFilled = false
                if (data?.step1?.name !== '' && data?.step1?.participant !== '' && data?.step1?.startDate !== '' && data?.step1?.endDate !== '') {
                    stepFilled = true
                }
                setStepsInfo(prevState => ({ ...prevState, stepOne: { filled: stepFilled } }))
            }
            setIsLoading(false)
        }).catch(error => {
            cleanFields()
            setIsLoading(false)
            setWarningMessage(["error", error.message])
        })
    }

    useEffect(() => {
        getInputFieldsData()
    }, [selectedFund])

    async function handleSubmit(e) {
        e.preventDefault();
        if (buttonEnable) {
            setIsLoading(true)
            const bodyContent = JSON.stringify({ client: databaseAddress, fundName: selectedFund?.label, insertValue: { step1: { name: valueName, participant: valueFMP, startDate: valueInitialDate, endDate: valueEndDate } } })
            await putPAIInput(bodyContent, 1).then(data => {
                getInputFieldsData()
                setStepsInfo(prevState => ({ ...prevState, stepOne: { filled: true } }))
                setIsLoading(false)
                setWarningMessage(["success", "Content saved successfully."])
            }).catch(error => {
                setIsLoading(false)
                setWarningMessage(["error", error.message])
            })
        }
    }

    if (isLoading) {
        return <LocalLoader size="textarea-big-input"></LocalLoader>;
    }

    return (
        <div style={{ display: step === 1 ? 'flex' : 'none' }} className="steps-main-div color-white">
            <div className="articles">
            <BorderIconTextFullWidthButton text="Articles" icon={<IoChevronDown className={`icon ${articlesOpen && "close-180-deg"}`} />} clickAction={toggleShowArticles} />
                <div ref={contentRef} className="articles-div" style={{ height: articlesOpen ? `${height}px` : '0', marginBottom: articlesOpen ? `15px` : '0' }}>
                    <p className="title font-style-montserrat-family-semibold extra-small-text">Article 1 - Definitions</p>
                    <p className="text font-style-montserrat-family-regular extra-small-text ">For the purposes of this Regulation, the following definitions apply:<br />
                        <label className="font-style-montserrat-family-semibold">(1)</label> ‘financial undertaking’ means an AIFM as defined in Article 4(1), point (b), of Directive 2011/61/EU of the European Parliament and of the Council, a management company as defined in Article 2(1), point (b), of Directive 2009/65/EC of the European Parliament and of the Council, an investment company authorised in accordance with Articles 27, 28 and 29 of Directive 2009/65/EC that has not designated for its management a management company authorised in accordance with Articles 6, 7 and 8 of that Directive, a credit institution as defined in Article 4(1), point (1), of Regulation (EU) No 575/2013 of the European Parliament and of the Council, an investment firm as defined in Article 4(1), point (2), of Regulation (EU) No 575/2013, an insurance undertaking as defined in Article 13, point (1), of Directive 2009/138/EC of the European Parliament and of the Council, a reinsurance undertaking as defined in Article 13, point (4), of Directive 2009/138/EC, or any third country entity that carries out similar activities, is subject to the laws of a third country and is supervised by a third-country supervisory authority;<br />
                        <label className="font-style-montserrat-family-semibold">(2)</label> ‘non-financial undertaking’ means an undertaking that is not a financial under taking as defined in point(1);<br />
                        <label className="font-style-montserrat-family-semibold">(3)</label> ‘sovereign exposure’ means an exposure to central governments, central banks and supranational issuers;<br />
                        <label className="font-style-montserrat-family-semibold">(4)</label>  ‘environmentally sustainable economic activity’ means an economic activity that complies with the requirements laid down in Article 3 of Regulation (EU) 2020/852;<br />
                        <label className="font-style-montserrat-family-semibold">(5)</label>  ‘transitional economic activity’ means an economic activity that complies with the requirements laid down in Article 10(2) of Regulation (EU) 2020/852;<br />
                        <label className="font-style-montserrat-family-semibold">(6)</label>  ‘enabling economic activity’ means an economic activity that complies with the requirements laid down in Article 16 of Regulation (EU) 2020/852.<br /></p>
                    <p className="title font-style-montserrat-family-semibold extra-small-text">Article 2 - General principles for the presentation of information</p>
                    <p className="text font-style-montserrat-family-regular extra-small-text "><label className="font-style-montserrat-family-semibold">1. </label> Financial market participants and financial advisers shall provide the information required by this Regulation free of charge and in a manner that is easily accessible, non-discriminatory, prominent, simple, concise, comprehensible, fair, clear and not misleading. Financial market participants and financial advisers shall present and lay out the information required by this Regulation in a way that is easy to read, use characters of readable size and use a style that facilitates its understanding. Financial market participants may adapt size and font type of characters and colours used in the templates set out in Annexes I to V to this Regulation.</p>
                    <p className="text font-style-montserrat-family-regular extra-small-text "><label className="font-style-montserrat-family-semibold">2. </label> Financial market participants and financial advisers shall provide the information required by this Regulation in a searchable electronic format, unless otherwise required by the sectoral legislation referred to in Article 6(3) and Article 11(2) of Regulation (EU) 2019/2088.</p>
                    <p className="text font-style-montserrat-family-regular extra-small-text "><label className="font-style-montserrat-family-semibold">3. </label> Financial market participants and financial advisers shall keep the information published on their websites in accordance with this Regulation up to date. They shall clearly mention the date of publication of the information and the date of any update. Where that information is presented as a downloadable file, financial market participants and financial advisers shall indicate the version history in the file name.</p>
                    <p className="text font-style-montserrat-family-regular extra-small-text "><label className="font-style-montserrat-family-semibold">4. </label> Financial market participants and financial advisers shall provide, where available, legal entity identifiers (LEIs) and international securities identification numbers (ISINs) when referring to entities or financial products in the information provided in accordance with this Regulation.</p>
                    <p className="title font-style-montserrat-family-semibold extra-small-text">Article 3 - Reference benchmarks with basket indexes</p>
                    <p className="text font-style-montserrat-family-regular extra-small-text ">Where an index designated as a reference benchmark is made up of a basket of indexes, financial market participants and financial advisers shall provide the information relating to that index in respect of that basket and of each index in that basket.</p>
                    <p className="font-style-montserrat-family-semibold extra-small-text">Chapter II - Transparency about adverse impacts on sustainability factors</p>
                    <p className="font-style-montserrat-family-semibold extra-small-text">Section 1 - Financial market participants</p>
                    <p className="title font-style-montserrat-family-semibold extra-small-text">Article 4 - Statement by financial market participants that they do consider principal adverse impacts of their investment decisions on sustainability factors</p>
                    <p className="text font-style-montserrat-family-regular extra-small-text "><label className="font-style-montserrat-family-semibold">1. </label> By 30 June each year, the financial market participants referred to in Article 4(1), point (a), of Regulation (EU) 2019/2088, or Article 4(3) or Article 4(4) of that Regulation, shall publish on their website, in a separate section titled: ‘Statement on principal adverse impacts of investment decisions on sustainability factors’, the information referred to in Article 4(1), point (a), of Regulation (EU) 2019/2088, in Article 4(2), (3) and (4) of that Regulation, and in Articles 4 to 10 of this Regulation. That information shall cover the period of 1 January until 31 December of the preceding year, and shall be published in the section ‘Sustainability-related disclosures’ referred to in Article 23 of this Regulation.</p>
                    <p className="text font-style-montserrat-family-regular extra-small-text "><label className="font-style-montserrat-family-semibold">2. </label> Financial market participants shall publish the statement referred to in paragraph 1 in the format of the template set out in Table 1 of Annex I.</p>
                    <p className="text font-style-montserrat-family-regular extra-small-text "><label className="font-style-montserrat-family-semibold">3. </label> By way of derogation from paragraph 1, for financial market participants that publish the statement referred to in Article 4(1), point (a), of Regulation (EU) 2019/2088, or Article 4(3) or Article 4(4) of that Regulation for the first time, the information referred to in paragraph 1 shall cover the period from the date on which the principal adverse impacts of investment decisions on sustainability factors were first considered until 31 December of that year. Those financial market participants shall publish the information in the statement referred to in paragraph 1 by 30 June of the following year.</p>
                    <p className="title font-style-montserrat-family-semibold extra-small-text">Article 5 - Summary section</p>
                    <p className="text font-style-montserrat-family-regular extra-small-text ">In the summary section in Table 1 of Annex I, financial market participants shall include all of the following:</p>
                    <p className="text font-style-montserrat-family-regular extra-small-text "><label className="font-style-montserrat-family-semibold">(a)</label> the name of the financial market participant to which the adverse sustainability impacts statement relates;</p>
                    <p className="text font-style-montserrat-family-regular extra-small-text "><label className="font-style-montserrat-family-semibold">(b)</label> the fact that principal adverse impacts on sustainability factors are considered;</p>
                    <p className="text font-style-montserrat-family-regular extra-small-text "><label className="font-style-montserrat-family-semibold">(c)</label> the reference period of the statement;</p>
                    <p className="text font-style-montserrat-family-regular extra-small-text "><label className="font-style-montserrat-family-semibold">(d)</label> a summary of the principal adverse impacts.</p>
                    <p className="text font-style-montserrat-family-regular extra-small-text ">The summary section in Table 1 of Annex I shall be drafted in all of the following languages:</p>
                    <p className="text font-style-montserrat-family-regular extra-small-text "><label className="font-style-montserrat-family-semibold">(a)</label> one of the official languages of the home Member State of the financial market participant and, where different, in an additional language customary in the sphere of international finance;</p>
                    <p className="text font-style-montserrat-family-regular extra-small-text "><label className="font-style-montserrat-family-semibold">(b)</label> where a financial product of the financial market participant is made available in a host Member State, one of the official languages of that host Member State.</p>
                    <p className="text font-style-montserrat-family-regular extra-small-text ">The summary section shall be of a maximum length of two sides of A4-sized paper when printed.</p>
                </div>
            </div>
            <div className="step-one margin-top-2 margin-bottom-3">
                <form onSubmit={handleSubmit} className="form-div">
                    <textarea rows={1} onChange={handleChangeName} ref={textareaNameRef}
                        type="text" className="first-input font-style-montserrat-family-regular extra-extra-small-text color-white"
                        value={valueName}
                        placeholder={placeholderName === "" ? "Name" : placeholderName} />
                    <p className="extra-small-text font-style-montserrat-family-regular color-white">considers principal adverse impacts of its investment decisions on sustainability factors. The
                        present statement is the consolidated statement on principal adverse impacts on sustainability factors of
                    </p>
                    <textarea row={3} onChange={handleChangeFMP} ref={textareaFMPRef}
                        type="text" className="second-input font-style-montserrat-family-regular extra-extra-small-text color-white"
                        value={valueFMP}
                        placeholder={placeholderFMP === "" ? "name of the financial market participant] [where applicable, insert ‘and its subsidiaries, namely [list the subsidiaries included]’" : placeholderFMP} />
                    <p className="extra-small-text font-style-montserrat-family-regular color-white">This statement on principal adverse impacts on sustainability factors covers the reference period from
                    </p>
                    <textarea rows={1} onChange={handleChangeInitialDate} ref={textareaInitialDateRef}
                        type="text" className="first-input font-style-montserrat-family-regular extra-extra-small-text color-white"
                        value={valueInitialDate}
                        placeholder={placeholderInitialDate === "" ? "insert ‘1 January’ or the date on which principal adverse impacts were first considered" : placeholderInitialDate} />
                    <p className="extra-small-text font-style-montserrat-family-regular color-white">to 31 December</p>
                    <textarea rows={1} onChange={handleChangeEndDate} ref={textareaEndDateRef}
                        type="text" className="first-input font-style-montserrat-family-regular extra-extra-small-text color-white"
                        value={valueEndDate}
                        placeholder={placeholderEndDate === "" ? "year-n" : placeholderEndDate} />
                    <BorderTextButton text="Save" extraClasses={`${buttonEnable ? "button-hover" : "button-disabled"}`} clickAction={handleSubmit} type="submit" disabled={!buttonEnable} />
                </form>
            </div>
        </div>
    );
}

export default StepOne;