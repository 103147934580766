
import React, { useState, useContext } from "react";
import { useNavigate } from 'react-router-dom';

import "./ConfigurationsMenu.scss"
import ThemeContext from "../../assets/styles/darkLightMode/theme-context";

import { handleThemeChange } from "../../utils/functions_variables/functions";
import { FiUser } from "react-icons/fi";
import { BsQuestionOctagon, BsChatLeftDots, BsFileEarmarkMedical, BsMoon } from "react-icons/bs";
import { MdOutlineLightMode } from "react-icons/md";

import { ExpandableLeftMenuTabButton, ExpandableLeftMenuTopButton } from "../../assets/styles/UIKit/UIKit";
import { TbLayoutSidebarLeftCollapse, TbLayoutSidebarLeftExpand } from "react-icons/tb";

export default function ConfigurationsMenu({ tab, setTab }) {
  const [menuOpen, setMenuOpen] = useState(true);
  const { theme, setTheme } = useContext(ThemeContext);
  const navigate = useNavigate();


  return (
    <div className="configurations-menu-div">
      <ExpandableLeftMenuTopButton text="Configurations" extraClasses="margin-bottom-1" icon={menuOpen ? <TbLayoutSidebarLeftCollapse className="icon small-text color-white" /> : <TbLayoutSidebarLeftExpand className="margin-center icon small-text color-white" />} open={menuOpen} clickAction={() => setMenuOpen(!menuOpen)}/>
      <ExpandableLeftMenuTabButton text="Profile" icon={<FiUser className="icon extra-small-text color-white" />} open={menuOpen} extraClasses={tab === 1 ? "active" : "inactive"} clickAction={() => navigate('/configurations/profile', { state: { from: 1 } })} />
      <div className="divider margin-top-1 margin-bottom-1" />
      <ExpandableLeftMenuTabButton text="FAQ" icon={<BsQuestionOctagon className="icon extra-small-text color-white" />} open={menuOpen} extraClasses={tab === 2 ? "active" : "inactive"} clickAction={() => navigate('/configurations/faq', { state: { from: 2 } })} />
      <ExpandableLeftMenuTabButton text="Support" icon={<BsChatLeftDots className="icon extra-small-text color-white" />} open={menuOpen} extraClasses={tab === 3 ? "active" : "inactive"} clickAction={() => navigate('/configurations/support', { state: { from: 3 } })} />
      <div className="divider margin-top-1 margin-bottom-1" />
      <ExpandableLeftMenuTabButton text="Terms & Conditions" icon={<BsFileEarmarkMedical className="icon extra-small-text color-white" />} open={menuOpen} extraClasses={tab === 4 ? "active" : "inactive"} clickAction={() => navigate('/configurations/terms', { state: { from: 4 } })} />
      <div className="divider margin-top-1 margin-bottom-1" />
      <ExpandableLeftMenuTabButton text={theme === 'dark' ? "Light mode" : "Dark mode"} icon={theme === 'dark' ? <MdOutlineLightMode className="icon extra-small-text color-white" /> : <BsMoon className="icon extra-small-text color-white" />} open={menuOpen} clickAction={() => handleThemeChange(theme, setTheme)} />
    </div>
  );
}
